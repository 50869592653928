.request-a-proposal {
  .quiz-question-content {
    .body-section {
      width: 100%;
      overflow-y: hidden;
      max-width: unset;
      align-items: center;
    }
    .quiz-content {
      justify-content: flex-start;
    }
  }
  .request-a-proposal-wrapper {
    display: flex;
    gap: 60px;
    flex: 1;
    color: #000;
    .new-title {
      font-family: 'BN Bergen St';
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }
    .text {
      font-family: 'LTC Caslon';
      font-size: 32px;
      font-weight: 400;
      line-height: 48px;
    }
    .left-part {
      width: 40%;
    }
    .right-part {
      width: 50%;
    }
    .left-part {
      height: calc(100vh - 111px);
      > img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }
    .right-part {
      height: calc(100vh - 111px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      .request-content {
        overflow-y: auto;
        .weddings-done {
          text-transform: uppercase;
          margin-bottom: 15px;
          > span {
            font-family: 'BN Bergen St';
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.2em;
            text-transform: uppercase;
          }
        }
        > p {
          font-family: 'Brandon Grotesque';
          font-style: normal;
          font-weight: 390;
          font-size: 20px;
          line-height: 28px;
          color: #231f20;
          margin-top: 0;
          margin-bottom: 8px;
        }

        .pictured {
          font-size: 16px;
        }
      }
      .action-box-wrapper {
        justify-content: flex-start;
        width: auto;
        padding-left: 0;
      }
    }
    .input-wrapper.for-availability {
      width: 100%;
      margin: 0;
      align-self: unset;
    }
    button.check-availability-btn {
      font-family: 'BN Bergen St';
      border: none;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.2em;
      font-weight: 700;
      text-transform: uppercase;
      background-color: #5e5929;
      opacity: 1;
      color: #fffefa;
      padding: 12.5px 16px;
      transition: box-shadow 0.2s ease-in-out;
      cursor: pointer;
    }
    button.check-availability-btn:disabled {
      cursor: context-menu;
      opacity: 0.8;
    }
  }

  @media only screen and (max-width: 820px) {
    .quiz-question-content {
      .body-section {
        width: 100%;
        height: fit-content;
        min-height: -webkit-fill-available;
        .quiz-content {
          padding-bottom: 40px;
        }
      }
    }
    .request-a-proposal-wrapper {
      flex-direction: column;
      gap: 16px;
      .left-part {
        width: 100%;
        height: auto;
        > img {
          height: 341px;
          visibility: unset;
        }
      }
      .right-part {
        width: auto;
        height: auto;
        padding: 16px;
        align-items: center;
        .request-content {
          overflow: hidden;
          > h2,
          > h3,
          > p {
            text-align: center;
          }
          > h2 {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 16px;
          }
        }
        .action-box-wrapper {
          justify-content: center;
        }
        .weddings-done-wrapper {
          width: 85%;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
      }
    }
  }
}

.text-field-wrapper {
  position: relative;
  .text-field {
    background-color: transparent;
    padding: 8px 0;
    outline: none;
    border: none;
    width: 100%;

    color: var(--c-active-tex);
    font-family: Brandon Grotesque;
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: 24px;
  }
  .label {
    position: absolute;
    pointer-events: none;
    transform-origin: top left;
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    font-family: Brandon Grotesque;
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: 24px;
    color: var(--c-night-500, #787475);
    transform: translate(0px, 8px);
  }
  &:focus-within label {
    color: var(--c-night-600, #555152);
    font-family: Brandon Grotesque;
    font-size: 10px;
    font-style: normal;
    font-weight: 390;
    line-height: normal;
    transform: translate(0px, -8px);
  }
  &.filled {
    .label {
      transform: translate(16px, 19px);
    }
    &:focus-within label {
      transform: translate(16px, 8px);
    }
  }
  &.has-value {
    .label {
      color: var(--c-night-600, #555152);
      font-family: Brandon Grotesque;
      font-size: 10px;
      font-style: normal;
      font-weight: 390;
      line-height: normal;
      transform: translate(0px, -8px);
    }
    &.filled label {
      transform: translate(16px, 8px);
    }
  }

  .text-field-group {
    border-bottom: 1px solid var(--c-night-300);

    &.filled {
      border: 1px solid var(--c-night-300);
      border-radius: 4px;
      padding: 26px 16px 8px;
      .text-field {
        padding: 0;
      }
    }
    &.input-error {
      border: 1px solid var(--c-error, #a83008);
    }
  }

  .error-helper {
    color: var(--c-error, #a83008);
    font-family: Brandon Grotesque;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-top: 5px;
  }
}

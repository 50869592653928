.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  .gallery__item {
    position: relative;
    &.pointer {
      cursor: pointer;
    }
  }
  .gallery__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  figure {
    margin: 0;
  }
  .cover {
    background-color: #222;
    opacity: 0.6;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 6px;
    .cover-text {
      right: 0;
      left: 0;
      bottom: 0;
      color: white;
      font-size: 7%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(0%, -50%);
      -ms-transform: translate(0%, -50%);
      transform: translate(0%, -50%);
      text-align: center;
      p {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: x-large;
      }
    }
  }

  &.gallery--1 {
    .gallery__item--1 {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 4;
    }
  }
  &.gallery--2 {
    grid-template-columns: repeat(2, 1fr);
    .gallery__item--1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .gallery__item--2 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }
  }
  &.gallery--3 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 200px);
    .gallery__item--1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    .gallery__item--2 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .gallery__item--3 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }
  &.gallery--4 {
    grid-template-rows: repeat(3, 150px);
    .gallery__item--1 {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 3;
    }
  }
  &.gallery--5 {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 200px);
    .gallery__item--1 {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .gallery__item--2 {
      grid-column-start: 4;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .gallery__item--3 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    .gallery__item--4 {
      grid-column-start: 3;
      grid-column-end: 5;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    .gallery__item--5 {
      grid-column-start: 5;
      grid-column-end: 7;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }
  &.gallery--6 {
    grid-template-rows: repeat(5, 100px);
    .gallery__item--1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    .gallery__item--2 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 4;
    }
    .gallery__item--3 {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    .gallery__item--4 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: 6;
    }
    .gallery__item--5 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 4;
      grid-row-end: 6;
    }
    .gallery__item--6 {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 3;
      grid-row-end: 6;
    }
  }
}

.modal-base.preview-image-modal {
  background-color: transparent;
  box-shadow: none;
  max-width: 80%;
  .image-preview-item {
    .image-preview {
      width: 100%;
      object-fit: cover;
      height: 500px;
    }
  }
}

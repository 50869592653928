@import './mixin.scss';
@import './base.scss';

// uncomment this for temp fix for live development (only for live dev)
// issue iframe block the webpage
// body > iframe {
//   display: none;
// }

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

.quiz-question {
  .border-red {
    border-color: #a83008 !important;
  }
  .error {
    color: #a83008;
    font-weight: 450;
  }
  background-color: $poppy-bg;
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 100dvh;
  .quiz-progress-bar {
    height: 4px;
    width: 100%;
    background-color: rgba(244, 192, 143, 0.3);
    position: relative;
    .progress-percentage {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: #ff9977;
      transition: width 400ms ease-in-out;
    }
  }
  .quiz-progress-bar-green {
    height: 4px;
    width: 100%;
    background-color: rgba(94, 89, 41, 0.4);
    position: relative;
    .progress-percentage {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: #5e5929;
      transition: width 400ms ease-in-out;
    }
  }
}

.grid-4-col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px 44px;
}

.modal-base {
  &.unserviceable-modal {
    .button {
      border-radius: 0px;
      font-family: 'BN Bergen St';
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      opacity: 1;
      padding: 24px 56px;
    }
    .button-secondary {
      color: #5e5929;
      background: #f2f1ea;
      border: 1px solid #5e5929;
      white-space: nowrap;
      &:active {
        background: #eceae1;
        border: 1px solid #5e5929;
      }
      &:disabled {
        color: #adaa90;
        background: #f2f1ea;
        border: 1px solid #adaa90;
      }
    }
    .button-primary {
      color: #f2f1ea;
      background: rgba(94, 89, 41, 1);
      border: 1px solid rgba(94, 89, 41, 1);
      white-space: nowrap;
      &:active {
        background: rgba(94, 89, 41, 1);
        border: 1px solid rgba(94, 89, 41, 1);
      }
      &:disabled {
        color: #f3d3ca;
        background: rgba(94, 89, 41, 0.51);
        border: 1px solid rgba(94, 89, 41, 0.51);
      }
    }
  }
  &.desktop.unserviceable-modal {
    background-color: #e5e3d9;
    padding: 3rem 0 0;

    .pin-desktop {
      cursor: pointer;
      position: absolute;
      left: calc(50% - 84px * 0.7 / 2);
      top: 2rem;
      height: 20px;
      transform: scale(0.7);
    }

    .unserviceable-wrapper {
      margin: 3rem 0;
      padding: 0.5rem 3rem 1rem;
      border-radius: 0;
      background-color: $linen;

      h2 {
        margin: 1rem 0 1rem;
      }
      .title {
        font-family: 'LTC Caslon';
        font-style: normal;
        font-weight: normal;
        font-size: 56px;
        line-height: 64px;
        text-align: center;
        color: #231f20;
      }

      .text-content {
        font-family: 'Brandon Grotesque';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: #231f20;
      }
    }
  }
}

.modal-base {
  &.mobile.unserviceable-modal {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 1rem;
    background-color: #e5e3d9;

    .pin-mobile {
      margin-bottom: 1rem;
    }

    .logo {
      img {
        width: 120px;
        height: 120px;
      }
    }

    .button-wrapper {
      button {
        width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }

    h2 {
      padding: 1rem 0 1rem;
      margin: 0;
    }
    .title {
      font-family: 'LTC Caslon';
      font-style: normal;
      font-weight: normal;
      font-size: 56px;
      line-height: 64px;
      text-align: center;
      color: #231f20;
    }

    .text-content {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #231f20;
    }
  }
}

.button-wrapper {
  button {
    font-family: 'BN Bergen St';
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #231f20;
  }
}

.quiz-question-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .header-top {
    width: 100%;
    background-color: #e5e3d9;
    box-sizing: border-box;
    // position: -webkit-sticky; /* Safari */
    position: fixed;
    top: 0;
    z-index: 100;
    .menu {
      width: 100%;
      height: 64px;
      background-color: #231f20;
    }
    .mobile {
      display: none;
    }
    .content {
      display: flex;
      width: 84%;
      height: 47px;
      margin: 0px auto;
      justify-content: space-between;
      align-items: center;
      .action {
        margin: 0 8px;
        display: flex;
        align-items: center;
        .button {
          padding: 0;
          opacity: 1;
        }
        .button > span {
          font-family: 'BN Bergen St';
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 9px;
          letter-spacing: 0.2em;
          color: #231f20;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      .left-action,
      .right-action {
        min-width: 40px;
      }
      .step-title-box {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        .text-title {
          font-family: 'BN Bergen St';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          color: #231f20;
          margin: 0;
        }
      }
    }
  }
  .quiz-question-content {
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100% - 111px);
    margin-top: 111px;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: #c4c4c4 white;
    &::-webkit-scrollbar {
      height: 20px;
      width: 5px;
      border-radius: 25px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 25px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 25px;
      background: #c4c4c4;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #b4b4b4;
    }

    .body-section {
      display: flex;
      width: 60%;
      max-width: 920px;
      flex-direction: column;
      align-items: flex-start;
      z-index: 1;

      .title-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 27px 0 0;
        width: 100%;
        text-align: center;
        .text-detail {
          font-family: 'LTC Caslon';
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          line-height: 48px;
          color: #231f20;
          margin: 0;
          margin-bottom: 16px;
        }
        @include responsive-down('md') {
          .text-detail {
            font-size: 20px;
            line-height: 30px;
          }
        }
        .step-subtitle {
          font-family: 'Brandon Grotesque';
          font-style: normal;
          font-weight: 390;
          font-size: 16px;
          line-height: 24px;
          color: #231f20;
          margin: 0;
        }
      }
    }
    // .left-flower {
    //   background: url('../images/Lupine_Curved_Grayscale_1.png');
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   position: fixed;
    //   width: 313px;
    //   height: 548px;
    //   left: -130px;
    //   bottom: 0px;
    //   &::after {
    //     content: '';
    //     display: block;
    //     position: fixed;
    //     background: url('../images/flower-colorfull.png');
    //     width: 346px;
    //     height: 344px;
    //     left: -86px;
    //     bottom: 0;
    //     background-size: cover;
    //   }
    // }
  }
  .quiz-question-content.hide-section-header {
    height: calc(100vh - 47px);
    margin-top: 47px;
  }
  .quiz-title {
    .title-base {
      font-family: 'Playfair Display';
      letter-spacing: 0.892857px;
      max-width: 1200px;
      margin: 0 auto;
      text-align: center;
    }
    .title {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 2.5rem;
    }
    .title-bold {
      font-weight: 900;
      font-size: 48px;
      line-height: 61px;
    }
    .subtitle {
      max-width: 60rem;
      padding: 0 10px;
      font-size: 1.5rem;
    }
  }

  .quiz-content {
    .subtitle {
      font-size: 1.5rem;
    }
    .checkbox-button {
      .label {
        width: 100%;
      }
    }
    .quiz-question-header {
      font-size: 20px;
      line-height: 30px;
      color: #231f20;
      margin-bottom: 1rem;
    }
    .option-text {
      font-family: 'Brandon Grotesque';
      font-size: 14px;
      font-style: normal;
      font-weight: 390;
      line-height: 21px;
    }

    .quiz-question-note {
      font-size: 1.5rem;
    }

    .quiz-guest-digging-deeper {
      .unique_style {
        width: 27rem;
        font-size: 1.5rem;
      }
    }
    .button {
      font-family: 'BN Bergen St';
      border: none;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.2em;
      font-weight: 700;
      text-transform: uppercase;
      background-color: #5e5929;
      opacity: 1;
      color: #fffefa;
      padding: 12.5px 16px;
      transition: box-shadow 0.2s ease-in-out;
      &:disabled {
        background-color: #9b9985;
        cursor: not-allowed;
      }
      &:not(:disabled):hover,
      &:not(:disabled):focus {
        box-shadow: 0px 4px 4px 0px #231f202e;
      }
      &.loading {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 16px;
          height: 16px;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto 8px auto auto;
          border: 4px solid transparent;
          border-top-color: #ffffff;
          border-radius: 50%;
          animation: button-loading-spinner 1s ease infinite;
        }
      }
    }
    .button-secondary {
      color: #5e5929;
      background: #f2f1ea;
      border: 1px solid #5e5929;
      white-space: nowrap;
      &:active {
        background: #eceae1;
        border: 1px solid #5e5929;
      }
      &:disabled {
        color: #adaa90;
        background: #f2f1ea;
        border: 1px solid #adaa90;
      }
    }
    .button-primary {
      color: #f2f1ea;
      background: rgba(94, 89, 41, 1);
      border: 1px solid rgba(94, 89, 41, 1);
      white-space: nowrap;
      &:active {
        background: rgba(94, 89, 41, 1);
        border: 1px solid rgba(94, 89, 41, 1);
      }
      &:disabled {
        color: #f3d3ca;
        background: rgba(94, 89, 41, 0.51);
        border: 1px solid rgba(94, 89, 41, 0.51);
      }
    }
    .wedding-date-box,
    .wedding-location-box {
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;
      .floating-label-wrapper {
        flex-grow: 1;
      }
    }

    .quiz-wedding_date {
      display: flex;
      flex-wrap: wrap;
      align-items: self-end;
      min-width: 635px;
      .question-info {
        cursor: pointer;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      .question-info-mobile {
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
      .wedding-detail-tooltip {
        display: none;
        position: absolute;
        top: calc(100% + 8px);
        right: 0;
        flex-direction: column;
        gap: 10px;
        color: #fffefa;
        background-color: #5e5929;
        padding: 15px 20px;
        z-index: 2;
        max-width: 354px;
        box-shadow: 5px 5px 8px #ddd;
        .title {
          font-family: 'Brandon Grotesque';
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          text-align: left;
          margin: 0;
        }
        .text {
          font-family: 'Brandon Grotesque';
          font-size: 20px;
          font-weight: 390;
          line-height: 30px;
          text-align: left;
          margin: 0;
        }
        .btn-close {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
          @media screen and (min-width: 768px) {
            display: none;
          }
        }
      }
      .question-info:hover ~ .wedding-detail-tooltip {
        display: flex;
      }
      .wedding-detail-tooltip.is-mobile {
        display: flex;
      }
      .wedding_date-date {
        width: 100%;

        .DayPickerInput-Overlay {
          left: 24px;
        }
      }
      .reception-btn {
        margin-right: 2.5px;
      }
      .continue-btn {
        margin-left: 2.5px;
      }
    }

    .action-box-wrapper {
      width: 100%;
      display: flex;
      padding: 0 13px;
    }

    .no-flex-zone {
      display: block;
    }

    .quiz-guest-location {
      width: 100%;
      box-sizing: border-box;

      .location-item {
        display: grid;
        grid-template-columns: 500px 200px;
        gap: 15px 30px;
      }

      .location-title {
        text-align: left;
      }

      .add-another {
        width: 165px;
      }

      .city-state-wrapper {
        display: flex;
        justify-content: space-between;
      }

      .city {
        width: 100%;
      }
      .state {
        width: 100%;
      }
    }
    .input-wrapper {
      width: calc(50% - 26px);
      margin: 0px 13px;
      align-self: center;
      .email {
        width: 100%;
      }
      .error {
        padding: 4px 0 0;
      }
      .floating-label-wrapper {
        padding: 0;
        label {
          color: #787475;
          font-family: 'Brandon Grotesque';
          font-size: 16px;
          line-height: 24px;
          top: 50%;
          transform: translateY(-50%);
        }
        &:focus-within label,
        &.has-value label {
          font-size: 10px;
          line-height: 14px;
          letter-spacing: normal;
          text-transform: none;
          left: 16px;
          top: 4px;
          transform: translateY(10%);
        }
        input {
          height: 56px;
          padding: 20px 20px 5px 16px;
          &::placeholder {
            color: #787475;
            font-family: 'Brandon Grotesque';
          }
        }
      }
    }
    .quiz-guest-expect {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .guest-and-table-wrapper {
        .table-option-card {
          max-width: 360px;
          font-family: 'Playfair Display';
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 20px;
          margin-bottom: 20px;
          .image-option-image {
            width: 187px;
            height: 145px;
            margin: 10px auto;

            img {
              display: initial;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
        .guests-wrapper {
          display: flex;
          flex-wrap: wrap;
        }
      }
      .button-wrapper {
        width: 220px;
        button {
          font-weight: bold;
          color: #231f20;
        }

        .button.true {
          background-color: #ff9977;
          opacity: 1;
        }
      }

      .checkbox-button {
        .label {
          background: #ffffff;
          border: 1.5px solid $poppy-red;
          box-sizing: border-box;
          border-radius: 20px;
          font-style: normal;
          font-weight: bold;
          letter-spacing: 0.25px;
          text-transform: uppercase;
          padding: 7px 40px;
          color: $poppy-red;
        }
        input:checked + .label {
          background: $poppy-red;
          color: #ffffff;
        }
      }
    }

    .quiz-guest-table_seat {
      width: 800px;
    }

    .quiz-guest-flowers_wedding {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px;
      .flowers_wedding-partner {
        text-transform: uppercase;
        display: flex;
        flex-wrap: wrap;

        .radio {
          margin: auto 10px 10px 0px;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .title-partner {
        margin: 3rem auto 2rem 0px;
      }
      .quiz-guest-partner {
        width: 300px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .partner-item {
          display: flex;
          flex-direction: column;
          margin-right: 20px;

          .quantity-value {
            color: $fontColor;
          }
          .quantity-action {
            opacity: 0.7;
          }
          .partner-item-label {
            font-family: 'BN Bergen St';
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #000000;
          }
        }
      }
    }

    .quiz-guest-item_price {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 10px;
      padding: 0 15px;
      .title-seel-price {
        font-family: 'LTC Caslon';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
      }
      .item-price {
        display: flex;
        flex-flow: row wrap;
        max-width: 600px;

        .checkbox-button {
          margin: 0 10px 14px 0px;
        }
      }
      .item-title {
        text-align: left;
        font-family: 'BN Bergen St';
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #000000;
      }
    }

    .quiz-guest-colors_flowers {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      // margin-top: 30px;

      .title-colors {
        font-family: 'LTC Caslon';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        color: #000000;
      }

      .subtitle {
        font-family: 'Brandon Grotesque';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #000000;
      }
      .poppy-wedding {
        p {
          margin: 3px;
          padding: 0;
        }
      }
      .flower-wrapper {
        margin: 20px auto 32px;
        position: relative;
        .choose-start-card {
          min-height: 205px;
          width: 74%;
          margin: 0px auto;
          border: 2px dotted #c4c4c4;
          display: flex;
          align-items: center;
          justify-content: center;
          .choose-start-text {
            width: 80%;
            font-family: 'LTC Caslon';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #000000;
          }
        }
        .bg-slider {
          max-height: 521px;
        }
      }
      .moodboard-grid {
        position: relative;
        min-height: 665px;
        columns: 6;
        column-gap: 19px;
        row-gap: 19px;
        .moodboard-grid-col {
          width: 100%;
          max-width: 190px;
          height: 100%;
          > *:first-child {
            margin-bottom: 18px;
          }
        }
        img {
          object-fit: cover;
          object-position: center;
          border-radius: 8px;
          width: 100%;
          &.moodboard-image-1 {
            height: 223px;
          }
          &.moodboard-image-2 {
            height: 310px;
          }
          &.moodboard-image-3 {
            height: 163px;
          }
          &.moodboard-image-4 {
            height: 250px;
          }
          &.moodboard-image-5 {
            height: 295px;
          }
          &.moodboard-image-6 {
            height: 250px;
          }
          &.moodboard-image-7 {
            height: 280px;
          }
          &.moodboard-image-8 {
            height: 193px;
          }
          &.moodboard-image-9 {
            height: 260px;
            padding-top: 80px;
            object-position: top;
          }
          &.moodboard-image-10 {
            height: 175px;
            padding-top: 78px;
            object-position: top;
          }
          &.moodboard-image-11 {
            height: 208px;
          }
          &.moodboard-image-12 {
            height: 253px;
          }
        }
      }
      .inner-slider-wrapper {
        position: absolute;
        width: 100%;
        max-width: 400px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 8px;
        padding: 24px 16px;
        text-align: center;
        box-sizing: border-box;
        .inner-slider {
          display: flex;
          align-items: center;
          .slick-slide {
            text-align: center;
            h2 {
              font-size: 40px;
              font-weight: 400;
              line-height: 60px;
              margin: 24px 0;
            }
          }
          .slick-arrow {
            &::before {
              content: '';
            }
          }
          .slick-prev {
            left: 0;
          }
          .slick-next {
            right: 0;
          }
          .slick-prev,
          .slick-next {
            transform: unset;
            position: unset;
            width: 35px;
            height: 35px;
            > img {
              width: 35px;
              height: 35px;
            }
          }
        }
        .label {
          font-family: 'BN Bergen St';
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.2em;
        }
      }
    }

    .quiz-guest-colors-palette {
      .moodboard-flex {
        display: flex !important;
        width: 812px !important;
        height: 528px;
        margin: auto;
        .left-div {
          flex: 25%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          img {
            display: block;
            width: 185px;
            height: 255px;
            object-fit: cover;
          }
        }
        .center-div {
          flex: 50%;
          display: flex;
          justify-content: center;
          img {
            width: 394px;
            height: 528px;
            object-fit: cover;
          }
        }
        .right-div {
          flex: 25%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          img {
            width: 185px;
            height: 255px;
            object-fit: cover;
          }
        }
      }
      .moodboard-flex.for-mobile {
        display: flex !important;
        width: 254px !important;
        height: 396px;
        margin: auto;
        flex-direction: column;
        .up-div {
          flex: 60%;
          display: flex;
          gap: 7px;
          .left-div {
            flex: 66%;
            img {
              display: block;
              object-fit: cover;
              width: 167px;
              height: 223px;
            }
          }
          .right-div {
            flex: 34%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 223px;
            img {
              display: block;
              object-fit: cover;
              width: 80px;
              height: 107px;
            }
          }
        }
        .down-div {
          flex: 40%;
          display: flex;
          justify-content: space-between;
          img {
            display: block;
            object-fit: cover;
            width: 123px;
            height: 164px;
          }
        }
      }
      .inner-slider-wrapper-2 {
        position: relative;
        max-width: 400px;
        text-align: center;
        box-sizing: border-box;
        margin: 22px auto 0px auto;
        .inner-slider {
          display: flex;
          align-items: center;
          .slick-slide {
            text-align: center;
            h2 {
              font-size: 40px;
              font-weight: 400;
              line-height: 60px;
              margin: 24px 0;
            }
          }
          .slick-arrow {
            &::before {
              content: '';
            }
          }
          .slick-prev {
            left: 0;
          }
          .slick-next {
            right: 0;
          }
          .slick-prev,
          .slick-next {
            transform: unset;
            position: unset;
            width: 35px;
            height: 35px;
            > img {
              width: 35px;
              height: 35px;
            }
          }
        }
        .label {
          font-family: 'BN Bergen St';
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.2em;
        }
        .name-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 330px;
          min-width: 190px;
          width: max-content;
        }
      }
      .inner-slider-wrapper-2.for-mobile {
        position: relative;
        max-width: 400px;
        text-align: center;
        box-sizing: border-box;
        margin: 22px auto 0px auto;
        .inner-slider {
          display: flex;
          align-items: center;
          .slick-slide {
            text-align: center;
            h2 {
              font-size: 40px;
              font-weight: 400;
              line-height: 60px;
              margin: 24px 0;
            }
          }
          .slick-arrow {
            &::before {
              content: '';
            }
          }
          .slick-prev {
            left: 0;
          }
          .slick-next {
            right: 0;
          }
          .slick-prev,
          .slick-next {
            transform: unset;
            position: unset;
            width: 35px;
            height: 35px;
            bottom: 240px;
            position: relative;
            > img {
              width: 35px;
              height: 35px;
            }
          }
        }
        .label {
          font-family: 'BN Bergen St';
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.2em;
        }
        .name-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 330px;
          min-width: 190px;
          width: max-content;
        }
      }
    }

    .quiz-budget {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .range-slider-option {
        padding: 0;
        .slider-image {
          margin-bottom: 0;
          img {
            max-width: 80%;
          }
        }
      }
      .submit-note {
        font-family: 'Brandon Grotesque';
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 25px;
        color: #000000;
      }

      .action-box-wrapper {
        justify-content: center;
        width: auto;
      }
    }
    .quiz-guest-like_flower {
      .like_flower-content {
        gap: 25px 30px;
      }
      .image-option-card {
        background-color: #ffffff;
      }
    }

    .quiz-guest-anything {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .field-pinterest {
        &:placeholder-shown {
          text-decoration-line: none;
        }
        width: 550px;
        letter-spacing: 0.25px;
        text-decoration-line: underline;
        color: #051efb;
      }
      .anything-textarea {
        border-radius: 20px;
        box-sizing: border-box;
      }

      .name-wrapper {
        gap: 0 10px;
      }

      .submit {
        display: block;
        margin: 30px auto 30px 0px;
        font-family: 'BN Bergen St';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #f2f1ea;
      }

      .anything-content {
        max-width: 35rem;
        margin: 0;
        .anything-title {
          font-family: 'LTC Caslon';
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
          margin: 20px 0 10px;
        }
      }

      .anything-compliance {
        max-width: 36rem;
        font-family: 'Brandon Grotesque';
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 18px;
        color: #000000;
      }
    }

    .quiz-budget {
      .quiz-budget-option-wrapper {
        display: flex;
        flex-wrap: wrap;
        .quiz-budget-slider {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            font-family: 'LTC Caslon';
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #231f20;
            text-transform: none;
          }
          .budget-slider {
            max-width: 358px;
            width: 100%;
            .rc-slider {
              padding: 4px 0;
              height: 32px;
            }
            .rc-slider-rail {
              height: 32px;
              border-radius: 56px;
              background: rgba(134, 130, 91, 0.3);
            }
            .rc-slider-track {
              background: #86825b;
              height: 32px;
              border-radius: 56px;
            }
            .rc-slider-step {
              height: 32px;
            }
            .rc-slider-handle {
              width: 40px;
              height: 40px;
              border-radius: 40px;
              background: #86825b;
              box-shadow: -4px 0px 4px 0px #575224;
              border: none;
            }
          }
          .budget-input {
            background-color: #f2f1ea;
            border: 1px solid #b4b4b4;
            border-radius: 10px;
            padding: 14px 48px;
            text-align: center;
            font-family: 'LTC Caslon';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
            color: #000000;
            width: 100%;
            max-width: 358px;
            box-sizing: border-box;
            &:focus-visible {
              outline: 1px solid #a4a4a4;
            }
          }
        }
      }
      .range {
        max-width: 700px;
      }
    }

    .quiz-guest-booked {
      display: flex;
      flex-direction: column;
      margin: 0 13px 0 13px;
      width: calc(50% - 26px);
      .stage-wrapper {
        display: flex;
        flex-wrap: wrap;
        .radio {
          position: relative;
          margin: 0;
          .radio-label {
            padding: 8px 16px;
            border-radius: 9999px;
          }
        }
      }

      .booked-wrapper {
        max-width: 700px;
        .btn-vendor {
          margin: 3px;
        }
      }
      .error {
        padding: 4px 0 0;
      }
    }

    .quiz-guest-booked.custom-wedding-planning {
      width: 100%;
    }

    .quiz-rituals {
      .rituals-option {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 10px;
        gap: 10px;
      }
    }
    .get-started-section {
      max-width: 850px;
    }
    .get-started-section__compliance {
      max-width: 36rem;
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #000000;
      margin: 20px 16px 20px 0;
    }
    .day-picker-wrapper {
      .input-field {
        border-radius: 4px;
        &::placeholder {
          text-align: left;
        }
      }
      .icon {
        width: 16px;
        height: 16px;
        padding: 0;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .quiz-column-wrapper {
      display: flex;
      flex-wrap: wrap;
    }
    .quiz-social-dropdown {
      > div {
        background-color: #f2f1ea;
        min-height: 56px;
      }
    }
  }
  &.quiz-guest-colors {
    .quiz-question-content .body-section {
      max-width: 1039px;
      width: 100%;
      min-height: 520px;
      padding: 0;
      .quiz-content {
        width: 100%;
      }
      .title-section .step-subtitle {
        // max-width: 442px;
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (min-width: 993px) {
  .quiz-question-wrapper {
    .quiz-content {
      .input-wrapper {
        &.md-25 {
          width: calc(25% - 26px);
        }
      }
    }
  }
}

// @media only screen and (max-width: 992px) {
//   .quiz-question-wrapper {
//     .quiz-question-content {
//       .left-flower {
//         width: 278px;
//         height: 490px;
//         left: -110px;
//         &::after {
//           width: 248px;
//           height: 290px;
//           left: -66px;
//           bottom: -12px;
//         }
//       }
//     }
//   }
// }

@media only screen and (max-width: 820px) {
  .quiz-question-wrapper {
    .logo {
      margin: 0;
    }
    p {
      margin: 0;
    }
    .header-top {
      width: 100%;
      box-sizing: border-box;
      // position: -webkit-sticky; /* Safari */
      position: fixed;
      top: 0;
      z-index: 100;
      .mobile {
        display: block;
      }
      .content {
        width: 100%;
        min-height: 47px;
        .step-title-box {
          .text-title {
            font-size: 12px;
          }
        }
      }
      .fgJmJc {
        background-color: #ffffff;
        border-radius: 0;
      }
      .progress {
        background-color: #f5a99d;
        border-radius: 0;
      }
    }
    .quiz-question-content {
      .body-section {
        width: 100%;
        padding: 0px;
        .title-section {
          width: calc(100% - 32px);
          margin: 27px 16px 0;
          text-align: center;
          .step-subtitle {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .mt-50px {
        margin-top: 30px !important;
      }
      .custom-bg-step-2 {
        display: none;
      }
      .left-flower {
        display: none;
      }
    }

    .quiz-title {
      margin: 90px auto 0;
      .title-base {
        font-size: 2rem;
      }
      .title {
        padding: 0 25px;
      }
    }

    .quiz-content {
      flex-direction: column;
      justify-content: flex-start;
      flex-grow: 1;
      flex: 1;

      .subtitle {
        font-size: 1rem;
      }
      .button {
        padding: 16px 24px;
        font-size: 12px;
        line-height: 18px;
      }
      .button-secondary,
      .button-primary {
        width: 99%;
      }

      .quiz-question-header {
        font-size: 1.25rem;
        margin-bottom: 10px;
      }

      .quiz-guest-digging-deeper {
        padding: 0 1.5rem;

        .unique_style {
          width: 100%;
        }

        .button-secondary {
          width: 80%;
        }
      }

      .quiz-wedding_date {
        min-width: auto;
        margin: 0;
        padding: 0 8px;
        .wedding_date-date {
          .input-icons {
            .icon {
              padding: 4px 6px;
            }
          }

          .DayPickerInput-Overlay {
            left: 14px;
            width: calc(100% - 24px);

            .DayPicker {
              width: 100%;
            }
          }
        }
        .input-wrapper {
          width: calc(100% - 16px);
          margin: 0px 8px;
          &.sm-50 {
            width: calc(50% - 16px);
          }
        }
      }

      .quiz-guest-booked {
        margin: 20px 16px 0;
        max-width: 100%;
        width: calc(100% - 32px);
        box-sizing: border-box;
        .booked-wrapper {
          display: flex;
          flex-wrap: wrap;
          grid-template-columns: 1fr 1fr;
          gap: 0.5rem;

          .btn-vendor {
            margin: 0;
            padding: 0;
          }
        }
      }

      .quiz-guest-booked.custom-wedding-planning {
        width: 100%;
      }

      .quiz-guest-expect {
        .slider-wrapper {
          width: 100%;
        }
        .guest-and-table-wrapper {
          .table-wrapper {
            .image-option-basic {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              .table-option-card:first-child {
                margin-top: 20px;
                margin-bottom: 30px;
              }
            }
          }
        }
      }

      .quiz-guest-location {
        .location-item {
          display: grid;
          grid-template-columns: 1fr;
          width: 90%;
          max-width: 300px;
        }

        .location-title {
          text-align: center;
        }

        .city {
          width: 100%;
        }
        .state {
          width: 100%;
        }

        .add-another {
          width: 100%;
        }
      }

      .quiz-guest-table_seat {
        .image-option-basic {
          grid-template-columns: 1fr;
        }
      }

      .quiz-rituals {
        .rituals-option {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-around;
          padding: 0.5rem 0.5rem 1rem;

          button {
            width: 45%;
            margin: 5px;

            &:last-child {
              width: auto;
            }
          }
        }

        .logo {
          display: none;
        }
      }

      .quiz-guest-flowers_wedding {
        .flowers_wedding-partner {
          display: block;
          .radio {
            margin: 15px 0;
          }
        }

        .title-partner {
          margin: 3rem 0px 2rem;
        }

        .quiz-guest-partner {
          max-width: 300px;
          display: grid;
          grid-template-columns: 1fr;
          gap: 16px;

          .partner-item {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
          }
        }
      }

      .quiz-guest-item_price {
        padding: 0 0.5rem;
        .item-title {
          text-align: left;
        }
        .item-price {
          display: flex;
          flex-wrap: wrap;
          grid-gap: 0;

          .checkbox-button {
            width: 50%;
            margin: 0.5rem 0 0;
          }
        }
      }

      .quiz-guest-anything {
        padding: 0 16px;
        .field-pinterest {
          width: 100%;
          margin-bottom: 0.5rem;
        }

        .anything-textarea {
          width: 100%;
          padding: 15px 20px;
          box-sizing: border-box;
        }
      }

      .quiz-guest-colors_flowers {
        margin: 20px 0 0;
        width: 100%;
        .subtitle {
          padding: 10px 0px 0px;
        }
        .flower-wrapper.special {
          display: flex;
          flex-direction: column-reverse;
          margin-bottom: 0;
          .bg-slider {
            max-height: 534px;
            z-index: -1;
            overflow: hidden;
            margin-top: -6px;
          }
        }
        .moodboard-grid {
          columns: 4;
          column-gap: 8px;
          .moodboard-grid-col {
            max-width: unset;
            > *,
            > *:first-child {
              margin-bottom: 12px;
            }
          }
          img {
            &.moodboard-image-1 {
              height: 168px;
            }
            &.moodboard-image-2 {
              height: 232px;
            }
            &.moodboard-image-3 {
              height: 150px;
            }
            &.moodboard-image-4 {
              height: 181px;
            }
            &.moodboard-image-5 {
              height: 188px;
            }
            &.moodboard-image-6 {
              height: 150px;
            }
            &.moodboard-image-7 {
              height: 180px;
            }
            &.moodboard-image-8 {
              height: 188px;
            }
            &.moodboard-image-9 {
              height: 183px;
              padding-top: 0;
            }
            &.moodboard-image-10 {
              height: 141px;
              padding-top: 0;
            }
            &.moodboard-image-11 {
              height: 190px;
            }
            &.moodboard-image-12 {
              height: 143px;
            }
          }
        }
        .inner-slider-wrapper {
          position: unset;
          max-width: 100%;
          transform: unset;
          border-radius: 0;
        }
      }

      .quiz-budget {
        margin: 12px 0;
        padding: 0 28px;
        .range-slider-option {
          .slider-image {
            margin-bottom: 12px;
            img {
              max-width: 100%;
            }
          }
          .img-wrapper {
            min-height: 80px;
          }
        }
      }

      .quiz-guest-anything {
        .submit {
          display: block;
          width: 100%;
          margin: 20px auto 20px 0px;
        }
      }
      .action-box-wrapper {
        padding: 0 16px;
        justify-content: center;
      }
    }
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

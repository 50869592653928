@import 'src/assets/scss/mixin.scss';

.quiz-guest-colors {
  .new-color-palette-v2 {
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    gap: 24px;
    justify-content: center;
    max-width: 816px;
    width: 100%;
    margin: auto;
    .color-palette-item {
      height: 250px;
      background-size: cover;
      background-position: top;
      position: relative;
      margin-bottom: 8px;
      cursor: pointer;
      .color-showcase {
        position: absolute;
        bottom: 8px;
        left: 8px;
        display: flex;
        gap: 4px;
        .style-main-color {
          width: 16px;
          height: 16px;
          border-radius: 99%;
        }
      }
      .palette-name {
        font-family: Brandon Grotesque;
        font-weight: 390;
        font-size: 20px;
        line-height: 30px;
      }
    }
    .color-palette-item:hover::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      background-color: #ff9977;
      opacity: 0.1;
    }
    .color-palette-item.selected::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      background-color: #ff9977;
      opacity: 0.4;
    }
  }
  @include responsive-down('md') {
    .new-color-palette-v2 {
      max-width: 340px;
      grid-template-columns: 45% 45%;
    }
  }
}

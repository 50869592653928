.flower-interstitial {
  .main-content {
    position: relative;
    background-color: #eaeae8;
    background-image: linear-gradient(to right, #eaeae8, #f3f3f1);
    .welcome-page {
      position: relative;
      z-index: 1;
      max-width: 1072px;
      margin: auto;
      padding: 7% 24px;
      .welcome-content {
        max-width: 500px;
      }
      .description {
        max-width: 400px;
      }
      .actions {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 24px;
        row-gap: 16px;
      }
    }
    .bg-image {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    .main-content {
      background-image: linear-gradient(to right, #EEEEEC, #EEEEEC);
      .bg-image {
        width: auto;
        height: 100%;
        left: unset;
        right: 0;
      }
      .welcome-page {
        padding: 0 16px;
        .welcome-content {
          display: block;
          .top-actions {
            padding-top: 32px;
          }
        }
      }
    }
  }
}

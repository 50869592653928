.survey-modal {
  padding: 0;
  border-radius: 0;

  * {
    box-sizing: border-box;
  }

  .content {
    background-color: #ffffff;
    padding: 4rem 3rem 1rem;

    .button {
      padding: 20px 40px;
      font-family: 'BN Bergen St';
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #f2f1ea;
      z-index: 1;
      position: relative;
      opacity: 1;
      background-color: rgba(235, 75, 50, 1);
    }
    .button:hover {
      background-color: #d7462d;
      opacity: 1;
      color: #fff;
    }

    .title {
      font-family: 'LTC Caslon';
      font-style: normal;
      font-weight: normal;
      text-align: center;
      color: #231f20;
      font-size: 32px;
      line-height: 40px;
    }
    .full-width {
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    max-width: 90%;

    .content {
      padding: 2.5rem 2rem;
    }
  }
}

.introduce-wrapper {
  .result-introduce {
    background-image: url('../../../../assets/images/introduce_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 300px;
    .introduce-content {
      color: white;
      display: flex;
      .content {
        width: 50%;
        padding: 10px;
        .title {
          font-family: 'LTC Caslon';
          font-style: normal;
          font-weight: 400;
          font-size: 48px;
          line-height: 60px;
        }
        .subtitle {
          font-family: 'Brandon Grotesque';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
        .centered {
          display: flex;
          margin-bottom: 30px;
          justify-content: center;
        }
      }
    }

    .actions {
      display: flex;
      gap: 20px;
      margin-bottom: 30px;
      .button-secondary {
        background-color: #f2f1ea;
      }
    }
  }

  .book-now-session {
    margin-top: 4em;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    &.mobile {
      display: none;
    }
  }

  .book-now-card {
    background-color: #fff;
    border-radius: 10px;
    height: 195px;
    width: 438px;
    padding: 20px;
    .text-urgency {
      font-family: 'BN Bergen St';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 24px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #eb4b32;
      margin: 0;
    }
    .book-now-title {
      font-family: 'LTC Caslon';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 36px;
      color: #231f20;
    }
    .description {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 390;
      font-size: 16px;
      line-height: 24px;
      color: #231f20;
      margin: 0;
    }
    .action {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
    .book-now-btn {
      display: flex;
      justify-content: center;
      width: 155px;
      height: 42px;
      border-radius: 40px;
      padding: 0;

      font-family: 'BN Bergen St';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #f2f1ea;
    }
  }

  @media only screen and (max-width: 768px) {
    .result-introduce {
      .introduce-content {
        text-align: center;
        .content {
          width: auto;
          .title {
            font-size: 32px;
            line-height: 42px;
          }
        }
      }
      .actions {
        flex-direction: column;
        .button {
          width: 80%;
        }
      }
    }
    .book-now-session {
      margin-top: 24px;
      padding: 0 15px;
      width: auto;
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
      }
    }
    .book-now-card {
      height: auto;
      width: auto;
      text-align: center;
      .action {
        justify-content: center;
      }
    }
  }
}

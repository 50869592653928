@import 'src/assets/scss/mixin.scss';

.contact-info-wrapper {
  width: 100%;
  .contact-info-section {
    width: 100%;
    .input-wrapper {
      margin: auto;
      margin-top: 30px;
    }
    .ref-text {
      font-family: 'LTC Caslon';
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }
    .ref-dropdown {
      width: calc(50% - 26px);
      margin: auto;
    }
    .action-box-wrapper {
      justify-content: center;
    }
    button.see-your-proposal-btn {
      position: relative;
      transition: box-shadow 0.2s ease-in-out, padding 0.2s ease-in-out;
    }
    button.see-your-proposal-btn:disabled {
      cursor: context-menu;
      opacity: 0.8;
    }
    button.see-your-proposal-btn.loading {
      cursor: context-menu;
      opacity: 0.8;
      padding-right: 40px;
    }
    button.see-your-proposal-btn.loading::after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto 8px auto auto;
      border: 4px solid transparent;
      border-top-color: #ffffff;
      border-radius: 50%;
      animation: button-loading-spinner 1s ease infinite;
    }
    .notice-text {
      width: 100%;
      max-width: calc(50% - 26px);
      margin: auto;
      text-align: justify;
    }
  }
  @include responsive-down('md') {
    .contact-info-section {
      margin-top: 8px;
      .notice-text {
        width: 100%;
        max-width: 90%;
        margin: auto;
        text-align: justify;
      }
      .action-box-wrapper {
        padding: 0px !important;
      }
    }
    .ref-dropdown {
      width: 90% !important;
      margin: auto;
    }
    .input-wrapper {
      width: 90% !important;
    }
  }
}

.quiz-question-wrapper .quiz-question-content .body-section:has(> .contact-info-wrapper) {
  width: 100%;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

// loader
/* [LOADING SPINNER] */
.loading-spinner {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin: 4px;
  position: absolute;
  .loading-icon {
    border: 4px solid transparent;
    border-top: 4px solid #EB4B32;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: spin 2s linear infinite;
  }

  &.sm {
    width: 30px;
    height: 30px;
    .loading-icon {
      border-width: 6px;
    }
  }
  &.md {
    width: 40px;
    height: 40px;
    .loading-icon {
      border-width: 8px;
    }
  }
  &.lg {
    width: 50px;
    height: 50px;
    .loading-icon {
      border-width: 8px;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pac-container {
  background-color: #f2f1ea;
  box-shadow: 0 2px 1px rgb(0 0 0 / 25%);
  border-radius: 0px;
  font-family: 'LTC Caslon';
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  border-top: none;
  .pac-item {
    border-top: none;
    padding: 4px;
    font-size: 13px;
    .pac-item-query {
      font-size: 15px;
    }
    &:hover {
      background-color: #f2f1ea;
    }
  }
}
// @media only screen and (max-width: 768px) {
// .pac-container {
//   display: flex;
//   flex-direction: column-reverse;
//   position: relative !important;
// }
// }

.welcome-header {
  background: url('/assets/images/moodboard/header-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .container {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .welcome-content {
    padding: 7% 24px;
  }
  h2 {
    font-style: normal;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 1.92px;
  }
  .event-detail {
    color: #000;
    font-family: Brandon Grotesque;
    font-size: 20px;
    font-style: normal;
    font-weight: 390;
    line-height: 30px;
    letter-spacing: 0.8px;
  }
  .actions {
    display: flex;
    gap: 24px;
    row-gap: 16px;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 768px) {
    background-position: 70%;
  }
}

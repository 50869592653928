.experience-popup {
  * {
    box-sizing: border-box;
  }

  &.budget-warning {
    .warning-content {
      padding-bottom: 2rem;
    }
  }

  &.message-popup {
    padding: 0;
    border-radius: 24px;
    margin: 5rem auto;
    padding-top: 3rem;
    background: #fff;

    h2 {
      margin-top: 0;
    }

    .modal-close {
      svg {
        stroke: #9b9b9b !important;
      }
    }

    .message-field {
      width: 80%;
      height: 180px;
      padding-top: 2rem;
    }

    .button-submit {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  &.result-popup {
    padding: 0;
    border-radius: 24px;
    margin: 5rem auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background: #fff;

    visibility: hidden;
    opacity: 0;
    transition: visibility 2.5s, opacity 2.5s linear;

    h2 {
      margin-top: 0;
    }

    .modal-close {
      svg {
        stroke: #9b9b9b !important;
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: auto;
    height: auto;
    margin: 5rem auto;
    background-color: #ffd1d1;
  }
}

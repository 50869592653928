.custom-palette-page {
  max-width: 1072px;
  margin: auto;
  padding: 0 24px 24px;
  .step-header {
    padding: 16px 10% 0;
    position: sticky;
    top: 0;
    z-index: 1;
    background: rgba(255, 254, 250, 0.98);
  }
  .custom-palette-content {
    margin-top: 166px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    .custom-header {
      margin-top: 16px;
    }
    .custom-content {
      flex: 1;
      max-width: 396px;
    }
    .color-content-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .custom-color-wrapper {
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 1280px) {
    .custom-palette-content {
      margin-top: 112px;
    }
  }
  @media only screen and (max-width: 1024px) {
    .custom-palette-content {
      .custom-color-wrapper {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0 20px;
    padding-bottom: 20px;
    .step-header {
      padding: 0;
      .quiz-step {
        padding-top: 8px;
      }
    }
    .custom-palette-content {
      flex-direction: column;
      margin-top: 14px;
      .custom-header {
        margin-top: 0;
      }
      .custom-color-wrapper {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media only screen and (max-width: 640px) {
    .custom-palette-content {
      .custom-color-wrapper {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

.color-slider {
  min-width: 290px;
  .slider-wrapper {
    position: relative;
    padding-right: calc(59px / 2);
    padding-left: calc(59px / 2);
    border-radius: 56px;
    box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
    background: #e9e9e9;

    &::before {
      content: ' ';
      background: var(--color);
      width: 59px;
      border-radius: 56px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }
  .rc-slider {
    height: 59px;
    padding: 0;
  }
  .rc-slider-rail {
    height: 59px;
    border-radius: 0px;
    background: #e9e9e9;
  }
  .rc-slider-track {
    height: 59px;
    border-radius: 0;
  }
  .rc-slider-step {
    height: 59px;
  }
  .rc-slider-handle {
    width: 59px;
    height: 59px;
    border-radius: 50%;
    border: 10px solid #fff;
    margin-top: 0;
    box-shadow: 0 3px 3px -2px #0003, 0 3px 4px #00000024, 0 1px 8px #0000001f;
  }
}

.add-pop-color-drawer {
  width: 400px !important;

  @media only screen and (max-width: 1280px) {
    height: calc(100% - 60px) !important;
    top: 60px !important;
  }
  @media only screen and (max-width: 640px) {
    width: 100% !important;
    height: 100dvh !important;
    top: 0 !important;
  }

  .pop-color-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .pop-color-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid #e5e3d9;
      h3 {
        margin-block: 0;
        color: #231f20;
        font-family: LTC Caslon;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.8px;
      }
      .close-icon {
        cursor: pointer;
      }
    }
    .pop-color-content {
      padding: 16px;
      flex: 1;
      overflow: auto;
      p {
        margin-block: 0;
      }
      .choose-color-title {
        color: #000;
        font-family: Brandon Grotesque;
        font-size: 20px;
        font-style: normal;
        font-weight: 390;
        line-height: 30px;
      }
      .list-colors {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        .color-item {
          border: 1px solid #a0a0a0;
          border-radius: 999px;
          padding: 8px 17px;
          display: inline-flex;
          gap: 5px;
          align-items: center;
          cursor: pointer;

          &.active {
            border: 2px solid #f97;
          }
          .has-border {
            border: 1px solid #a0a0a0;
          }

          .color-circle {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
          .color-name {
            color: #000;
            font-family: Brandon Grotesque;
            font-size: 20px;
            font-style: normal;
            font-weight: 390;
            line-height: 30px;
          }
        }
      }
    }
    .pop-color-footer {
      padding: 16px 0;
      flex: 0 0 auto;
      display: flex;
      gap: 10px;
      justify-content: center;
    }
  }
}

@import 'src/assets/scss/mixin.scss';

.guest-count-wrapper {
  width: 100%;
  .guest-count-section {
    width: 100%;
    .guest-range-new {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: center;
      width: 100%;
      .item {
        width: 40%;
        color: #000;
        text-align: center;
        font-family: Brandon Grotesque;
        font-size: 16px;
        font-style: normal;
        font-weight: 390;
        line-height: 24px;

        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #bdbcbc;
        // width: 185px;
        height: 57px;
        cursor: pointer;
        &.selected {
          border: 1px solid #f97;
          background: #f97;
        }
      }
    }
  }
  @include responsive-down('md') {
    .guest-count-section {
      margin-top: 8px;
    }
  }
}

.quiz-question-wrapper .quiz-question-content .body-section:has(> .guest-count-wrapper) {
  width: 100%;
}

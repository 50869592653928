@import 'src/assets/scss/base.scss';

.essentials-collection-wrapper {
  padding-bottom: 32px;
  .ec-label {
    font-family: 'LTC Caslon';
    font-weight: 405;
    font-style: italic;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 10%;
    margin-top: 44px;
    margin-bottom: 30px;
    text-align: center;
  }
  .ec-two-columns {
    display: flex;
    justify-content: space-between;
    > div {
      width: 50%;
      &:last-child {
        flex: 0 0 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    .ec-cover {
      width: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 4px;
    }
  }
  .ec-checklist {
    list-style: none;
    padding: 0;
    margin-bottom: 14px;
    li {
      margin-bottom: 10px;
      &::before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 18px;
        background-image: url('/assets/icons/check-icon.svg');
      }
    }
  }
  .ec-title {
    font-family: 'LTC Caslon';
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 5%;
    margin: 0;
  }
  .ec-minimum-budget {
    font-family: 'Brandon Grotesque';
    font-weight: 390;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0%;
    margin: 12px 0 16px;
  }
  .ec-footer {
    margin-top: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ec-button-link {
      font-weight: 390;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0%;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 820px) {
    .ec-label {
      margin-top: 16px;
      margin-bottom: 8px;
    }
    .ec-title {
      margin-top: 12px;
      font-size: 20px;
      line-height: 30px;
    }
    .ec-minimum-budget {
      margin: 8px 0;
    }
    .ec-two-columns {
      flex-direction: column-reverse;
      > div {
        width: 100%;
        &:last-child {
          flex: 0 0 100%;
        }
      }
    }
    .ec-footer {
      margin-top: 16px;
    }
  }
}

.quiz-question-wrapper .quiz-content.essentials-collection-wrapper .action-box-wrapper {
  padding-left: 0;
}
.quiz-question-wrapper .quiz-question-content .body-section:has(.essentials-collection-wrapper) {
  width: 100%;
  padding: 0 24px;
}

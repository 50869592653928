$bgColorSection: #f2f1ea;

.results-page {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #f2f1ea;

  h1 {
    margin-top: 20px;
    margin-bottom: 15px;
    line-height: 61px;
    font-weight: 900;
    letter-spacing: 0.892px;
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 54px;
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'Nunito', sans-serif;
    font-size: 24px;
    line-height: 38px;
    font-weight: 600;
  }

  .button-schedule-a-call {
    align-self: flex-start;
    margin-top: 15px;
    margin-bottom: 40px;
    padding: 12px 24px;
    line-height: 14px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.25px;
    text-decoration: none;
    font-weight: 800;
    font-family: 'Nunito', sans-serif;
  }

  .w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
  }

  .area-6 {
    grid-area: Area-6;
  }

  .area {
    grid-area: Area;
  }

  .area-7 {
    grid-area: Area-7;
  }

  .area-2 {
    grid-area: Area-2;
  }

  .area-3 {
    grid-area: Area-3;
  }

  .area-4 {
    grid-area: Area-4;
  }

  .area-5 {
    grid-area: Area-5;
  }

  img {
    max-width: 100%;
    vertical-align: middle;
    border: 0;
    height: 100%;
    object-fit: cover;
  }

  .button {
    position: relative;
    top: auto;
    right: auto;
    bottom: 0%;
    z-index: auto;
    display: inline-block;
    width: auto;
    padding: 24px 26px;
    float: none;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border-style: solid;
    border-width: 1px;
    border-color: #d7462d;
    border-radius: 0px;
    background-color: #d7462d;
    opacity: 1;
    -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    font-family: 'BN Bergen St';
    font-style: normal;
    font-weight: bold;
    line-height: 21px;
    letter-spacing: 0.2em;
    color: #fbfaf8;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
  }
  .button:hover {
    background-color: #d7462d;
    opacity: 1;
    color: #fff;
  }
  .button.button-secondary {
    border-width: 1.5px;
    background-color: transparent;
    opacity: 1;
    color: #5e5929;
    border-color: #5e5929;
  }

  .section-37 {
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-bottom: 40px;
    background-color: #ffffff;
    max-height: 498px;

    .section-wrapper {
      display: flex;
      max-height: 498px;
      .left-section {
        width: 50%;
        display: flex;
        position: relative;
        .img-left {
          position: absolute;
          top: -35px;
          height: 561px;
          width: auto;
          max-width: unset;
        }
      }
      .right-section {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 40px 60px;
        .heading-78 {
          width: auto;
          max-width: 1000px;
          align-self: center;
          font-family: 'LTC Caslon';
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          line-height: 48px;
          color: #231f20;
        }

        .section-content-1 {
          .text-para {
            font-family: 'Brandon Grotesque';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            color: #231f20;
          }
        }

        .only-space {
          padding: 40px;
        }

        .button {
          position: relative;
          top: auto;
          right: auto;
          bottom: 0%;
          z-index: auto;
          display: inline-block;
          width: auto;
          margin-top: 15px;
          margin-bottom: 40px;
          padding: 24px 26px;
          float: none;
          -webkit-justify-content: space-around;
          -ms-flex-pack: distribute;
          justify-content: space-around;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-align-self: center;
          -ms-flex-item-align: center;
          -ms-grid-row-align: center;
          align-self: center;
          border-style: solid;
          border-width: 1px;
          border-color: #d7462d;
          border-radius: 0px;
          background-color: #d7462d;
          opacity: 0.75;
          -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
          transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
          font-family: 'BN Bergen St';
          font-style: normal;
          font-weight: bold;
          line-height: 21px;
          letter-spacing: 0.2em;
          color: #fbfaf8;
          font-size: 14px;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }
  }

  .section-39 {
    background-color: $bgColorSection;
    .sneak-peek-desktop {
      display: flex;
      padding-top: 60px;
      justify-content: space-between;

      .div-block-152 {
        margin-right: 0px;
        margin-left: 75px;
        display: flex;
        width: 50%;
        padding-bottom: 60px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        h1 {
          font-size: 48px;
        }
      }
    }
  }
  .section-38 {
    display: none;
  }
  .mobile {
    display: none;
  }
  .center {
    text-align: center;
  }
}

// table
@media screen and (max-width: 992px) {
  .results-page {
    .button.left-align {
      align-self: center;
    }

    .section-39 {
      .sneak-peek-desktop {
        max-width: 728px;
        .div-block-152 {
          margin-right: 0px;
          margin-left: 30px;
        }

        .button-schedule-a-call {
          align-self: center;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .results-page {
    .section-top {
      height: 70px;
      h3 {
        max-width: 80%;
      }
      .btn-close {
        top: 26px;
      }
    }
    h1 {
      font-size: 36px;
      line-height: 52px;
    }
    .button {
      width: 100%;
    }
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }

    .section-37 {
      padding-top: 0px;
      padding-bottom: 0px;
      max-height: unset;
      margin-bottom: 0px;
      .section-wrapper {
        display: flex;
        flex-direction: column;
        max-height: unset;
        .left-section {
          width: 100%;
          justify-content: center;
          padding-top: 30px;
          .img-left {
            position: relative;
            top: 0px;
            height: 473.07px;
            max-width: 100%;
          }
        }
        .right-section {
          width: 95%;
          padding-left: 15px;
          padding-right: 15px;
          h2 {
            margin-left: 0px;
            margin-right: 0px;
          }
          .only-space {
            display: none;
          }
        }
      }

      .heading-78 {
        width: auto;
        margin-right: 20px;
        margin-left: 20px;
        font-size: 24px;
        line-height: 30px;
      }
    }

    .section-39 {
      .sneak-peek-desktop {
        display: none;
      }
    }
    .section-38 {
      display: block;

      .container-29 {
        padding-right: 20px;
        padding-left: 20px;

        display: flex;
        padding-right: 40px;
        padding-left: 40px;
        flex-direction: column;
        align-items: center;

        .nunito-heading {
          margin-top: 40px;
          margin-bottom: 0px;
          font-family: Nunito, sans-serif;
          font-weight: 400;
          text-align: center;
        }
        .text-block-93 {
          text-align: center;
        }

        .text-block-91 {
          width: 60%;
          margin-right: 40px;
          margin-left: 40px;
          text-align: center;
        }
      }

      .div-block-160 {
        max-width: 335px;
        margin-bottom: 30px;
        padding: 14px;
        background-color: #fbfaf8;

        .w-layout-grid {
          display: -ms-grid;
          display: grid;
          grid-auto-columns: 1fr;
          -ms-grid-columns: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
          -ms-grid-rows: auto auto;
          grid-template-rows: auto auto;
          grid-row-gap: 16px;
          grid-column-gap: 16px;
        }

        .grid-21 {
          height: 218px;
          margin-bottom: 16px;
          grid-template-areas:
            'Area Area-2'
            'Area Area-2';
        }

        .grid-21-copy {
          height: 218px;
          margin-bottom: 0px;
          grid-template-areas:
            '. Area-2'
            '. Area-2';
        }

        .start-end-span-1 {
          grid-column-start: span 1;
          grid-column-end: span 1;
          grid-row-start: span 1;
          grid-row-end: span 1;
        }

        .image-88 {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .results-page {
    .section-38 {
      display: block;

      .container-29 {
        .text-block-91 {
          width: 80%;
          margin-top: 20px;
          margin-right: auto;
          margin-left: auto;
          text-align: center;
        }
      }
    }
  }
}

@import 'src/assets/scss/mixin.scss';

$fontColor: #320117;
$gray: rgba(0, 0, 0, 0.35);
$aquaBoxShadow: rgba(17, 177, 206, 0.425);
$poppy-bg: #f2f1ea;
$aqua: #231f20;
$primary: #c85138;

.radio-with-icon {
  margin: 10px 24px;
  color: $fontColor;

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        background: #fff;
        @include bordered(1.5px, $gray);
        @include square(1.4em, $radius: 100%);
        display: none;
        position: relative;
        top: -0.2em;
        margin-right: 10px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }

      &:focus {
        outline: 0;
      }
      &:focus-visible {
        &:before {
          @include box-shadow(0, 0, 0, 4px, $aquaBoxShadow);
        }
      }

      display: inline-block;
      border: 1px solid #b4b4b4;
      border-radius: 4px;
      text-align: center;
      box-sizing: border-box;
      padding: 18px 15px;
      text-align: left;
      cursor: pointer;
      background: $poppy-bg;
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 390;
      font-size: 16px;
      line-height: 23px;
      color: #231f20;
      width: 100%;
      max-width: 340px;
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $aqua;
          border-color: $aqua;
          box-shadow: inset 0 0 0 4px #fff;
        }
        &.basic::before {
          background: $primary;
          border-color: $primary;
          box-shadow: none;
        }
        background: #ff9977;
        border: 1px solid #ff9977;
        color: #231f20;
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px #fff;
          background: #fff;
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

body {
  --c-stem: #5E5929;
  --c-linen: #FFFEFA;
  --c-petal: #f97;
  --c-success: #4F4B23;
  --c-error: #A83008;
  --c-active-tex: #231F20;
  --c-night-100: #CFCFCF;
  --c-night-200: #C6C5C5;
  --c-night-300: #BDBCBC;
  --c-night-400: #898586;
  --c-night-500: #787475;
  --c-night-600: #555152;
  --c-night-700: #444041;
}
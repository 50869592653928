.date-range-picker {
  position: relative;
  background-color: white;
  border: 1px solid var(--neutrals-night300);
  border-radius: 4px;
  margin-top: 4px;
  background: transparent;
  z-index: 10;
  .upper-filter {
    display: flex;
    gap: 16px;
    .month-select,
    .year-select {
      flex: 50%;
      .select .react-select__menu-list {
        scrollbar-width: thin !important;
        scrollbar-color: #ff9977 transparent !important;
      }
    }
  }

  .Selectable
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #ff9977 !important;
    color: #231f20 !important;
    border-radius: 50% !important;
  }

  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }

  .Selectable .DayPicker-Day--start {
    border-radius: 50% !important;
    background-color: #ff9977 !important;
    position: relative;
  }

  .Selectable .DayPicker-Day--start::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ff9977 !important;
    opacity: 0.2;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .Selectable .DayPicker-Day--end {
    border-radius: 50% !important;
    background-color: #ff9977 !important;
    position: relative;
  }

  .Selectable .DayPicker-Day--end::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ff9977 !important;
    opacity: 0.2;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  .DayPicker-Day {
    padding: 0.8rem 1rem !important;
  }

  // .DayPicker-Week > .DayPicker-Day:first-child {
  //   border-top-left-radius: 50% !important;
  //   border-bottom-left-radius: 50% !important;
  // }
  // .DayPicker-Week > .DayPicker-Day:last-child {
  //   border-top-right-radius: 50% !important;
  //   border-bottom-right-radius: 50% !important;
  // }
  .DayPicker-Caption > div {
    text-align: center;
  }
  .DayPicker-NavButton--prev {
    left: 1.5rem;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: rgba(255, 153, 119, 0.2);
    border-radius: 50% !important;
  }

  .DayPicker-Month {
    margin: 0;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .DayPicker-wrapper {
    padding: 0;
  }
  .DayPicker {
    display: block;
  }
}

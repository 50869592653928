@import './base.scss';

.style-info-wrapper {
  min-height: 100vh;
  background-color: $secondary;

  .style-info {
    margin: 0 auto;
    max-width: 1260px;
    padding: 0 30px;

    .logo {
      display: block;
      margin: 0 auto;
      width: 90px;
      padding-top: 20px;
    }

    .content-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 3rem;
      max-width: 70rem;
      margin: 2rem auto 0;
      padding-bottom: 5rem;

      .narrow-wrapper {
        display: flex;
        justify-content: space-between;
        div {
          img {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            max-height: 20rem;
          }
        }
      }

      .info-wrapper {
        display: flex;
        align-self: center;
      }
    }

    .sub-title {
      text-align: center;
      padding: 1rem 2rem;
    }

    .title {
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: bold;
      font-size: 3rem;
      margin: 0 0 20px;
      line-height: 53px;
      text-transform: capitalize;
    }

    .button-proposal {
      font-size: 18px;
      padding: 10px 30px;
      font-weight: 500;
    }

    .info-content {
      font-size: 22px;
      line-height: 30px;
      font-family: 'Nunito';
    }

    .img-weddings {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;

      img {
        margin-bottom: 15px;
      }
    }
  }
}

.font_preloaded {
  opacity: 0;
}

.customer-wrapper {
  input {
    height: 2.5rem;
    text-transform: none;
    &::placeholder {
      text-transform: uppercase;
    }
  }

  .logo {
    position: absolute;
    top: -6rem;
    left: calc(50% - 45px);
    height: 90px;
    width: 90px;
    opacity: 0.5;

    img {
      width: 100%;
      display: block;
    }
  }

  .mail {
    padding: 1rem 1rem 0.5rem;
  }

  .name-wrapper {
    gap: 0 10px;
  }

  .sub-title {
    font-size: 1.5rem;
  }

  button.button {
    height: 3rem;
  }
}

.swiping {
  background-color: $poppy-bg;
  min-height: 100vh;
  overflow-x: hidden;

  .onboarding-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    .onboarding-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 10px;
      margin: 0 auto;

      .left-content {
        .find-your-style-box {
          padding: 70px 10px;
          .box-title {
            margin-bottom: 40px; 
          }
        }
      }

      .right-content {
        .img-wrapper {
          width: 322.77px;
          height: 424.98px;
          margin: 0 auto;
          position: relative;
          box-sizing: border-box;
          max-width: 100%;
          margin-right: 60px;

          img {
            width: 100%;
            display: block;
          }

          .img-layer1 {
            position: absolute;
            z-index: 1;
          }
          .img-layer2 {
            position: absolute;
            z-index: 2;
            top: 60px;
            left: 60px;
          }
        }
        .actions-box {
          margin-top: 60px;

          .btn-start {
            margin: auto;
            border-radius: 0;
            padding: 23px 56px;
            font-family: 'BN Bergen St';
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: $poppy-bg;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .swipping-component-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $poppy-bg;
    min-height: 100vh;

    .swipping-component-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 10px;
      margin: 0 auto;

      .left-content {
        .find-your-style-box {
          padding: 70px 10px;
          .box-title {
            margin-bottom: 40px;
          }
        }
      }

      .right-content {
        .tinder-wrapper {
          display: flex;
          align-items: center;
          font-size: calc(10px + 2vmin);

          .tinder-progress {
            width: 370px;
            margin-top: 1rem;
            margin-left: auto;
            margin-right: auto;
            .fgJmJc {
              background-color: transparent;
              border: 1px solid #44401E;
              .progress {
                background-color: #44401E;
              }
            }
          }

          .swipe-wrapper {
            .swipe {
              width: 370px;
              max-height: 493px;
              margin: 0 auto;

              &:nth-child(n + 2) {
                margin-top: -453px;
              }

              .card {
                position: relative;
                background-size: cover;
                background-position: center;
                max-height: 100%;
                overflow: hidden;
                padding: 10px 15px 0;
                background: #fff;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.125));

                .img-swipe {
                  width: 100%;
                  height: 413px;
                }

                .hint {
                  padding: 0;
                  width: 65%;
                  margin: 0 auto;
                  min-height: 30px;
                }

                img {
                  display: block;
                  object-fit: cover;
                  max-height: 100%;
                  max-width: 100%;
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
    }
  }

  .tinder-button-wrapper {
    padding: 20px 15px 0;
    box-sizing: border-box;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;
    z-index: 3;
    justify-items: center;
    width: 25rem;
    margin: 0 auto;

    .button-empty:focus {
      &:focus {
        outline: 0;
      }
    }

    .icon-active {
      display: none;
    }

    .button-like:hover, .button-like:active {
      .icon {
        display: none;
      }
      .icon-active {
        display: block;
      }
    }

    .button-love:hover, .button-love:active {
      .icon {
        display: none;
      }
      .icon-active {
        display: block;
      }
    }

    .icon {
      width: 70px;
      cursor: pointer;

      &:focus {
        outline: 0;
      }
    }
  }
}

.ReactModal__Overlay {
  z-index: 999;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.55) !important; 
}

@media only screen and (max-width: 768px) {
  .swiping {
    .onboarding-wrapper {
      .onboarding-content {
        box-sizing: border-box;
        min-height: 100vh;
        width: 100%;
        padding: 30px 20px;
        flex-direction: column;
        justify-content: flex-start;

        .left-content {
          .find-your-style-box {
            padding: 30px 10px;
            .box-title {
              margin-bottom: 20px;
            }
          }
        }

        .right-content {
          margin-top: 30px;
          width: 100%;
          .img-wrapper {
            max-width: 214.71px;
            width: 100%;
            height: 260.23px;
            margin-right: auto;
            .img-layer2 {
              top: 30px;
              left: 20px;
              width: 106%;
            }
          }
        }
      }
    }

    .logo {
      padding-top: 5px;
    }
    
    .swipping-component-wrapper {
      .swipping-component-content {
        box-sizing: border-box;
        min-height: 100vh;
        width: 100%;
        padding: 40px 20px;
        flex-direction: column;

        .left-content {
          .find-your-style-box {
            padding: 30px 10px;
            .box-title {
              margin-bottom: 20px;
              .text-dark {
                display: block; 
              }
            }
          }
        }
        .right-content {
          .tinder-wrapper {
            .tinder-progress {
              width: 100%;
              padding: 0 1rem;
              box-sizing: border-box;
              margin-top: 30px;
            }
            .swipe-wrapper {
              margin: 20px;
              div.swipe {
                width: 100%;
                max-width: 320px;

                &:nth-child(n + 2) {
                  margin-top: -433px;
                }

                .card {
                  .img-swipe {
                    height: 393px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .tinder-button-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      padding: 10px 20px 0;
      width: 100%;      
      margin-top: 10px;

      .icon, .icon-active {
        height: 8vh;
        width: 8vh;
      }
    }
  }
  .modal-base {
    max-width: 25rem;
    width: 90%;
    box-sizing: border-box;
    padding: 1rem;
    margin: 7rem auto;
  }

  .style-info-wrapper {
    padding: 10px 20px;

    .style-info {
      padding: 0;

      .info-content {
        font-size: 16px;
      }

      .button-proposal {
        width: 100%;
      }

      .img-weddings {
        display: block;
        img {
          width: 100%;
          margin-bottom: 15px;
        }
      }

      .sub-title {
        padding: 0.5rem 1rem;
      }
    }
  }
  @media screen and (orientation: portrait) {
    .swiping {
      .logo {
        padding: 0.5rem;
        box-sizing: border-box;
        img {
          height: 10vh;
          width: 10vh;
        }
      }
      
      .swipping-component-wrapper {
        .swipping-component-content {
          .right-content {
            .tinder-wrapper {
              align-items: unset;
              .tinder-content {
                margin: 0 auto;
              }
              .swipe-wrapper {
                height: 56vh;
                box-sizing: border-box;
                div.swipe {
                  height: 100%;
                  max-height: none;
                  &:nth-child(n + 2) {
                    margin-top: calc(-56vh);
                  }
                  .card {
                    padding: 0.5rem 1rem 0;
                    height: 100%;
                    .img-swipe {
                      height: 90%;
                    }
                    .hint {
                      height: 15%;
                      width: 80%;
                      box-sizing: border-box;
                      padding: 10% 0 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .tinder-button-wrapper {
        padding: 0.5rem 1.5rem 0.5rem;
        .icon {
          height: 8vh;
          width: 8vh;
          display: block;
        }
      }
    }
  }

  @media only screen and (max-width: 414px) {
    .swiping {
      .onboarding-wrapper {
        .onboarding-content {
          .left-content {
            .find-your-style-box {
              padding: 10px 10px;
              .box-title {
                margin-bottom: 10px;
                display: flex;
                flex-direction: column;

                .text-dark {
                  display: block; 
                }
              }
            }
          }
  
          .right-content {
            .img-wrapper {
              margin-right: auto;
              margin-left: auto;
              .img-layer2 {
                top: 30px;
                left: 20px;
                width: 106%;
              }
            }
            .actions-box {
              margin-top: none;
              .btn-start {
                width: 100%;
              }
            }
          }
        }
      }
      .swipping-component-wrapper {
        .swipping-component-content {
          .left-content {
            .find-your-style-box {
              padding: 10px 10px;
              .box-title {
                margin-bottom: 10px;
              }
            }
          }
          .right-content {
            .tinder-wrapper {
              .tinder-progress {
                padding: 0;
              }
              .swipe-wrapper {
                margin: 0px;
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }

  .customer-wrapper {
    .mail {
      img {
        width: 3rem;
        margin: 0 auto;
      }
    }
  }
}

.swiping-vertical {
  // width: 100%;
  // height: 100%;
  // position: fixed;
  // overflow: hidden;
  // -webkit-overflow-scrolling: touch;
}

@import 'src/assets/scss/mixin.scss';

.new-planning-stage-wrapper {
  width: 100%;
  .new-planning-stage-section {
    width: 100%;
    margin-top: 36px;
    .radio-with-icon {
      text-align: center;
      .radio-with-icon-content {
        display: flex;
        gap: 18px;
        align-items: center;
      }
    }
  }
  @include responsive-down('md') {
    .new-planning-stage-section {
      margin-top: 8px;
    }
  }
}

.quiz-question-wrapper .quiz-question-content .body-section:has(> .new-planning-stage-wrapper) {
  width: 100%;
}

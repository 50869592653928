.rituals-section {
  .rituals-choose {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 43px;
    .item {
      color: #000;
      text-align: center;
      font-family: Brandon Grotesque;
      font-size: 16px;
      font-style: normal;
      font-weight: 390;
      line-height: 24px;

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      border: 1px solid #bdbcbc;
      // width: 185px;
      height: 57px;
      flex-basis: 185px;
      cursor: pointer;
      &.selected {
        border: 1px solid #f97;
        background: #f97;
      }
    }
    @media only screen and (max-width: 768px) {
      .item {
        flex-basis: 100%;
      }
    }
  }
}

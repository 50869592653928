.post-moodboard-page {
  .main-content {
    background: url('/assets/images/moodboard/post-moodboard-bg.png');
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: cover;
    .container {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .post-moodboard-wrapper {
    padding: 5% 7%;
    max-width: 50%;
    .actions {
      display: flex;
      gap: 24px;
      .button-stem {
        text-decoration: none;
      }
    }
    .wedding-specialist {
      display: flex;
      gap: 30px;
      align-items: center;
      margin: 24px 0;
      .avatar {
        width: 136px;
        height: 136px;
        border-radius: 50%;
        flex: 0 0 auto;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .details {
        .title {
          color: #000;
          font-family: LTC Caslon;
          font-size: 12px;
          font-style: italic;
          font-weight: 405;
          line-height: 18px;
          letter-spacing: 1.2px;
        }
        .owner-name {
          color: #000;
          font-family: Brandon Grotesque;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
        }
      }
    }
  }

  .header-title {
    color: #000;
    font-family: LTC Caslon;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 1.28px;
  }

  .normal-text {
    color: #000;
    font-family: Brandon Grotesque;
    font-size: 20px;
    font-style: normal;
    font-weight: 390;
    line-height: 30px;
  }

  @media only screen and (max-width: 768px) {
    .main-content {
      background: none;
      .container {
        display: block;
      }
    }
    .post-moodboard-wrapper {
      text-align: center;
      max-width: unset;
      .wedding-specialist {
        flex-direction: column;
      }
      .actions {
        justify-content: center;
      }
    }
  }
}

@import 'src/assets/scss/base.scss';

.customer-testimonial-wrapper {
  flex-direction: column;

  .testimonial-content {
    max-width: 880px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    padding: 40px 44px;
    border: 1px solid #9b9b9b;
    border-radius: 0px;

    h1 {
      margin: 0;
      font-weight: 400;
    }

    .testimonial-item {
      position: relative;
      display: block;
      box-sizing: border-box;
    }

    .item-inner {
      display: flex;
      max-width: none;
      margin-right: 0px;
      margin-left: 0px;
      padding: 0px;
      border: 0px solid #9b9b9b;
      border-radius: 0px;
    }

    .couple-img {
      display: block;
      width: 175px;
      height: 175px;
      margin: auto 2rem;
      object-fit: cover;
    }

    .couple-say-content {
      max-width: 558px;
      text-align: left;
    }

    .stars {
      display: flex;
      position: relative;
    }

    .couple-say {
      margin-top: 6px;
      font-size: 20px;
      line-height: 38px;
      font-weight: 400;
      margin-bottom: 10px;
      font-family: 'LTC Caslon', sans-serif;
      color: #320117;
    }

    .title {
      display: none;
    }
  }

  .customer-testimonial-content {
    margin: auto;
    max-width: 600px;
    padding: 10px;
    .loading {
      font-size: 32px;
      span {
        display: inline-block;
        margin: 0 2px;
        animation: loading 0.7s infinite;
        @for $i from 1 through 3 {
          &:nth-child(#{$i + 1}) {
            animation-delay: #{$i * 0.1}s;
          }
        }
      }
    }
    @keyframes loading {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(5px);
      }
    }
    .waiting-text {
      color: #000;
      text-align: center;
      font-family: LTC Caslon;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0.96px;
    }
    .title {
      color: #000;
      font-family: LTC Caslon;
      font-size: 14px;
      font-style: italic;
      font-weight: 405;
      line-height: normal;
      letter-spacing: 1.4px;
    }
    .couple-feedback {
      color: #000;
      text-align: center;
      font-family: Brandon Grotesque;
      font-size: 20px;
      font-style: normal;
      font-weight: 390;
      line-height: 30px;
      max-height: 200px;
      overflow: auto;
      padding: 0 16px;
      @include custom-scrollbar;
    }
    .couple {
      color: #000;
      text-align: center;
      font-family: LTC Caslon;
      font-size: 24px;
      font-style: italic;
      font-weight: 405;
      line-height: normal;
    }
    .stars {
      display: flex;
      gap: 20px;
      justify-content: center;
    }
    .testimonial-image {
      max-width: 232px;
      height: 250px;
      background-position: center;
      background-size: cover;
      margin: 40px auto 0;
      // img {
      //   width: 100%;
      //   height: auto;
      //   object-fit: cover;
      // }
    }
    .slick-slide {
      max-height: calc(80vh - 40px);
      overflow-y: auto;
    }
  }

  @media screen and (max-width: 768px) {
    .customer-testimonial-content {
      max-width: 100%;
      padding: 0;
      height: 100vh;
      margin-top: 16px;
    }
  }
  @media screen and (max-width: 479px) {
    padding-top: 0px;
    padding-bottom: 0px;
    border-width: 0px;
    border-radius: 0px;
    text-align: center;

    .icon-logo {
      width: 90px;
      height: 90px;
    }

    .testimonial-content {
      padding: 1rem 1.5rem;
      margin-right: 1rem;
      margin-left: 1rem;
      .item-inner {
        display: block;
        text-align: center;
      }

      .couple-img {
        width: 100px;
        height: 100px;
        margin: auto;
      }

      .stars {
        margin-bottom: 20px;
        justify-content: center;
        align-items: stretch;
      }

      .couple-say-content {
        text-align: center;
      }

      .title {
        display: block;
        font-size: 21px;
        font-weight: 900;
        letter-spacing: 0.892px;
        color: $night;
        margin-bottom: 1rem;
        font-family: 'LTC Caslon';
      }

      .couple-say {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}

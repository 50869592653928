.accordion {
  margin-bottom: 10px;
  border-bottom: 1px solid #000;
  cursor: pointer;
  .accordion-title {
    display: flex;
    align-content: center;
    .accordion-icon {
      font-size: 40px;
      padding: 0 20px;
      display: flex;
      line-height: 0.5;
      align-items: center;
    }
  }
  .accordion-content {
    transition: opacity 1s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
    &.active {
      padding: 20px 20px 30px 80px;
      opacity: 1;
      height: auto;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }
}

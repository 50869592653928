.moodboard-page {
  .main-content {
    > .container {
      max-width: unset;
    }
  }

  .section-title {
    color: #000;
    font-family: LTC Caslon;
    font-size: 14px;
    font-style: italic;
    font-weight: 405;
    line-height: normal;
    letter-spacing: 1.4px;
  }
  .section-subtitle {
    color: #000;
    font-family: LTC Caslon;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }
  .description {
    color: #000;
    font-family: Brandon Grotesque;
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: normal;
  }
  .text-white {
    color: #fffefa;
  }
}

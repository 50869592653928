.experience-popup {
  * {
    box-sizing: border-box;
  }

  &.message-popup {
    padding: 0;
    border-radius: 24px;
    margin: 5rem auto;
    padding-top: 3rem;
    background: #fff;

    h2 {
      margin-top: 0;
    }

    .modal-close {
      svg {
        stroke: #9b9b9b !important;
      }
    }

    .sub-title {
      font-size: 20px;
      margin-bottom: 1rem;
    }

    .input-wrapper {
      width: 80%;
      margin: 0 auto;
    }

    .date-field {
      width: 100%;
    }

    .button-wrapper {
      margin-top: 1rem;
      padding-bottom: 1rem;
    }

    .button-submit {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  @media screen and (max-width: 767px) {
    width: auto;
    height: auto;
    margin: 5rem auto;
    background-color: #ffd1d1;
  }
}

.schedule-call-wrapper {
  background-color: #f2f1ea;
  color: #1a1b1f;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  * {
    box-sizing: border-box;
  }

  .w-col {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .w-col-6 {
    width: 50%;
  }
  .expect-wrapper {
    .tip-top {
      font-family: 'LTC Caslon';
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #231f20;
    }
  }
  .column-5 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 80px 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .column-6 {
    margin: 80px 0 20px;
  }

  #calendly-wrapper {
    margin-top: -1rem;
  }

  .content-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .header {
    display: flex;
    width: 100%;
    height: 381px;
    background-image: url('../../assets/images/bg_top_schedule.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .header-wrapper {
    padding-top: 20px;
    margin-bottom: 0px;
    display: flex;
    width: 100%;
    .section-left,
    .section-right {
      width: 50%;
    }
    .section-left {
      position: relative;
      .img-layer1,
      .img-layer2 {
        position: absolute;
        bottom: 0px;
        left: 0px;
        max-width: 100%;
      }
      .img-layer1 {
        z-index: 2;
        max-width: 90%;
      }
      .img-layer2 {
        z-index: 1;
      }
    }
    .section-right {
      .text-box {
        padding-top: 30px;
        padding-bottom: 30px;
        width: 80%;
        .title {
          font-family: 'LTC Caslon';
          font-style: normal;
          font-weight: normal;
          font-size: 56px;
          line-height: 64px;
          color: #f2f1ea;
          margin-bottom: 20px;
        }
        .subtitle {
          font-family: 'Brandon Grotesque';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          color: #f2f1ea;
        }
      }
    }
  }

  .column-3 {
    background-color: #f2f1ea;

    .div-block-52 {
      height: 780px;
      margin-bottom: 50px;
    }
    .div-block-167 {
      width: 75px;
      height: 1px;
      background-color: #d7462d;
    }
    .div-block-168 {
      display: flex;
      margin-top: 40px;
      align-items: center;
      h4 {
        font-family: 'LTC Caslon';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        color: #231f20;
      }
    }

    .heading-81 {
      font-family: 'LTC Caslon';
      font-style: normal;
      font-weight: normal;
      font-size: 48px;
      line-height: 60px;
      color: #231f20;
      margin: 0;
    }
    .heading-start-conversation {
      font-family: 'LTC Caslon';
      font-style: normal;
      font-weight: normal;
      font-size: 48px;
      line-height: 60px;
      color: #231f20;
      margin: 0px 0px 20px;
    }

    .paragraph.what-to-expect-copy {
      width: 70%;
      margin-left: 20px;
    }
    .paragraph {
      display: inline-block;
      margin-bottom: 0px;
    }

    .image-94 {
      width: 64px;
      height: 64px;
      margin-right: 30px;
    }
    img {
      display: block;
      -o-object-fit: cover;
      object-fit: cover;
    }
    img {
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
      border: 0;
    }
  }

  .conversation-form {
    display: flex;
    flex-direction: column;

    .subtitle {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      color: #231f20;
    }

    .div-block-168 {
      margin-left: 0;
      width: 80%;

      textarea {
        width: 100%;
        height: 200px;
        background-color: transparent;
        border-radius: 0;
      }
    }

    button {
      width: 260px;
      background: #eb4b32;
      border: 1px solid #eb4b32;
      border-radius: 0;
      padding: 20px 10px;
      font-family: 'BN Bergen St';
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #f2f1ea;
    }
  }

  .image-87 {
    display: none;
  }

  .section-41 {
    display: none;
  }
  .heading-11 {
    max-width: 500px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-size: 64px;
    text-align: center;
  }

  .select-call-type {
    h3 {
      font-family: 'LTC Caslon';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 48px;
      color: #231f20;
    }
    .button {
      border-radius: 0px;
      font-family: 'BN Bergen St';
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      opacity: 1;
      padding: 24px 56px;
    }
    .button-primary {
      color: #f2f1ea;
      background: #eb4b32;
      border: 1px solid #eb4b32;
      white-space: nowrap;
      &:active {
        background: #db3b22;
        border: 1px solid #db3b22;
      }
      &:focus {
        border: 2px solid #f5a99d;
      }
      &:disabled {
        color: #f3d3ca;
        background: #f5a99d;
        border: 1px solid #f5a99d;
      }
    }
    .full-width {
      width: 100%;
    }
    .designer-card {
      background-color: #fff;
      display: flex;
      padding: 11px;
      gap: 11px;
      .left-image {
        width: 169px;
        height: 211px;
        img {
          object-fit: cover;
        }
      }
      .right-content {
        width: 70%;
        p {
          margin: 0;
        }
        .title {
          font-family: 'LTC Caslon';
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
          line-height: 36px;
          color: #000000;
        }
        .subtitle {
          font-family: 'Brandon Grotesque';
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 20px;
          color: #505050;
        }
        .description {
          font-family: 'Brandon Grotesque';
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 21px;
          color: #231f20;
          margin-top: 10px;
        }
      }
    }
  }

  .button.button-text {
    background: transparent;
    outline: none;
    border: none;
    text-decoration: underline;
    font-size: 16px;
    line-height: 28px;
  }

  .footer {
    img {
      width: 100%;
      object-fit: cover;
      border: 0;
      vertical-align: middle;
    }
  }

  @media screen and (max-width: 1024px) {
    .column-3 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: auto;
      flex-direction: column-reverse;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .w-col-stack {
      width: 100%;
      left: auto;
      right: auto;
      padding-right: 1rem;
      padding-left: 1rem;
    }
    h2 {
      text-align: left;
    }
    .div-block-168 {
      width: 100%;
    }

    .conversation-form {
      width: 100%;
    }
  }
  @media screen and (max-width: 992px) {
  }
  @media screen and (max-width: 768px) {
    .section-top {
      height: 70px;
      h3 {
        max-width: 80%;
      }
      .btn-close {
        top: 26px;
      }
    }
    .column-5,
    .column-6 {
      margin: 40px 0 20px;
    }
    .column-3 {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;

      #calendly-wrapper {
        width: 100%;
      }

      .div-block-52 {
        height: 450px;
        margin-bottom: 0px;
      }
    }
    .expect-wrapper {
      .heading-81 {
        font-size: 42px;
      }
    }
    .column-5 {
    }
    .column-6 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .w-row,
    .w-container .w-row {
      margin-left: 0;
      margin-right: 0;
    }
    .w-col {
      width: 100%;
      left: auto;
      right: auto;
    }
    .content-wrapper {
      background-color: #f2f1ea;
      .conversation-form {
        .div-block-168 {
          width: 100%;
        }
        button {
          width: 100%;
        }
      }
    }
    .image-87 {
      display: block;
      width: 88px;
    }
    .header {
      height: 600px;
    }
    .header-wrapper {
      flex-direction: column-reverse;
      justify-content: space-between;
      .section-left,
      .section-right {
        width: 100%;
      }
      .section-right {
        .text-box {
          margin: auto;
          text-align: center;
        }
      }
      .title {
        font-family: 'Playfair Display', sans-serif;
        font-size: 48px;
      }
      .subtitle {
        font-size: 16px;
        line-height: 28px;
      }
    }
    .section-41 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-top: 40px;
      margin-bottom: 40px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .heading-11 {
      font-family: 'Playfair Display', sans-serif;
      font-size: 48px;
    }
    .div-block-170 {
      margin-right: 40px;
      margin-left: 40px;
    }
    .heading-84 {
      margin-top: 40px;
    }
    .text-block-94 {
      margin-right: 0px;
      margin-left: 0px;
      padding-bottom: 40px;
    }
    .div-block-171 {
      height: 1px;
      padding-right: 0px;
      padding-bottom: 0px;
      background-color: #d7462d;
    }

    .conversation-form {
      .heading-81 {
        font-size: 36px;
      }
      .heading-start-conversation {
        font-size: 24px;
      }
    }
    .select-call-type {
      .designer-card {
        .right-content {
          width: 60%;
          .title {
            font-size: 24px;
            line-height: 36px;
          }
          .subtitle {
            font-size: 14px;
            line-height: 20px;
          }
          .description {
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 479px) {
    .content-wrapper {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      background-color: transparent;
    }
    h1 {
      font-size: 36px;
      line-height: 52px;
    }
    .header-wrapper {
      margin-bottom: 0px;
      .center {
        margin-right: 20px;
        margin-left: 20px;
      }
      .title {
        font-size: 36px;
      }
    }
    .w-col {
      width: 100%;
    }
    .heading-11 {
      font-size: 36px;
    }
    .div-block-170 {
      margin-right: 40px;
    }
    .text-block-94 {
      margin-right: 0px;
      margin-left: 0px;
      padding-bottom: 40px;
    }
    .div-block-171 {
      width: 100%;
      height: 1px;
      padding-bottom: 0px;
      background-color: #d7462d;
    }
  }
}

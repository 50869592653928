.couple-info-page-wrapper {
  .main-content {
    background: url('/assets/images/cannyValley/couple-info-bg.png');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    @media only screen and (max-width: 768px) {
      background: none;
    }
  }
}
.couple-info-page {
  padding-top: 78px;
  padding-left: calc(100% / 12);
  padding-right: calc(100% / 4);
  padding-bottom: 20px;
  .page-title {
    color: #000;
    font-family: LTC Caslon;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
  }
  .main-info {
    margin-top: 24px;
    .grid-wrapper {
      display: flex;
      box-sizing: border-box;
      flex-flow: wrap;
      gap: 0px;
      .grid-item {
        width: 100%;
      }
      @media (min-width: 1024px) {
        grid-gap: 24px;
        gap: 24px;
        .grid-item {
          width: calc(50% - 12px);
        }
      }
    }
    @media (min-width: 1024px) {
      margin-top: 32px;
    }
  }
  .actions {
    margin-top: 16px;
  }
  .quiz-guest-booked {
    .stage-wrapper {
      display: flex;
      margin-top: 8px;
      flex-wrap: wrap;
      .radio {
        position: relative;
      }
      .radio-label {
        background-color: transparent;
        padding: 6px 16px;
        border-radius: 20px;
      }
    }
  }
  .error-helper {
    color: var(--c-error, #a83008);
    font-family: Brandon Grotesque;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-top: 5px;
  }
  .option-text {
    font-family: 'Brandon Grotesque';
    font-size: 14px;
    font-style: normal;
    font-weight: 390;
    line-height: 21px;
  }
  .quiz-social-dropdown {
    @media (min-width: 1024px) {
      width: calc(50% - 12px);
    }
  }

  .floating-label-wrapper {
    padding-top: 0;
    label {
      font-family: Brandon Grotesque;
      font-size: 16px;
      font-style: normal;
      font-weight: 390;
      line-height: 24px;
      color: var(--c-night-500, #787475);
      transform: translate(16px, 19px);
      pointer-events: none;
      transform-origin: top left;
      transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      left: 0;
    }
    &:focus-within label {
      color: var(--c-night-600, #555152);
      font-family: Brandon Grotesque;
      font-size: 10px;
      line-height: normal;
      transform: translate(16px, 8px);
      text-transform: none;
      letter-spacing: normal;
      left: auto;
    }
    &.has-value {
      .label {
        color: var(--c-night-600, #555152);
        font-family: Brandon Grotesque;
        font-size: 10px;
        line-height: normal;
        transform: translate(16px, 8px);
        text-transform: none;
        letter-spacing: normal;
        left: auto;
      }
    }
    input {
      padding: 26px 16px 8px;
      border: 1px solid var(--c-night-300);
      border-radius: 4px;
    }
    .day-picker-wrapper {
      .icon {
        width: 16px;
        height: 16px;
        padding: 0;
        right: 16px;
        top: 18px;
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    padding-top: 36px;
  }
  @media only screen and (max-width: 1024px) {
    padding-right: 15%;
    padding-top: 24px;
  }
  @media only screen and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
    .page-title {
      font-size: 24px;
      line-height: 36px;
    }
  }
}

@import 'src/assets/scss/base.scss';

.budget-wrapper {
  .budget-section {
    .range-slider-option .slider-image img {
      max-width: 100% !important;
    }
  }
}

.budget-warning {
  background-color: $stem;
  padding: 0;
  border-radius: 0;

  * {
    box-sizing: border-box;
  }

  .pin-logo {
    position: relative;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      color: rgba(242, 241, 234, 1);
      font-weight: 400;
    }
  }

  .warning-content {
    background-color: #ffffff;
    padding: 4rem 3rem 1rem;
    height: 384px;

    button {
      z-index: 2;
      position: relative;
    }

    h2 {
      margin: 1rem 0 1rem;
    }

    .title {
      font-family: 'LTC Caslon';
      font-style: normal;
      font-weight: normal;
      font-size: 56px;
      line-height: 64px;
      text-align: center;
      color: #231f20;
    }

    .text-content {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #231f20;
    }

    .btn-send-message {
      padding: 20px 20px;
      font-family: 'BN Bergen St';
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #f2f1ea;
      z-index: 1;
      position: relative;
      opacity: 1;
      background-color: rgba(235, 75, 50, 1);
    }

    .modal-flower {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 264px;
      z-index: 0;
    }
  }

  @media screen and (max-width: 767px) {
    max-width: 90%;

    .warning-content {
      padding: 2.5rem 2rem;
    }
    .modal-flower {
      height: 159px !important;
    }

    h3 {
      font-size: 32px;
      padding: 18px 38px;
    }
    // width: 100%;
    // height: 100%;
    // margin: 0 auto;
    // background-color: #fff;
  }
}

.quiz-question-wrapper .quiz-question-content .body-section:has(> .budget-wrapper) {
  width: 100%;
}

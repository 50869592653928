.connect-us-section {
  background: url('/assets/images/moodboard/connect-us-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  .connect-us {
    padding: 7%;
    max-width: 572px;
    .actions {
      display: flex;
      gap: 20px;
    }
  }
  @media only screen and (max-width: 768px) {
    background: none;
    .container {
      justify-content: flex-start;
    }
    .connect-us {
      .actions {
        flex-direction: column;
      }
    }
  }
}

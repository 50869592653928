.budget-page {
  max-width: 1158px;
  margin: auto;
  padding: 0 40px 20px;
  .step-header {
    padding: 16px 10% 0;
    position: sticky;
    top: 0;
    z-index: 1;
    background: rgba(255, 254, 250, 0.98);
  }
  .budget-content-wrapper {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    margin-top: 60px;
    .budget-content {
      flex: 1;
      margin-top: 34px;
      max-width: 396px;
    }
    .flower-gallery {
      width: 50%;
    }
    .budget-header {
      margin-top: 16px;
      .step-description {
        margin-top: 16px;
      }
    }
    .budget-focus {
      margin-top: 24px;
    }
  }
  .select {
    .react-select__control {
      border-radius: 8px;
      border: 1px solid #a0a0a0;
      color: #000;
      font-family: Brandon Grotesque;
      font-size: 16px;
      font-style: normal;
      font-weight: 390;
      line-height: 24px;
    }
  }
  .budget-amount {
    text-align: left;
    .title {
      color: #000;
      font-family: LTC Caslon;
      font-size: 14px;
      font-style: italic;
      font-weight: 405;
      line-height: 21px;
      letter-spacing: 1.4px;
    }
    .budget-input-wrapper {
      display: flex;
      align-items: center;
      gap: 29px;
      .action {
        cursor: pointer;
      }
      .budget-input {
        color: #000;
        text-align: center;
        font-family: LTC Caslon;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0.96px;
        max-width: 144px;
        outline: none;
        border: none;
        border-bottom: 1px solid #a0a0a0;
        background: transparent;
        padding: 16px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0 20px;
    padding-bottom: 20px;
    .step-header {
      padding: 0;
      .quiz-step {
        padding-top: 8px;
      }
    }
    .budget-content-wrapper {
      flex-direction: column;
      margin-top: 14px;
      gap: 12px;
      .budget-content {
        max-width: unset;
        margin-top: 0;
      }
      .flower-gallery {
        width: 100%;
        .gallery {
          display: flex;
          overflow-x: auto;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none;
          }
          .gallery__item {
            width: 241px;
            overflow: hidden;
            flex: 0 0 auto;
          }
        }
      }
      .budget-header {
        margin-top: 0;
        .step-description {
          margin-top: 8px;
        }
        .budget-focus {
          margin-top: 16px;
        }
      }
      .budget-amount {
        text-align: center;
        .budget-input-wrapper {
          justify-content: center;
        }
      }
    }
    .mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

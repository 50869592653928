.sample-flower-page {
  max-width: 1072px;
  margin: auto;
  padding: 0 24px 24px;
  .step-header {
    padding: 16px 10% 0;
    position: sticky;
    top: 0;
    z-index: 1;
    background: rgba(255, 254, 250, 0.98);
  }
  .sample-flower-content-wrapper {
    display: flex;
    gap: 30px;
    margin-top: 40px;
    justify-content: space-between;
    .sample-flower-content {
      flex: 1;
      max-width: 396px;
      margin-top: 114px;
    }
    .sample-flower-preview {
      width: 442px;
      .sample-flower-grid-wrapper {
        display: grid;
        justify-items: center;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(14, 1fr);
        .flower-grid-item {
          width: 146px;
          height: 146px;
          border-radius: 50%;
          // box-shadow: 0 3px 3px -2px #0003, 0 3px 4px #00000024, 0 1px 8px #0000001f;
          cursor: pointer;
          transition: all 0.5s ease-in-out;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
          &.gray-out {
            filter: grayscale(100%);
            transform: scale(0.9);
          }
          &.like {
            transform: scale(1.2);
          }
          &.love {
            transform: scale(1.4);
          }

          &.--1 {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 3;
            grid-row-end: 7;
            z-index: 1;
          }
          &.--2 {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 9;
            grid-row-end: 13;
            z-index: 3;
          }
          &.--3 {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 5;
            z-index: 0;
          }
          &.--4 {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 6;
            grid-row-end: 10;
            z-index: 2;
          }
          &.--5 {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 11;
            grid-row-end: 15;
            z-index: 4;
          }
          &.--6 {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 3;
            grid-row-end: 7;
            z-index: 1;
          }
          &.--7 {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 9;
            grid-row-end: 13;
            z-index: 3;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    max-width: 1020px;
    .sample-flower-content-wrapper {
      .sample-flower-content {
        margin-top: 90px;
      }
      .sample-flower-preview {
        width: 390px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0 20px;
    padding-bottom: 20px;
    .step-header {
      padding: 0;
      .quiz-step {
        padding-top: 8px;
      }
    }
    .sample-flower-content-wrapper {
      flex-direction: column;
      margin-top: 14px;
      .sample-flower-content {
        margin-top: 0;
      }

      .actions {
        display: flex;
        justify-content: center;
      }

      .sample-flower-preview {
        margin: auto;
        width: 100%;
        .sample-flower-grid-wrapper {
          .flower-grid-item {
            width: 140px * 0.7;
            height: 140px * 0.7;
          }
        }
      }
    }
  }
}

.ReactModal__Overlay:has(.sample-flower-preview-modal) {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  background: rgba(222, 204, 204, 0.21);
  backdrop-filter: blur(2.5px);
}
.ReactModal__Overlay--after-open:has(.sample-flower-preview-modal) {
  opacity: 1;
}
.ReactModal__Overlay--before-close:has(.sample-flower-preview-modal) {
  opacity: 0;
}

.modal-base.sample-flower-preview-modal {
  background: rgba(222, 204, 204, 0.21);
  box-shadow: none;
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: unset;
  .flower-preview-wrapper {
    position: relative;
    display: flex;
    gap: 40px;
    &::after {
      content: ' ';
      position: absolute;
      height: 1px;
      background: #000;
      width: 60px;
      left: 270px;
      top: 100px;
      z-index: 0;
    }
    .flower-circle {
      border-radius: 50%;
      width: 280px;
      height: 280px;
      border: 1px solid #000;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      .circle-item {
        width: 260px;
        height: 260px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
    .flower-preview-content {
      border: 1px solid #231f20;
      background: #fff;
      text-align: left;
      padding: 24px 61px 32px 41px;
      max-width: 238px;
      position: relative;
      top: 67px;
      z-index: 1;
      display: flex;
      flex-direction: column;
      .close-text {
        display: inline-block;
        align-self: flex-end;
        span {
          color: #5e5929;
          font-family: BN Bergen St;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          cursor: pointer;
        }
      }
      .flower-name {
        color: #000;
        font-family: LTC Caslon;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0.96px;
      }
      .description {
        color: #000;
        font-family: Brandon Grotesque;
        font-size: 16px;
        font-style: normal;
        font-weight: 390;
        line-height: 24px;
      }

      .actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 11px;
        .item {
          border-radius: 999px;
          border: 1px solid #bdbcbc;
          padding: 16px 22px;
          cursor: pointer;
          flex: 1 0 auto;
          text-align: center;
          &.selected {
            border: 1px solid #f97;
            background: #f97;
          }
        }
      }
      .alt-colors-available {
        font-family: Brandon Grotesque;
        font-size: 14px;
        color: #231F20;
        line-height: 21px;
        margin: 8px 0 16px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .flower-preview-wrapper {
      flex-direction: column;
      align-items: center;
      padding: 20px;
      &::before {
        content: ' ';
        position: absolute;
        height: 1px;
        background: #000;
        width: 40%;
        left: 40px;
        top: 100px;
        z-index: 0;
      }
      &::after {
        content: ' ';
        position: absolute;
        background: #000;
        width: 1px;
        height: 182px;
        left: 40px;
        top: 100px;
        z-index: 0;
      }
      .flower-circle {
        width: 220px;
        height: 220px;
        .circle-item {
          width: 200px;
          height: 200px;
        }
      }
      .flower-preview-content {
        max-width: unset;
        top: 0;
      }
    }
  }
}

.guinea-pig-custom {
  height: calc(100vh - 64px) !important;
  top: 64px !important;
}
.guinea-pig-wrapper {
  .guinea-pig {
    display: flex;
    flex: 1;
    .left-part {
      width: 50%;
    }
    .right-part {
      width: 50%;
    }
    .left-part {
      height: calc(100vh - 64px);
      .bg-image {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .right-part {
      height: calc(100vh - 64px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > div {
        overflow-y: auto;
        color: #231f20;
        p,
        h2,
        h3 {
          padding: 0;
          margin: 0;
        }
        .title {
          color: #231F20;
          text-align: center;
          // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-family: 'LTC Caslon';
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;          
          margin-bottom: 40px;
        }
        .subtitle {
          color: #231F20;
          text-align: center;
          font-family: 'Brandon Grotesque';
          font-size: 20px;
          font-style: normal;
          font-weight: 390;
          line-height: 30px;
          margin-bottom: 10px;
        }
        .description {
          color: #231F20;
          text-align: center;
          font-family: 'Brandon Grotesque';
          font-size: 16px;
          font-style: normal;
          font-weight: 390;
          line-height: 24px;
        }
        .input {
          &::placeholder {
            font-family: 'Brandon Grotesque';
            font-style: normal;
            font-weight: 390;
            font-size: 16px;
            line-height: 24px;
            color: #8c8c8c;
          }
        }
        .content-above-box {
          width: 330px;
          max-width: 100%;
          margin: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
          text-align: center;
          .action-box-wrapper {
            justify-content: center;
            margin-top: 20px;
            .btn-primary {
              color: #F2F1EA;
              font-family: 'Brandon Grotesque';
              font-size: 16px;
              font-style: normal;
              font-weight: 390;
              line-height: 18px;
            }
          }
        }
        .content-bellow-box {
          width: 342px;
          margin: auto;
          max-width: 100%;
          background: #E5E3D9;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 40px 30px;
          .action-box-wrapper {
            justify-content: center;
            margin-top: 20px;
  
            .btn-secondary {
              border-radius: 10px;
              border: 1px solid #5E5929;
              background: #F2F1EA;
              color: #5E5929;
              font-family: 'Brandon Grotesque';
              font-size: 16px;
              font-style: normal;
              font-weight: 390;
              line-height: 18px;
            }
            .btn-secondary:disabled {
              opacity: 0.4;
            }
          }
        }
      }
    }
    .part-bg {      
      background-image: url('../../../../assets/images/Lupine_Curved_Grayscale_2.png');
      background-repeat: no-repeat;
      background-size: auto;
      background-position: -15px calc(100% + 15px);
    }
  }
  @media only screen and (max-width: 820px) {
    .guinea-pig {
      flex-direction: column;
      gap: 20px;
      .left-part {
        width: 100%;
        height: auto;
        .bg-image {
          height: 260px;
        }
      }
      .right-part {
        width: auto;
        height: auto;
        padding: 0px;
        align-items: center;
        > div {
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .title {
            font-size: 24px;
            margin-bottom: 10px;
          }
          .subtitle {
            font-size: 22px;
          }
          .description {
            font-size: 16px;
          }
          .content-above-box, .content-bellow-box {
            width: auto;
            padding: 30px 25px 50px;
          }
          .content-above-box {
            margin-bottom: 0px;
          }
          .action-box-wrapper {
            justify-content: center;
            button {
              width: 60%;
            }
          }
        }
      }
      .part-bg {
        background-image: none;
      }
    }
  }
}

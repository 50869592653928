.flower-gallery {
  figure {
    margin: 0;
  }
  .gallery-wrapper {
    display: grid;
    grid-template-columns: 25% 30% 25%;
    grid-template-rows: repeat(9, 85px);
    grid-gap: 10px;
    .gallery-item {
      position: relative;
      &.gallery-item--1 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
      }
      &.gallery-item--2 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 7;
      }
      &.gallery-item--3 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 7;
        grid-row-end: 10;
      }
      &.gallery-item--4 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 6;
      }
      &.gallery-item--5 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 6;
        grid-row-end: 10;
      }
      &.gallery-item--6 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
      }
      &.gallery-item--7 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 6;
      }
      &.gallery-item--8 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 6;
        grid-row-end: 8;
      }
      &.gallery-item--9 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 8;
        grid-row-end: 10;
      }
      &.gallery-item--10 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 4;
      }
      &.gallery-item--11 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 4;
        grid-row-end: 7;
      }
      &.gallery-item--12 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 7;
        grid-row-end: 10;
      }
    }
    .gallery-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width:756px) {
    .gallery-wrapper {
      display: grid;
      grid-template-columns: 50%;
      grid-template-rows: repeat(13, auto);
      grid-gap: 10px;
      .gallery-item {
        position: relative;
        &.gallery-item--1 {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 1;
          grid-row-end: 4;
        }
        &.gallery-item--2 {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 3;
        }
        &.gallery-item--3 {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 4;
          grid-row-end: 6;
        }
        &.gallery-item--4 {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 3;
          grid-row-end: 6;
        }
        &.gallery-item--5 {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 6;
          grid-row-end: 9;
        }
        &.gallery-item--6 {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 9;
          grid-row-end: 12;
        }
        &.gallery-item--7 {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 9;
          grid-row-end: 11;
        }
        &.gallery-item--8 {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 12;
          grid-row-end: 14;
        }
        &.gallery-item--9 {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 11;
          grid-row-end: 14;
        }
        &.gallery-item--10 {
          display: none;
        }
        &.gallery-item--11 {
          display: none;
        }
        &.gallery-item--12 {
          display: none;
        }
      }
    }
  }
}

.story-images-wrapper {
  .image-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .color-item {
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }
  .story-images {
    height: 1150px;
    position: relative;
    overflow: hidden;

    &.max-item-1 {
      height: 470px * 0.75;
    }
    &.max-item-2 {
      height: 710px * 0.75;
    }
    &.max-item-3 {
      height: 910px * 0.75;
    }
    &.max-item-4 {
      height: 1130px * 0.75;
    }
    &.max-item-5 {
      height: 1025px;
    }
    &.max-item-6 {
      height: 1599px * 0.75;
    }
    .item {
      overflow: hidden;
      position: absolute;
      &.image-item {
        &.--1 {
          width: 310px * 0.75;
          height: 294px * 0.75;
          left: 0;
          z-index: 0;
        }
        &.--2 {
          width: 241px * 0.75;
          height: 294px * 0.75;
          right: 0;
          top: 323px * 0.75;
          z-index: 2;
        }
        &.--3 {
          width: 206px * 0.75;
          height: 245px * 0.75;
          top: 520px * 0.75;
          left: 0;
          z-index: 4;
        }
        &.--4 {
          width: 256px * 0.75;
          height: 385px * 0.75;
          top: 728px * 0.75;
          right: 0;
          z-index: 6;
        }
        &.--5 {
          width: 279px * 0.75;
          height: 234px * 0.75;
          top: 1015px * 0.75;
          left: 0;
          z-index: 8;
        }
        &.--6 {
          width: 288px * 0.75;
          height: 317px * 0.75;
          right: 0;
          top: 1241px * 0.75;
          z-index: 10;
        }
      }
      &.color-item {
        &:nth-child(even) {
          transform-origin: right center
        }
        &:nth-child(odd) {
          transform-origin: left center;
        }
        &.--1 {
          top: 245px * 0.75;
          z-index: 1;
        }
        &.--2 {
          right: 0;
          top: 544px * 0.75;
          z-index: 3;
        }
        &.--3 {
          top: 691px * 0.75;
          z-index: 5;
        }
        &.--4 {
          top: 942px * 0.75;
          right: 0;
          z-index: 7;
        }
        &.--5 {
          top: 1140px * 0.75;
          z-index: 9;
        }
      }
    }

    @media only screen and (min-width: 390px) {
      &.max-item-1 {
        height: 470px;
      }
      &.max-item-2 {
        height: 710px;
      }
      &.max-item-3 {
        height: 910px;
      }
      &.max-item-4 {
        height: 1130px;
      }
      &.max-item-5 {
        height: 1360px;
      }
      &.max-item-6 {
        height: 1599px;
      }
      .color-item {
        width: 215px;
        height: 215px;
      }
      .item {
        &.image-item {
          &.--1 {
            width: 310px;
            height: 294px;
            left: 0;
            z-index: 0;
          }
          &.--2 {
            width: 241px;
            height: 294px;
            top: 323px;
            left: 101px;
            z-index: 2;
          }
          &.--3 {
            width: 206px;
            height: 245px;
            top: 520px;
            left: 0;
            z-index: 4;
          }
          &.--4 {
            width: 256px;
            height: 385px;
            top: 728px;
            left: 86px;
            z-index: 6;
          }
          &.--5 {
            width: 279px;
            height: 234px;
            top: 1015px;
            left: 0px;
            z-index: 8;
          }
          &.--6 {
            width: 288px;
            height: 317px;
            left: 54px;
            top: 1241px;
            z-index: 10;
          }
        }
        &.color-item {
          &.--1 {
            top: 245px;
            z-index: 1;
          }
          &.--2 {
            right: 0;
            left: unset;
            top: 544px;
            z-index: 3;
          }
          &.--3 {
            top: 691px;
            z-index: 5;
          }
          &.--4 {
            top: 942px;
            left: unset;
            right: 0;
            z-index: 7;
          }
          &.--5 {
            top: 1130px;
            left: 0;
            z-index: 9;
          }
        }
      }
    }
    @media only screen and (min-width: 768px) {
      &.max-item-1,
      &.max-item-2 {
        height: 300px;
      }
      &.max-item-3,
      &.max-item-4 {
        height: 450px;
      }
      &.max-item-5,
      &.max-item-6 {
        height: 1035px * 0.6;
      }
      .item {
        &.image-item {
          &.--1 {
            width: 270px * 0.6;
            height: 274px * 0.6;
            left: 40px * 0.6;
            z-index: 0;
          }
          &.--2 {
            width: 270px * 0.6;
            height: 273px * 0.6;
            left: 333px * 0.6;
            top: 24px * 0.6;
            z-index: 2;
          }
          &.--3 {
            width: 268px * 0.6;
            height: 320px * 0.6;
            left: 134px * 0.6;
            top: 244px * 0.6;
            z-index: 3;
          }
          &.--4 {
            width: 276px * 0.6;
            height: 359px * 0.6;
            top: 336px * 0.6;
            left: 430px * 0.6;
            z-index: 4;
          }
          &.--5 {
            width: 323px * 0.6;
            height: 253px * 0.6;
            left: 40px * 0.6;
            top: 579px * 0.6;
            z-index: 6;
          }
          &.--6 {
            width: 269px * 0.6;
            height: 296px * 0.6;
            left: 437px * 0.6;
            top: 715px * 0.6;
            z-index: 6;
          }
        }
        &.color-item {
          width: 120px;
          height: 120px;
          &.--1 {
            top: 140px * 0.6;
            left: 202px * 0.6;
            z-index: 1;
          }
          &.--2 {
            left: 511px * 0.6;
            top: 248px * 0.6;
            z-index: 3;
          }
          &.--3 {
            top: 350px * 0.6;
            z-index: 4;
          }
          &.--4 {
            top: 543px * 0.6;
            left: 333px * 0.6;
            z-index: 5;
          }
          &.--5 {
            top: 715px * 0.6;
            left: 200px;
            z-index: 5;
          }
        }
      }
    }
    @media only screen and (min-width: 1024px) {
      &.max-item-1,
      &.max-item-2 {
        height: 350px;
      }
      &.max-item-3,
      &.max-item-4 {
        height: 600px;
      }
      &.max-item-5,
      &.max-item-6 {
        height: 835px;
      }
      .item {
        &.image-item {
          &.--1 {
            width: 270px * 0.8;
            height: 274px * 0.8;
            left: 40px * 0.8;
            z-index: 0;
          }
          &.--2 {
            width: 270px * 0.8;
            height: 273px * 0.8;
            left: 333px * 0.8;
            top: 24px * 0.8;
            z-index: 2;
          }
          &.--3 {
            width: 268px * 0.8;
            height: 320px * 0.8;
            left: 134px * 0.8;
            top: 244px * 0.8;
            z-index: 3;
          }
          &.--4 {
            width: 276px * 0.8;
            height: 359px * 0.8;
            top: 336px * 0.8;
            left: 430px * 0.8;
            z-index: 4;
          }
          &.--5 {
            width: 323px * 0.8;
            height: 253px * 0.8;
            left: 40px * 0.8;
            top: 579px * 0.8;
            z-index: 6;
          }
          &.--6 {
            width: 269px * 0.8;
            height: 296px * 0.8;
            left: 437px * 0.8;
            top: 715px * 0.8;
            z-index: 6;
          }
        }
        &.color-item {
          width: 200px * 0.8;
          height: 200px * 0.8;
          &.--1 {
            top: 140px * 0.8;
            left: 202px * 0.8;
            z-index: 1;
          }
          &.--2 {
            left: 511px * 0.8;
            top: 248px * 0.8;
            z-index: 3;
          }
          &.--3 {
            top: 350px * 0.8;
            z-index: 4;
          }
          &.--4 {
            top: 543px * 0.8;
            left: 333px * 0.8;
            z-index: 5;
          }
          &.--5 {
            top: 715px * 0.8;
            left: 260px;
            z-index: 5;
          }
        }
      }
    }
    @media only screen and (min-width: 1280px) {
      &.max-item-1,
      &.max-item-2 {
        height: 400px;
      }
      &.max-item-3,
      &.max-item-4 {
        height: 730px;
      }
      &.max-item-5,
      &.max-item-6 {
        height: 1035px;
      }
      .item {
        &.image-item {
          &.--1 {
            width: 270px;
            height: 274px;
            z-index: 0;
            left: 40px;
          }
          &.--2 {
            width: 270px;
            height: 273px;
            left: 333px;
            top: 24px;
            z-index: 2;
          }
          &.--3 {
            width: 268px;
            height: 320px;
            left: 134px;
            top: 244px;
            z-index: 3;
          }
          &.--4 {
            width: 276px;
            height: 359px;
            top: 336px;
            left: 430px;
            z-index: 4;
          }
          &.--5 {
            width: 323px;
            height: 253px;
            left: 40px;
            top: 579px;
            z-index: 6;
          }
          &.--6 {
            width: 269px;
            height: 296px;
            left: 437px;
            top: 715px;
            z-index: 6;
          }
        }
        &.color-item {
          width: 200px;
          height: 200px;
          &.--1 {
            top: 140px;
            left: 202px;
            z-index: 1;
          }
          &.--2 {
            left: 511px;
            top: 248px;
            z-index: 3;
          }
          &.--3 {
            top: 350px;
            z-index: 4;
          }
          &.--4 {
            top: 543px;
            left: 333px;
            z-index: 5;
          }
          &.--5 {
            top: 715px;
            left: 350px;
            z-index: 5;
          }
        }
      }
    }
    @media only screen and (min-width: 1440px) {
      &.max-item-1,
      &.max-item-2 {
        height: 450px;
      }
      &.max-item-3,
      &.max-item-4 {
        height: 830px;
      }
      &.max-item-5,
      &.max-item-6 {
        height: 1150px;
      }
      .item {
        &.image-item {
          &.--1 {
            width: 270px * 1.12;
            height: 274px * 1.12;
            left: 40px * 1.12;
            z-index: 0;
          }
          &.--2 {
            width: 270px * 1.12;
            height: 273px * 1.12;
            left: 333px * 1.12;
            top: 24px * 1.12;
            z-index: 2;
          }
          &.--3 {
            width: 268px * 1.12;
            height: 320px * 1.12;
            left: 134px * 1.12;
            top: 244px * 1.12;
            z-index: 3;
          }
          &.--4 {
            width: 276px * 1.12;
            height: 359px * 1.12;
            top: 336px * 1.12;
            left: 430px * 1.12;
            z-index: 4;
          }
          &.--5 {
            width: 323px * 1.12;
            height: 253px * 1.12;
            left: 40px * 1.12;
            top: 579px * 1.12;
            z-index: 6;
          }
          &.--6 {
            width: 269px * 1.12;
            height: 296px * 1.12;
            left: 437px * 1.12;
            top: 715px * 1.12;
            z-index: 6;
          }
        }
        &.color-item {
          width: 200px * 1.12;
          height: 200px * 1.12;
          &.--1 {
            top: 140px * 1.12;
            left: 202px * 1.12;
            z-index: 1;
          }
          &.--2 {
            left: 511px * 1.12;
            top: 248px * 1.12;
            z-index: 3;
          }
          &.--3 {
            top: 350px * 1.12;
            z-index: 4;
          }
          &.--4 {
            top: 543px * 1.12;
            left: 333px * 1.12;
            z-index: 5;
          }
          &.--5 {
            top: 715px * 1.12;
            left: 390px;
            z-index: 5;
          }
        }
      }
    }
  }
}

.color-story-section {
  .color-story {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 10px;
    padding: 0;
  }

  .color-story-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: auto;
    position: relative;
    padding: 0 24px;
    height: 249px;
  }

  .story-images-wrapper {
    width: 232px;
    padding: 0;
    margin: auto;
  }

  @media only screen and (min-width: 360px) {
    .story-images-wrapper {
      width: 312px;
    }
  }

  @media only screen and (min-width: 390px) {
    .story-images-wrapper {
      width: 342px;
    }
  }

  @media only screen and (min-width: 768px) {
    .color-story {
      flex-direction: row;
    }
    .color-story-content {
      height: 300px;
      width: 35%;
      position: sticky;
      top: 0;
    }
    .story-images-wrapper {
      width: 65%;
      padding-top: 40px;
    }
  }
}

@import 'src/assets/scss/base.scss';

.flexible-pricing {
  h3,
  p,
  label {
    margin: 0 0 8px 0;
  }
  h2 {
    font-family: 'LTC Caslon';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 60px;
  }
  h3 {
    font-family: 'LTC Caslon';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 42px;
  }
  p {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .pricing-header {
    .header-content {
      padding: 10px;
      .title {
        color: #231f20;
      }
      .subtitle {
        color: #636363;
      }
    }
  }
  .pricing-content {
    .tab-header {
      .header-content {
        display: flex;
        text-align: center;
        .tab-header-item {
          display: flex;
          flex: 1;
          padding: 20px;
          background-color: #e5e3d9;
          position: relative;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          &.active {
            background-color: #fff;
            &::before {
              position: absolute;
              content: '';
              background: #ff9977;
              width: 100%;
              height: 8px;
              top: 0px;
              left: 0;
              z-index: 2;
            }
          }
          .title {
            color: #231f20;
          }
          .subtitle {
            color: #636363;
          }
        }
      }
    }
    .tab-content {
      background-color: #fff;
      padding: 32px 0 59px 0;
      .title {
        color: #231f20;
      }
      .starting-at {
        font-family: 'BN Bergen St';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #636363;
      }
      .price {
        color: #231f20;
      }
      .description {
        color: #636363;
      }
      .final-price {
        color: #636363;
      }
      .packages {
        list-style: inside;
        padding: 0;
        color: #636363;
        li {
          font-family: 'Brandon Grotesque';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #636363;
        }
      }
      .sample-package {
        font-family: 'BN Bergen St';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #636363;
      }
      .book-now-btn {
        display: flex;
        .loader {
          margin-left: 5px;
          border: 3px solid $linen;
          border-top: 3px solid #eb4b32;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          animation: rotating 2s linear infinite;
        }
      }
      .mobile {
        display: none;
      }
      .desktop {
        display: flex;
        .left-content {
          width: 60%;
          .tab-content-header {
            margin-left: 40px;
          }
          .tab-content-description {
            display: flex;
            gap: 10px;
            margin-left: 30px;
            .description-wrapper {
              width: 65%;
              padding-right: 35px;
            }
            .packages-wrapper {
              width: 35%;
            }
          }
          .tab-content-action {
            display: flex;
            gap: 15px;
          }
        }
        .right-content {
          width: 40%;
          height: 440px;
        }
      }
    }
  }

  @media screen and (max-width: 756px) {
    .pricing-content {
      .tab-header {
        width: 100%;
        .header-content {
          gap: 20px;
          padding: 0 20px;
          .tab-header-item {
            background-color: transparent;
            border: 1px solid #231f20;
            border-radius: 40px;
            padding: 10px;
            &.active {
              background-color: #ff9977;
              &::before {
                content: none;
              }
            }
            .title {
              font-size: 12px;
              line-height: 15px;
              letter-spacing: 0.2em;
              margin: 0;
            }
            .subtitle {
              display: none;
            }
          }
        }
        .subtitle {
          font-size: 20px;
          color: #231f20;
          text-align: center;
          margin-top: 24px;
          margin-bottom: 16px;
        }
      }
      .tab-content {
        padding: 0 15px 24px;
        background-color: transparent;
        .package-content {
          background-color: #fff;
          padding: 24px;
        }
        .desktop {
          display: none;
        }
        .mobile {
          display: block;
          text-align: center;
          padding: 0 10px;
          .package-description {
            display: flex;
            justify-content: space-between;
            align-content: center;
            .title {
              text-align: start;
            }
            .right-content {
              display: flex;
              align-items: flex-end;
              .price {
                margin: 0;
              }
            }
          }
          .flower-card {
            position: relative;
            .flower {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .button {
            width: 80%;
            font-size: 12px;
          }
          .slick-dots li {
            margin: 0;
            button {
              &::before {
                font-size: 10px;
                color: #ffd2c3;
                opacity: 1;
              }
            }
            &.slick-active {
              button {
                &::before {
                  color: #ff9977;
                }
              }
            }
          }
        }
      }
    }
  }
}

.faqs-section {
  background-image: url('../../assets/images/introduce_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  height: auto;
  .flower {
    position: absolute;
    bottom: 0;
    z-index: 0;
    &.flower-1 {
      left: 0;
    }
    &.flower-2 {
      right: 0;
      max-height: 674px;
    }
  }
  .faqs-section-wrapper {
    z-index: 1;
    position: relative;
    .faq-header {
      width: 50%;
      margin: auto;
      .header-content {
        .title {
          font-family: 'LTC Caslon';
          font-style: normal;
          font-weight: 400;
          font-size: 48px;
          line-height: 60px;
          color: #ffffff;
        }
        .subtile {
          font-family: 'Brandon Grotesque';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
    }

    h4 {
      font-family: 'LTC Caslon';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #231f20;
    }
    .center {
      text-align: center;
    }
    .faqs-content {
      background-color: #fff;
      padding: 2rem;
      .accordion:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
    .action {
      margin: 54px auto;
    }
  }
  @media only screen and (max-width: 768px) {
    height: auto;
    padding: 10px;
    .faqs-section-wrapper {
      .faq-header {
        width: 100%;
      }
      .action {
        margin: 44px 0 80px 0;
        .button {
          width: 80%;
        }
      }
    }
    .flower {
      &.flower-2 {
        max-height: 389px;
      }
      &.flower-1 {
        display: none;
      }
    }
  }
}

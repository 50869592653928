@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&display=swap');

$padding-common: 6rem;
$padding-table-common: 2rem;

.customer_experience {
  * {
    box-sizing: border-box;
  }

  h2 {
    font-family: 'Playfair Display';
    line-height: 64px;
  }

  h3 {
    font-family: 'Nunito';
    line-height: 49px;
    font-size: 36px;
  }

  img {
    object-fit: cover;
  }

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  .congratulations-wrapper {
    padding-bottom: 3rem;
    background-color: #fbfaf8;
    .w-container {
      margin-left: auto;
      margin-right: auto;
      max-width: 1040px;
    }
  }

  .working-process-wrapper {
    background-color: #d7462d;
    text-align: center;
    padding: 2rem 7rem;
    margin-bottom: 5rem;
    color: #ffffff;

    .working-process {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-around;
    }

    .process-item {
      .item-no {
        font-weight: bold;
        font-size: 21px;
        margin-bottom: 10px;
      }

      .item-content {
        padding: 0 2rem;
        font-weight: normal;
        font-size: 16px;
        box-sizing: border-box;
        max-width: 300px;
      }
    }
  }

  .want_more-wrapper {
    background-image: url('../../assets/images/bg_want_more.png');
    height: 486px;
    width: 100%;
    object-fit: contain;
    padding: 5rem $padding-common;
    display: flex;
    background-size: cover;

    h3 {
      font-family: 'Playfair Display';
      margin-top: 0;
    }

    .want_more-content {
      width: 549px;
      font-size: 18px;
      align-self: center;
      background-color: #f8f4f0;
      padding: 4rem 3rem;
    }
  }

  .product {
    display: flex;
    background-color: #fdf3ed;
    padding: 2rem $padding-common;

    .product-list {
      width: 70%;

      .product-option {
        margin-bottom: 3rem;
      }

      .option-quantity {
        .quantity-input {
          background-color: #d7462d;
          color: #ffffff;
          fill: #ffffff;
        }

        input {
          color: #ffffff;
        }
      }

      .option-title {
        align-items: center;
        align-content: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .title-name {
          font-size: 36px;
          line-height: 49px;
          margin-right: 1rem;
          font-weight: 400;
        }
      }

      .option-list {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        column-gap: 1rem;
        row-gap: 1rem;
        margin-right: 3rem;
      }

      .option-item {
        background-color: #ffffff;
        border-radius: 4px;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &:hover {
          outline: 1px solid #ffa3a3;
        }

        img {
          aspect-ratio: 1 / 1;
          display: block;
          width: 100%;
          height: 100%;
        }

        .item-info {
          border-radius: 0 0 4px 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem 1rem;

          .tooltip-text {
            top: -2rem;
            left: 0rem;
            width: 200px;
          }

          .name-price-wrapper {
            overflow: hidden;
          }

          .item-name {
            font-weight: 400;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .item-price {
            font-size: 13px;
            font-weight: 300;
          }
        }
      }
    }

    .cart-wrapper {
      margin-top: 4rem;
      width: 30%;
      padding: 1.5rem;
      background: url('../../assets/images/vector.png');
      background-size: 100% 100%;
      height: fit-content;

      .cart-products {
        display: flex;
        flex-direction: column;
      }

      .cart-title {
        font-size: 33px;
        line-height: 45px;
      }

      .cart-item {
        display: flex;
        justify-content: space-between;
        margin: 13px auto;
        width: 100%;
        line-height: 25px;

        &.cart-total {
          font-size: 20px;
          padding: 20px 0 10px;
        }

        .item-price {
          font-weight: 700;
        }
      }

      .line {
        border-top: 0.5px solid #a49e9a;
        margin: 22px auto;
      }

      .button-checkout {
        width: 100%;
        margin: 15px auto 5px;
      }

      .note {
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 16px;
        color: #000;
      }

      .photographer {
        font-weight: 300;
        font-size: 10px;
        font-style: italic;
        line-height: 11px;
      }
    }
  }

  @media screen and (min-width: 1025px) {
    .as-seen-in {
      padding: 2rem 0;
      .div-block-164 {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .want_more-wrapper {
      padding: 1rem $padding-table-common;
    }

    .working-process-wrapper {
      padding: 1rem $padding-table-common;

      h3 {
        text-align: center;
      }
    }

    .product {
      padding: 1rem $padding-table-common;

      .product-list {
        .option-item {
          .item-info {
            .item-name {
              max-width: 100px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

    h3 {
      font-family: 'Nunito';
      font-size: 28px;
      line-height: 40px;
      text-align: center;
    }

    .working-process-wrapper {
      text-align: center;

      .working-process {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        flex-direction: column;
        padding-top: 1rem;

        .process-item {
          width: 100%;
          flex-direction: column;
          .item-content {
            margin: 1rem auto;
          }

          .line-red {
            width: 50%;
            border-top: 1px solid #ffffff;
            margin: 2rem auto;
          }

          &:last-child {
            .line-red {
              display: none;
            }
          }
        }
      }
    }

    .want_more-wrapper {
      background: none;
      display: block;
      padding: 0;
      height: auto;

      .want_more-img {
        display: block;
        width: 100%;
      }
      .want_more-content {
        width: 100%;
        text-align: center;
        background-color: #ffffff;
        padding: 2rem 3rem;
      }
    }

    .as-seen-in {
      background-color: #ffffff;
    }

    .product {
      flex-direction: column;
      padding: 1rem 0;

      .cart-wrapper {
        width: auto;
        margin-top: 2rem;
        margin: 2rem $padding-table-common;
        box-sizing: border-box;
      }

      .product-list {
        width: 100%;

        .option-list {
          margin: 0;
          display: block;
        }

        .swipe-note {
          text-align: center;
          width: 255px;
          margin: 2rem auto;
          font-size: 22px;
          line-height: 26px;
        }

        .option-title {
          width: 100%;
          display: block;
          margin-bottom: 1rem;

          .title-name {
            width: 100%;
            text-align: center;
          }
        }

        .option-item {
          height: 400px;
          justify-content: flex-end;
          margin: 0;

          img {
            height: 100%;
          }
        }

        .carousel-center {
          .slick-active {
            margin: 0 auto;

            .option-item {
              background: #ffffff !important;
            }
          }

          .slick-slide {
            padding: 0 0.5rem;

            .option-item {
              background: rgba(0, 0, 0, 0.2);
            }
          }

          .slick-next {
            &::before {
              content: none;
            }
            right: 5px;
            height: 100%;
            text-align: center;
            width: 2rem;

            svg {
              height: 100%;
            }
          }

          .slick-prev {
            &::before {
              content: none;
            }
            left: 5px;
            z-index: 1;
            height: 100%;
            text-align: center;
            width: 2rem;

            svg {
              height: 100%;
            }
          }
        }

        .option-quantity-wrapper {
          margin: 2rem auto;
          text-align: center;

          .option-quantity {
            height: 40px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 479px) {
  }
}

.proposal-header-wrapper {
  background-color: #f2f1ea;
  width: 100%;
  height: 56px;
  overflow-x: auto;

  .proposal-header {
    display: flex;
    justify-content: space-between;
    padding: 7px 1.5rem;
    .left-menu {
      display: flex;
      align-items: center;
      gap: 56px;
      .menu-item {
        height: 42px;
        cursor: pointer;
        align-items: center;
        display: flex;
        .menu-title {
          font-family: 'BN Bergen St';
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          color: #231f20;
        }
        &.active {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            bottom: -7px;
            left: 0;
            height: 8px;
            background-color: #ff9977;
          }
        }
      }
    }
  }

  .book-now-btn {
    display: flex;
    justify-content: center;
    width: 155px;
    height: 42px;
    border-radius: 40px;
    padding: 0;

    font-family: 'BN Bergen St';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #F2F1EA;
  }

  @media (max-width: 768px) {
    .proposal-header {
      width: auto;
      min-width: 768px;
    }
  }
}

@mixin square($size, $radius: 0) {
  width: $size;
  height: $size;

  @if $radius != 0 {
    border-radius: $radius;
  }
}

@mixin position($top: 0, $left: 0) {
  position: absolute;
  top: $top;
  left: $left;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  -moz-transform: $property;
  -o-transform: $property;
  transform: $property;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: '') {
  -webkit-box-shadow: $top $left $blur $color unquote($inset + '');
  -moz-box-shadow: $top $left $blur $color unquote($inset + '');
  box-shadow: $top $left $blur $color unquote($inset + '');
}

@mixin bordered($width, $color) {
  border: $width solid $color;
}

@mixin remove-arrows {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  -ms-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    $browser: '-webkit-' !global;
    @content;
  }
  @-moz-keyframes #{$animationName} {
    $browser: '-moz-' !global;
    @content;
  }
  @-o-keyframes #{$animationName} {
    $browser: '-o-' !global;
    @content;
  }
  @keyframes #{$animationName} {
    $browser: '' !global;
    @content;
  }
}
$browser: null;

@include keyframes(rotating) {
  0% {
    #{$browser}transform: rotate(0deg);
  }
  100% {
    #{$browser}transform: rotate(360deg);
  }
}

@mixin custom-scrollbar {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 white;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 25px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 25px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: #c4c4c4;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #b4b4b4;
  }
}

// Poppy custom breakpoints
$breakpoints: (
  "xs": 390px,
  "sm": 768px,
  "md": 1024px,
  "lg": 1280px,
  "xl": 1920px
);

@mixin responsive-down($breakpoint) {
  $value: map-get($breakpoints, $breakpoint);
  @if $value != null {
    @media (max-width: ($value)) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Please make sure it is defined in `$breakpoints` map.";
  }
}

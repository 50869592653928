$bgColorSection: #F2F1EA;

.section-footer {
  background-color: $bgColorSection;
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  background-image: url('../../assets/images/bg-footer.png');
  background-repeat: no-repeat;
  background-position: right bottom;
  .section-footer-wrapper {
    display: flex;
    .section-left {
      width: 50%;
      display: flex;
      flex-direction: column;
      margin-right: 25px;

      .footer-logo {

      }
      .footer-menu {
        display: flex;

        .menu-1, .menu-2 {
          ul {
            list-style: none;
            padding: 0;
            li {
              padding-top: 10px;
              padding-bottom: 10px;
              a {
                text-decoration: underline;
                font-family: 'LTC Caslon';
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                text-decoration-line: underline;
                color: #231F20;
              }
            }
          }
        }
        .menu-1 {
          margin-right: 100px;
        }
      }
    }
    .section-right {
      margin-left: 25px;
      width: 50%;
      .footer-title {
        font-family: 'LTC Caslon';
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          line-height: 48px;
          color: #231F20;
          margin-bottom: 0;
      }
      .footer-para {
        margin-top: 0;
        font-family: 'Brandon Grotesque';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #231F20;
      }
      .subscribe-box {
        background-color: transparent;
        display: flex;
        input, button {
          padding: 15px;
        }
        input {
          font-family: 'LTC Caslon';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #231F20;
          border: 1px solid #231F20;
          box-sizing: border-box;
          background-color: transparent;
        }
        input:focus-visible {
          outline: 0;
        }
        button {
          background: #EB4B32;
          font-family: 'BN Bergen St';
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          color: #F2F1EA;
          border: 1px solid #EB4B32;
          box-sizing: border-box;
          margin-left: -2px;
        }
      }
      .follow-icons {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        width: 100%;
        max-width: 240px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .section-footer {
    .section-footer-wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .section-footer {
    padding-top: 20px;
    padding-bottom: 30px;
    .section-footer-wrapper {
      flex-direction: column-reverse;
      .section-left, .section-right {
        width: 100%;
      }
      .section-right {
        margin-bottom: 50px;
        margin-left: 0;
        .subscribe-box {
          width: 100%;
        }
      }

      .section-left{
      .footer-menu{
        .menu-1 {
          margin-right: 36px;
        }
      }
    }
    }
  }
}

@media only screen and (max-width: 414px) {
  .section-footer {
    .section-footer-wrapper {
      .section-right {
        .subscribe-box {
          input {
            width: 100%;
          }
        }
      }
    }
  }
}

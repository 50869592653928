.poppy-header-wrapper {
  z-index: 1;
  .menu-text {
    color: #fff;
    font-family: Brandon Grotesque;
    font-size: 20px;
    font-style: normal;
    font-weight: 390;
    line-height: normal;
  }
  .poppy-header {
    background: #231f20;
    display: flex;
    justify-content: space-between;
    padding: 5px 35px;

    .poppy-logo-wrapper {
      display: flex;
      align-items: center;
      .poppy-logo {
        width: 90px;
        height: 19px;
        flex-shrink: 0;
        .text-logo {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .main-menu-wrapper {
      display: flex;
      gap: 40px;
      align-items: center;
      &.mobile {
        display: none;
      }
      .main-menu {
        display: flex;
        gap: 24px;
        .menu-item {
          text-decoration: none;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      padding: 15px 35px;
      .main-menu-wrapper {
        &.mobile {
          display: flex;
          .btn-menu {
            text-decoration: none;
            color: #fff;
            font-family: Brandon Grotesque;
            font-size: 20px;
            font-style: normal;
            font-weight: 390;
            line-height: normal;
            cursor: pointer;
          }
        }
        &.desktop {
          display: none;
        }
      }
    }
  }

  .poppy-header-overlay {
    width: 100%;
    position: absolute;
    background: #231f20;
    overflow: hidden;
    transition-duration: 0.3s;
    transition-property: max-height;
    transition-delay: 0s;
    max-height: 0;

    &.--show {
      max-height: 100%;
    }
    .main-menu {
      .menu-item {
        padding: 7px 15px;
      }
    }
  }
}

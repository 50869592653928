@import "src/assets/scss/base.scss"; 

$bgColorSection: #fbfaf8;

.call-scheduled {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  * {
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
    vertical-align: middle;
    object-fit: cover;
    border: 0;
  }

  .w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
  }

  h1 {
    margin-top: 20px;
    margin-bottom: 15px;
    font-family: "LTC Caslon";
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 80px;
    color: #231F20;
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "LTC Caslon";
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 48px;
    color: #231F20;
  }

  .text-block-91, .text-block-95 {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #231F20;
  }

  .button-copy {
    align-self: flex-start;
    margin-top: 15px;
    margin-bottom: 40px;
    text-decoration: none;
    font-weight: 800;
    background: #EB4B32;
    border: 1px solid #EB4B32;
    border-radius: 0;
    padding: 20px 30px;
    font-family: "BN Bergen St";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #F2F1EA;
  }

  .section-42 {
    background-color: $bgColorSection;
    padding-top: 40px;
    padding-bottom: 60px;

    .image-87 {
      display: none;
    }

    .heading-85 {
      text-align: center;
    }

    .text-block-95 {
      text-align: center;
    }

    .div-block-166 {
      width: 1px;
      margin-right: 40px;
      margin-left: 40px;
      background-color: $stem; 
      color: $stem;
    }
  }

  .section-37 {
    display: flex;
    padding-top: 60px;
    padding-bottom: 60px;
    flex-direction: column;
    background-color: #F2F1EA;

    .sneak-peek-desktop {
      display: flex;
      margin-top: 60px;
      justify-content: space-between;
    }

    .w-container {
      margin-left: auto;
      margin-right: auto;
      max-width: 940px;
    }

    .div-block-152 {
      display: flex;
      width: 50%;
      margin-right: 75px;
      margin-left: 0px;
      padding-bottom: 60px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .image-87 {
        display: none;
      }

      .heading-79 {
        font-size: 50px;
      }

      .button {
        background: #EB4B32;
        border: 1px solid #EB4B32;
        border-radius: 0;
        padding: 20px 30px;
        font-family: "BN Bergen St";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #F2F1EA;
      }

      .button.left-align.hidden-on-mobile {
        display: block;
        width: auto;
      }
    }
  }

  .section-38 {
    display: none;
  }

  .lookbook-wrapper{
    display: flex;
    justify-content: center;
  }

  .lookbook {
    display: inline-block;
    vertical-align: top;
    color: $night; 
    padding: 20px 20px 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Brandon Grotesque';
    color: $night; 
  }
}

@media screen and (max-width: 992px) {
  .call-scheduled {
    .button {
      margin-bottom: 20px;
    }

    .section-37 {
      .w-container {
        max-width: 728px;
      }
      .div-block-152 {
        margin-right: 10px;
        margin-left: 30px;
      }
    }
    .button.left-align {
      align-self: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .call-scheduled {
    .heading-79 {
      font-size: 36px;
    }
    .section-42 {
      background-color: #fbfaf8;

      .container-31 {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .image-87 {
        display: block;
        width: 88px;
      }

      .div-block-172 {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .text-block-95 {
        width: 80%;
        margin-bottom: 20px;
      }

      .div-block-166 {
        width: 80%;
        height: 1px;
      }
    }

    .section-37 {
      padding-top: 0px;
      padding-bottom: 0px;
      .sneak-peek-desktop {
        display: none;
      }
    }

    .lookbook-wrapper{
      display: none;
    }

    .section-38 {
      display: block;

      .container-29 {
        display: flex;
        padding-right: 40px;
        padding-left: 40px;
        flex-direction: column;
        align-items: center;
      }

      .hidden-on-desktop {
        display: block;
        width: 75%;
        padding-top: 10px;
        padding-right: 10px;
        padding-left: 10px;
      }

      .button.left-align {
        display: block;
        width: 100%;
      }
    }
  }

  .avatar-gif-mobile {
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 479px) {
  .call-scheduled {
    h1 {
      font-size: 36px;
      line-height: 52px;
    }
    .heading-79 {
      text-align: center;
    }

    .w-container {
      max-width: none;
    }

    .section-42 {
      .text-block-95 {
        width: 90%;
      }
    }

    .section-38 {
      .container-29 {
        padding-right: 20px;
        padding-left: 20px;
      }

      .hidden-on-desktop {
        width: auto;
        margin-right: 10px;
        margin-left: 10px;
        padding-bottom: 10px;
        text-align: left;
      }
    }
  }
}

.flower-preference-section {
  background: url('/assets/images/moodboard/flower-preference-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .flower-preference {
    padding: 7%;
  }
  .flower-preference-wrapper {
    display: flex;
    gap: 22px;
    justify-content: space-between;

    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: 451px;
    }
    .preference {
      text-align: center;
      .flower-excluding {
        margin-top: 21px;
      }
      .stem-name {
        font-family: Brandon Grotesque;
        font-size: 20px;
        font-style: normal;
        font-weight: 390;
        line-height: 30px;
      }
    }
    .list-stems {
      display: flex;
      gap: 40px;
      justify-content: space-between;
      .item {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
      }
      .stem {
        width: 182px;
        height: 182px;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .flower-preference-wrapper {
      flex-direction: column;
      .content {
        max-width: unset;
      }
      .preference {

        .flower-excluding {
          margin-top: 16px;
        }
      }
      .list-stems {
        justify-content: center;
        .stem {
          width: 120px;
          height: 120px;
        }
      }
    }
  }
  @media only screen and (max-width: 390px) {
    .flower-preference-wrapper {
      .list-stems {
        flex-direction: column;
        .stem {
          width: 120px;
          height: 120px;
        }
      }
    }
  }
}

.quiz-guest-bouquet-shape {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .image-option-basic {
    display: flex;
    flex-wrap: wrap;

    .image-option-card {
      max-width: 360px;
      font-family: 'Playfair Display';
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 20px;
      margin-bottom: 20px;

      .image-option-image {
        width: 187px;
        height: 145px;
        margin: 10px auto;

        img {
          display: initial;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .shape {
      height: 45px;
      width: 45px;
      margin-left: 1rem;
    }

    .image-option-label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media screen and (max-width: 991px) {
    .image-option-basic {
      .image-option-card {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        .image-option-image {
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .shape {
        height: 30px;
        width: 30px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .image-option-basic {
      display: flex;
      flex-direction: column;

      .image-option-card {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        .image-option-image {
          img {
            height: auto;
          }
        }
        .image-option-label {
          top: 82.59%;
        }
      }
    }
  }
}

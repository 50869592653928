.justify-content-center {
  justify-content: center;
}

.row-reverse {
  flex-direction: row-reverse;
}

.justify-content-between {
  justify-content: space-between;
}

.visibility-hidden {
  visibility: hidden;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.align-items-center {
  align-items: center;
}

.justify-items-center {
  justify-items: center;
}

.flex-direction-column {
  flex-direction: column
}

.text-transform-upper {
  letter-spacing: 0.25px;
  text-transform: uppercase;
}

.center-all {
  display: flex;
  justify-content: center;
  justify-items: center;
}

// font
.fz-20 {
  font-size: 20px;
}
.fz-15rem {
  font-size: 1.5rem;
}

// z-index
.z-1 {
  z-index: 1;
}

// COLOR
.bg-white {
  background-color: #ffffff;
}

// Typography
h2 {
  font-size: 48px;
}
h3 {
  font-size: 32px;
}
.font-bold-btn {
  font-weight: bold;
  letter-spacing: 0.25px;
  text-transform: uppercase;
}

// Layout
.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 13px;
}

.grid-5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.gap-10 {
  gap: 10px
}

.d-none {
  display: none;
}

// transform
.rotate-180 {
  transform: rotate(180deg);
}

// =========
// display
// =========
.show-sm {
  display: none !important;
}
.hidden-sm {
  display: block !important;
}

.visibility-hidden {
  visibility: hidden;
}

.mw-50-rem {
  max-width: 50rem;
}

// =========
// Mobile
// =========
@media only screen and (max-width: 768px) {
  h2 {
    font-size: 38px;
  }
  h3 {
    font-size: 26px;
  }

  .show-sm {
    display: block !important;
  }
  .hidden-sm {
    display: none !important;
  }
}
.palette-image-container {
  .palette-image {
    width: 100%;
    height: 100%;
    position: relative;
    .circle {
      border-radius: 50%;
      position: absolute;
      transition: 0.5s;
      transition-property: background-color top left width height;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .color {
      width: 120px;
      height: 120px;
      &.--01 {
        left: 160px;
        z-index: 1;
      }
      &.--02 {
        left: 238px;
        top: 14px;
        z-index: 2;
      }
      &.--03 {
        top: 120px;
        left: 120px;
        z-index: 4;
      }
      &.--04 {
        left: 0;
        top: 241px;
        z-index: 0;
      }
      &.--05 {
        left: 72px;
        top: 240px;
        z-index: 2;
      }
      &.--06 {
        left: 240px;
        top: 200px;
        z-index: 4;
      }
    }
  }

  // LAYOUT A
  &.layoutA {
    min-height: 370px;
    width: 360px;
    .image {
      &.--01 {
        top: 14px;
        left: 12px;
        z-index: 0;
        width: 150px;
        height: 150px;
      }
      &.--02 {
        top: 154px;
        z-index: 1;
        width: 160px;
        height: 160px;
      }
      &.--03 {
        top: 55px;
        left: 200px;
        z-index: 2;
        width: 160px;
        height: 160px;
      }
      &.--04 {
        left: 170px;
        top: 220px;
        z-index: 4;
        width: 150px;
        height: 150px;
      }
    }
    .color {
      &.--01 {
        left: 138px;
        z-index: 1;
      }
      &.--02 {
        display: none;
      }
      &.--03 {
        left: 110px;
        top: 120px;
        z-index: 3;
      }
      &.--04 {
        display: none;
      }
      &.--05 {
        display: none;
      }
      &.--06 {
        display: none;
      }
    }
  }
  // LAYOUT B
  &.layoutB {
    min-height: 380px;
    width: 350px;
    .image {
      &.--01 {
        top: 13px;
        left: 2px;
        z-index: 0;
        width: 160px;
        height: 160px;
      }
      &.--02 {
        top: 183px;
        z-index: 0;
        width: 150px;
        height: 150px;
      }
      &.--03 {
        top: 82px;
        left: 200px;
        z-index: 2;
        width: 150px;
        height: 150px;
      }
      &.--04 {
        left: 160px;
        top: 220px;
        z-index: 4;
        width: 160px;
        height: 160px;
      }
    }

    .color {
      &.--01 {
        left: 148px;
        z-index: 1;
      }
      &.--02 {
        display: none;
      }
      &.--03 {
        left: 90px;
        top: 120px;
        z-index: 3;
      }
      &.--04 {
        display: none;
      }
      &.--05 {
        display: none;
      }
      &.--06 {
        display: none;
      }
    }
  }
  // LAYOUT C
  &.layoutC {
    min-height: 388px;
    width: 360px;
    .image {
      &.--01 {
        top: 14px;
        left: 12px;
        z-index: 0;
        width: 150px;
        height: 150px;
      }
      &.--02 {
        top: 140px;
        z-index: 1;
        width: 160px;
        height: 160px;
      }
      &.--03 {
        top: 54px;
        left: 184px;
        z-index: 2;
        width: 160px;
        height: 160px;
      }
      &.--04 {
        left: 170px;
        top: 220px;
        z-index: 4;
        width: 150px;
        height: 150px;
      }
    }

    .color {
      &.--01 {
        left: 150px;
        z-index: 1;
      }
      &.--02 {
        display: none;
      }
      &.--03 {
        left: 110px;
        top: 120px;
        z-index: 3;
      }
      &.--04 {
        display: none;
      }
      &.--05 {
        left: 94px;
        top: 266px;
        z-index: 2;
      }
      &.--06 {
        left: 260px;
        top: 186px;
        z-index: 3;
      }
    }
  }
  // LAYOUT D
  &.layoutD {
    min-height: 385px;
    width: 360px;
    .image {
      &.--01 {
        top: 13px;
        left: 2px;
        z-index: 0;
        width: 160px;
        height: 160px;
      }
      &.--02 {
        top: 183px;
        z-index: 1;
        width: 150px;
        height: 150px;
      }
      &.--03 {
        top: 82px;
        left: 190px;
        z-index: 2;
        width: 150px;
        height: 150px;
      }
      &.--04 {
        left: 160px;
        top: 220px;
        z-index: 4;
        width: 160px;
        height: 160px;
      }
    }

    .color {
      &.--01 {
        left: 148px;
        z-index: 1;
      }
      &.--02 {
        display: none;
      }
      &.--03 {
        left: 90px;
        top: 120px;
        z-index: 3;
      }
      &.--04 {
        display: none;
      }
      &.--05 {
        left: 94px;
        top: 265px;
        z-index: 2;
      }
      &.--06 {
        left: 252px;
        top: 195px;
        z-index: 3;
      }
    }
  }
  // LAYOUT E
  &.layoutE {
    min-height: 386px;
    width: 360px;
    .image {
      &.--01 {
        top: 14px;
        left: 12px;
        z-index: 0;
        width: 150px;
        height: 150px;
      }
      &.--02 {
        top: 154px;
        z-index: 1;
        width: 160px;
        height: 160px;
      }
      &.--03 {
        top: 54px;
        left: 200px;
        z-index: 2;
        width: 160px;
        height: 160px;
      }
      &.--04 {
        left: 170px;
        top: 220px;
        z-index: 4;
        width: 150px;
        height: 150px;
      }
    }

    .color {
      &.--01 {
        left: 138px;
        z-index: 1;
      }
      &.--02 {
        display: none;
      }
      &.--03 {
        left: 110px;
        top: 120px;
        z-index: 3;
      }
      &.--04 {
        display: none;
      }
      &.--05 {
        left: 94px;
        top: 266px;
        z-index: 2;
      }
      &.--06 {
        display: none;
      }
    }
  }
  // LAYOUT F
  &.layoutF {
    min-height: 386px;
    width: 340px;
    .image {
      &.--01 {
        top: 14px;
        left: 2px;
        z-index: 0;
        width: 160px;
        height: 160px;
      }
      &.--02 {
        top: 184px;
        z-index: 1;
        width: 150px;
        height: 150px;
      }
      &.--03 {
        top: 83px;
        left: 190px;
        z-index: 2;
        width: 150px;
        height: 150px;
      }
      &.--04 {
        left: 160px;
        top: 220px;
        z-index: 4;
        width: 160px;
        height: 160px;
      }
    }

    .color {
      &.--01 {
        left: 150px;
        z-index: 1;
      }
      &.--02 {
        display: none;
      }
      &.--03 {
        left: 90px;
        top: 120px;
        z-index: 3;
      }
      &.--04 {
        display: none;
      }
      &.--05 {
        left: 94px;
        top: 266px;
        z-index: 2;
      }
      &.--06 {
        display: none;
      }
    }
  }
  // LAYOUT G
  &.layoutG {
    min-height: 386px;
    width: 360px;
    .image {
      &.--01 {
        top: 14px;
        left: 25px;
        z-index: 1;
        width: 160px;
        height: 160px;
      }
      &.--02 {
        top: 184px;
        left: 23px;
        z-index: 1;
        width: 150px;
        height: 150px;
      }
      &.--03 {
        top: 83px;
        left: 200px;
        z-index: 4;
        width: 150px;
        height: 150px;
      }
      &.--04 {
        left: 185px;
        top: 220px;
        z-index: 7;
        width: 160px;
        height: 160px;
      }
    }

    .color {
      &.--01 {
        left: 160px;
        z-index: 2;
      }
      &.--02 {
        left: 260px;
        top: 40px;
        z-index: 3;
      }
      &.--03 {
        left: 110px;
        top: 120px;
        z-index: 5;
      }
      &.--04 {
        left: 0;
        top: 117px;
        z-index: 0;
      }
      &.--05 {
        left: 125px;
        top: 266px;
        z-index: 5;
      }
      &.--06 {
        left: 250px;
        top: 196px;
        z-index: 5;
      }
    }
  }
  // LAYOUT H
  &.layoutH {
    min-height: 360px;
    width: 360px;
    .image {
      &.--01 {
        top: 24px;
        left: 32px;
        z-index: 0;
        width: 150px;
        height: 150px;
      }
      &.--02 {
        top: 144px;
        z-index: 1;
        width: 160px;
        height: 160px;
      }
      &.--03 {
        top: 65px;
        left: 200px;
        z-index: 3;
        width: 160px;
        height: 160px;
      }
      &.--04 {
        left: 170px;
        top: 210px;
        z-index: 5;
        width: 150px;
        height: 150px;
      }
    }

    .color {
      &.--01 {
        left: 160px;
        z-index: 1;
      }
      &.--02 {
        left: 238px;
        top: 14px;
        z-index: 2;
      }
      &.--03 {
        top: 120px;
        left: 120px;
        z-index: 4;
      }
      &.--04 {
        left: 0;
        top: 241px;
        z-index: 0;
      }
      &.--05 {
        left: 72px;
        top: 240px;
        z-index: 2;
      }
      &.--06 {
        left: 240px;
        top: 200px;
        z-index: 4;
      }
    }
  }
}

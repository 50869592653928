.quiz-step {
  display: flex;
  padding: 10px 0;
  .item {
    flex: 1 0 auto;
    max-width: 100px;
  }
  .step-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.5;
    .step-icon {
      width: 20px;
      height: 20px;
    }
    .step-title {
      color: #231f20;
      text-align: center;
      font-family: BN Bergen St;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 2px;
    }
    &.active {
      opacity: 1;
    }
  }
  .step-line-wrapper {
    margin: auto;
    width: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    .step-line {
      height: 5px;
      background: rgb(255, 153, 119);
      opacity: 0.5;
      &.active {
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .step-wrapper {
      .step-icon {
        display: none;
      }
      .step-title {
        font-size: 8px;
        letter-spacing: 1.6px;
      }
    }
    .step-line-wrapper {
      padding: 0 5px;
    }
  }
}

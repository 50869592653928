@import "src/assets/scss/base.scss"; 

.nav {
  position: relative;
  height: 64px;
}

.section-header {
  background-color: $night; 
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; 
  top: 0; 
  width: 100%; 
  transition: height ease .6s;
  z-index: 1;
  .text-logo {
    width: 101.99px;
    height: 22px;
    position: absolute;
    top: 20px; 
    left: 30px;
    cursor: pointer;
  }
  .btn-menu {
    position: absolute;
    right: 3.73%;
    top: 25px;
    font-family: 'BN Bergen St';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.2em;
    color: #FFFFFF;
    transition: height ease .6s;
  }
}

.open-nav-wrapper{
  height: 100vh; 
  width: 100%;
  max-width: 100%;
  top:0;
  position: fixed;
  background-color: $night;
  transition: height ease .6s;
  z-index: 100; 

  .btn-menu{
    transition: height ease .6s;
  }
  
  .nav-content > div {
    width: 33%;
  }

  .nav-content{
    display: flex; 
    justify-content: center;
    align-items: center;
    margin-top: 100px; 
    height: calc(100% - 200px); 
    color: $linen !important; 

    .flower-arch {
      height: 345px; 
      display: block;
      margin-left: auto; 
      margin-right: auto; 
    }

    .nav-links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      animation: opacityEase ease 2s;
      a {
        padding-left: 100px;
        text-decoration: none;
      }
      h1 {
        color: $linen !important; 
        text-decoration: none;
        font-family: 'LTC Caslon';
        font-size: 3.5rem !important;
        line-height: '64px' !important;
        font-weight: 400;
      }
      a:hover h1{
        font-family: 'LTC Caslon Italic';
      }
    }

    .flowers {
      position: absolute; 
      bottom: 0; 
      right: 0; 
      max-height: 650px;
      opacity: 1; 
      animation: opacityEase ease 2s;
    }
  }
}

@keyframes opacityEase {
  0% {
      opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@media only screen and (max-width: 768px) {
  .open-nav-wrapper{
    .nav-content{
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 54px; 

      .nav-links {
        flex-grow: 1;
        a {
         padding-left: 0;
        }
      }

      .flower-arch-container{
        margin-left: auto;
        margin-right: auto;
        .flower-arch {
          height: auto; 
          width: 175px; 
        }
      }

      .flowers {
        display: none;
      }
    }
  }

}
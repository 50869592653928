@import 'src/assets/scss/base.scss';

$bgColorSection: #f2f1ea;

.section-36 {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $bgColorSection;

  img {
    max-width: 100%;
  }

  h1 {
    font-family: 'Playfair Display';
  }

  .button {
    text-transform: uppercase;
    border-radius: 24px;
    font-weight: 800;
    font-family: 'Nunito', sans-serif;
  }

  .button-secondary {
    background-color: transparent;
    opacity: 1;
    color: #d7462d;
    border: 1.5px solid #d7462d;
  }

  .button-wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;

    margin-top: 1rem;
  }

  .text-experience {
    flex-direction: row-reverse;
  }

  .container-33 {
    margin-bottom: 20px;
    a {
      display: block;
      -webkit-transition: opacity 200ms ease;
      transition: opacity 200ms ease;
      font-family: Nunito, sans-serif;
      color: #1a1b1f;
      text-decoration: underline;
      background-color: transparent;
    }

    .image-107 {
      display: none;
    }
    .div-block-191 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      min-height: 41px;
      padding-right: 50px;
      padding-left: 50px;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 49px;
      background-color: #d7462d;
    }
    .text-block-99 {
      padding-right: 10px;
      color: #fbfaf8;
    }

    .link-9 {
      color: #fbfaf8;
      text-transform: uppercase;
      cursor: pointer;
    }
    a:active,
    a:hover {
      outline: 0;
    }
    a:hover {
      color: #32343a;
    }
  }

  .container-28 {
    display: flex;
    justify-content: space-between;

    .div-block-152 {
      display: flex;
      width: 60%;
      padding: 0 1rem 0 56px;
      margin-right: 75px;
      margin-left: 0px;
      flex-direction: column;
      align-items: flex-start;

      .image-87 {
        display: none;
        object-fit: cover;
      }

      .heading-79 {
        font-family: 'LTC Caslon';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
        color: #231f20;
        margin: 0;
      }

      .text-block-91 {
        font-family: 'Brandon Grotesque';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #231f20;
        margin-top: 23px;
      }
      .book-now-text {
        font-family: 'LTC Caslon';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        color: #231f20;
        margin-top: 32px;
      }
    }

    .similar-flowers-wrapper {
      width: 40%;
      height: 100%;
      text-align: center;
    }

    .lookbook {
      display: inline-block;
      vertical-align: top;
      color: $night;
      padding: 20px 20px 10px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      font-family: 'Brandon Grotesque';
    }

    .div-block-153 {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        display: block;
        object-fit: cover;
        min-width: 100px;
        min-height: 200px;
        max-height: 433px;
      }
    }

    .div-block-192 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-top: 10px;
      .w-button {
        display: inline-block;
        padding: 9px 15px;
        background-color: #3898ec;
        color: white;
        border: 0;
        line-height: inherit;
        text-decoration: none;
        cursor: pointer;
        border-radius: 0;
      }
      .button {
        position: relative;
        top: auto;
        right: auto;
        bottom: 0%;
        z-index: auto;
        display: inline-block;
        width: auto;
        margin-top: 15px;
        padding: 24px 26px;
        float: none;
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        border-style: solid;
        border-width: 1px;
        border-color: #d7462d;
        border-radius: 0px;
        background-color: #d7462d;
        opacity: 0.75;
        -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
        transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
        font-family: 'BN Bergen St';
        font-style: normal;
        font-weight: bold;
        line-height: 21px;
        letter-spacing: 0.2em;
        color: #fbfaf8;
        font-size: 14px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
      }
      .button:hover {
        background-color: #d7462d;
        opacity: 1;
        color: #fff;
      }
      .button.button-secondary {
        border-width: 1.5px;
        background-color: transparent;
        opacity: 1;
        color: #5e5929;
        border-color: #5e5929;
      }
      .div-block-193 {
        width: 10px;
      }
      .hidden-on-mobile {
        width: 75px;
      }
      .button.left-align {
        display: block;
        -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
        align-self: flex-start;
      }
      .hidden-on-mobile {
        display: block;
        width: auto;
      }
      .book-now-btn {
        display: flex;
        .loader {
          margin-left: 5px;
          border: 3px solid $linen;
          border-top: 3px solid #eb4b32;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          animation: rotating 2s linear infinite;
        }
      }
    }
  }

  // table
  @media screen and (max-width: 992px) {
    .w-container {
      max-width: 728px;

      .div-block-152 {
        margin-right: 10px;
        margin-left: 30px;

        .heading-79 {
          margin-top: 0px;
        }

        .button-schedule-a-call {
          text-align: center;
          align-self: center;
        }
      }

      .similar-flowers-wrapper {
        margin-right: 30px;
      }

      .div-block-192 {
        .button {
          margin-bottom: 20px;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          opacity: 1;
        }
        .button.left-align {
          -webkit-align-self: center;
          -ms-flex-item-align: center;
          -ms-grid-row-align: center;
          align-self: center;
        }
      }
    }

    .mobile-style-text {
      width: 75%;
      padding-top: 10px;
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  .heading-78 {
    width: auto;
  }

  @media screen and (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 0px;
    background-color: inherit;

    .text-experience-mobile {
      flex-direction: column-reverse;

      .button-schedule-a-call {
        margin-bottom: 20px;
      }
    }

    .container-28 {
      flex-direction: column;
      align-items: center;

      .div-block-152 {
        width: auto;
        margin-right: 0px;
        margin-left: 0px;
        padding: 10px;
        align-items: center;

        .heading-79 {
          font-size: 36px;
          text-align: center;
        }

        .image-87 {
          display: block;
          width: 88px;
        }

        .text-block-91.hidden-sm {
          display: none;
        }
      }

      .div-block-153 {
        margin-top: 30px;
        img {
          max-height: 45vh;
        }
      }

      .div-block-192 {
        flex-direction: column;
        .button {
          margin-bottom: 10px;
          width: 80%;
        }
      }

      .button-schedule-a-call {
        display: none;

        &.show-sm {
          display: block;
        }
      }

      .button.left-align {
        align-self: center;
      }

      .similar-flowers-wrapper {
        width: 50%;
        margin-top: 30px;
      }

      .mobile-style-text {
        display: block;
        width: 75%;
        margin-right: 10px;
        margin-left: 10px;
        padding-bottom: 10px;
        text-align: center;
      }

      .div-block-192 {
        width: 100%;
        .button.left-align {
          display: block;
        }
        .hidden-on-mobile {
          display: none;
        }
        .button {
          width: 100%;
        }
        .is-sticky {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 999;
          animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
          padding-top: 0px;
          padding-bottom: 0px;
          .sticky-item {
            margin: 15px 10px;
          }
        }
      }

      .text-experience {
        flex-direction: column-reverse;
      }
    }
    .container-33 {
      .div-block-191 {
        min-height: 300px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 0px;
        text-align: center;
      }
      .image-107 {
        display: block;
        margin-bottom: 20px;
      }
      .link-9 {
        margin-top: 10px;
      }
    }
  }

  @media screen and (max-width: 479px) {
    .container-28 {
      .similar-flowers-wrapper {
        width: 80%;
        height: 100%;
        margin: 0 auto;
      }

      .div-block-192 {
        .button.hidden-on-mobile {
          display: none;
        }
      }
    }
  }
}

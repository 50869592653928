.end-quiz-wrapper {
  padding: 0 16px;
  margin-top: 180px;
  .title {
    font-family: 'LTC Caslon';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #231f20;
    margin: 0;
  }
  .description {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 390;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #231f20;
  }
  .action-box-wrapper {
    justify-content: center;
  }
  a {
    text-decoration: underline;
    color: #231f20;
  }
}

.end-quiz {
  .quiz-question-content {
    height: calc(100vh - 111px);
    .body-section {
      max-width: 500px;
    }
    .left-flower {
      background: url('../../../../assets/images/Lupine_Curved_Grayscale_1.png');
      width: 330px;
      height: 702px;
      left: 0;
      &::after {
        display: none;
      }
    }
    .right-flower {
      background: url('../../../../assets/images/Lupine_Curved_Grayscale_2.png');
      width: 255px;
      height: 517px;
      position: absolute;
      right: -30px;
      bottom: 0;
    }
    @media only screen and (max-width: 1024px) {
      .end-quiz-wrapper {
        margin-top: 60px;
      }
      .left-flower,
      .right-flower {
        display: none;
      }
    }
  }
}

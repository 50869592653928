// webfonts
@font-face {
  font-family: "Nunito Black";
  src: local("Nunito Black"),
    url("../webfonts/NunitoSans-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Bold";
  src: local("Nunito Bold"),
    url("../webfonts/NunitoSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito";
  font-weight: bold;
  src: local("Nunito ExtraBold"),
    url("../webfonts/NunitoSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito SemiBold";
  src: local("Nunito SemiBold"),
    url("../webfonts/NunitoSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito";
  src: local("PlayfairDisplay"),
    url("../webfonts/NunitoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Playfair Display";
  font-weight: 900;
  src: local("PlayfairDisplay"),
    url("../webfonts/PlayfairDisplay-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  src: local("OpenSans"),
    url("../webfonts/OpenSans-Regular.ttf") format("truetype");
}

// added
@font-face {
  font-family: "LTC Caslon";
  src: local("LTC Caslon"),
    url("../webfonts/LTCCaslon.ttf") format("truetype");
}

@font-face {
  font-family: 'LTC Caslon Italic';
  src: url("../webfonts/LTCCaslonProItalic.otf") format("opentype");
}

@font-face {
  font-family: "Brandon Grotesque";
  src: local("Brandon Grotesque"),
    url("../webfonts/Brandon-Regular.otf") format("opentype");
}

@font-face {
  font-family: "BN Bergen St";
  src: local("BN Bergen St"),
    url("../webfonts/BNBergenSt.otf") format("opentype");
}

@font-face {
  font-family: "Libre Caslon Text";
  src: local("Libre Caslon Text"),
    url("../webfonts/LibreCaslonText-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Libre Caslon Text";
  src: local("Libre Caslon Text"),
    url("../webfonts/LibreCaslonText-Italic.ttf") format("truetype");
}

@for $j from 0 to 101 {
  // margin
  .mb-#{$j}px {
    margin-bottom: 1px * $j !important;
  }

  .mb-#{$j}rem {
    margin-bottom: 0.1rem * $j !important;
  }

  .ml-#{$j}px {
    margin-left: 1px * $j !important;
  }

  .mt-#{$j}px {
    margin-top: 1px * $j !important;
  }

  .mt-#{$j}rem {
    margin-top: 0.1rem * $j !important;
  }

  .mr-#{$j}px {
    margin-right: 1px * $j !important;
  }

  .mlr-#{$j}px {
    margin-left: 1px * $j !important;
    margin-right: 1px * $j !important;
  }

  .mlr-#{$j}rem {
    margin-left: 0.1rem * $j !important;
    margin-right: 0.1rem * $j !important;
  }

  .mtb-#{$j}px {
    margin-top: 1px * $j !important;
    margin-bottom: 1px * $j !important;
  }

  .mt-#{$j}px-auto {
    margin: 1px * $j auto;
  }

  // padding
  .pb-#{$j}px {
    padding-bottom: 1px * $j !important;
  }

  .pl-#{$j}px {
    padding-left: 1px * $j !important;
  }

  .pt-#{$j}px {
    padding-top: 1px * $j !important;
  }

  .pr-#{$j}px {
    padding-right: 1px * $j !important;
  }

  .plr-#{$j}px {
    padding-left: 1px * $j !important;
    padding-right: 1px * $j !important;
  }

  .plr-#{$j}rem {
    padding-left: 0.1rem * $j !important;
    padding-right: 0.1rem * $j !important;
  }

  .ptb-#{$j}px {
    padding-top: 1px * $j !important;
    padding-bottom: 1px * $j !important;
  }

  // width
  .w-#{$j} {
    width: 1% * $j !important;
  }
  .w-#{$j}px {
    width: 1px * $j !important;
  }

  // height
  .h-#{$j} {
    height: 1% * $j !important;
  }
  .h-#{$j}px {
    height: 1px * $j !important;
  }
}

// font-size
@for $j from 10 to 40 {
  .fz-#{$j}px {
    font-size: 1px * $j !important;
  }
}

.container {
  max-width: 1152px;
  width: 100%;
}

.position-relative {
  position: relative;
}

.text-dark {
  color: #231F20;
}

.text-highlight {
  color: #eb4b32;
}

.btn-new {
  border-radius: 0;
  font-family: 'BN Bergen St';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: $poppy-bg;
}

.find-your-style-box {
  width: 344px;
  height: 390px;
  border: 1px solid #5E5929;
  box-sizing: border-box;
}

.box-title {
  font-family: 'LTC Caslon';
  font-style: normal;
  font-weight: normal;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  color: #231F20;
  margin: 0;
}
.box-text {
  font-family: 'Brandon Grotesque';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #231F20;
  margin: 0;
}

.wedding-datail-box {
  background: $poppy-bg;
}

.bg-img-budget {
  background: #fdf3ed;
}

@media only screen and (max-width: 768px) {
  .find-your-style-box {
    max-width: 332px;
    width: 100%;
    height: 180px;
    border: 1px solid #5E5929;
    box-sizing: border-box;
  }
  
  .box-title {
    font-size: 32px;
    line-height: 36px;
    text-align: center;
  }
  .box-text {
    font-size: 16px;
    line-height: 28px;
  }
}

.inline-link {
  font-family: 'Brandon Grotesque', sans-serif;
  font-size: 16px;
  line-height: 18px;
  font-weight: 390;
  color: #5e5929;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}

.hidden-mobile {
  display: block;
}
.hidden-desktop {
  display: none;
}

@include responsive-down('md') {
  .hidden-mobile {
    display: none;
  }
  .hidden-desktop {
    display: block;
  }
}
.choose-palette-page {
  max-width: 1072px;
  padding: 0 24px 100px;
  margin: auto;
  .step-header {
    padding: 16px 10% 0;
    position: sticky;
    top: 0;
    z-index: 1;
    background: rgba(255, 254, 250, 0.98);
  }
  .choose-palette-content {
    display: flex;
    gap: 30px;
    margin-top: 180px;
    justify-content: space-between;
    .choose-header {
      margin-top: 16px;
    }
    .palette-content {
      flex: 1;
      max-width: 396px;
    }
    .rotate-right {
      transform: rotate(180deg);
    }
    .palette {
      .palette-title {
        color: #000;
        font-family: LTC Caslon;
        font-size: 20px;
        font-style: italic;
        font-weight: 405;
        line-height: 24px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
      .palette-description {
        color: #000;
        font-family: Brandon Grotesque;
        font-size: 16px;
        font-style: normal;
        font-weight: 390;
        line-height: 24px;
        margin-top: 10px;
      }
      .action-slider {
        display: flex;
        gap: 10px;
        .action-item {
          cursor: pointer;
        }
      }
    }
  }
  .palette-image-group {
    position: relative;
    width: 500px;
    min-height: 390px;
    .palette-image-container {
      transform: scale(1.57);
    }
  }
  @media only screen and (max-width: 1280px) {
    padding-bottom: 60px;
    .choose-palette-content {
      margin-top: 94px;
    }
    .palette-image-group {
      .palette-image-container {
        transform: scale(1.4);
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    padding-bottom: 40px;
    .choose-palette-content {
      margin-top: 64px;
    }
    .palette-image-group {
      width: 450px;
      .palette-image-container {
        transform: scale(1.2);
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0 20px;
    padding-bottom: 20px;
    .step-header {
      padding: 0;
      .quiz-step {
        padding-top: 8px;
      }
    }
    .choose-palette-content {
      flex-direction: column;
      gap: 12px;
      margin-top: 14px;
      max-width: unset;
      .choose-header {
        margin-top: 0;
      }

      .palette {
        &.mobile {
          display: flex;
          justify-content: center;
          flex-direction: column;
          .palette-group {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            text-align: center;
          }
          .action-btn {
            cursor: pointer;
          }
        }
      }
    }

    .palette-image-group {
      position: relative;
      margin: auto;
      width: auto;
      min-height: 273px;
      .palette-image-container {
        transform: scale(1);
      }
      .palette-image-item {
        position: absolute;
      }
    }
  }

  @media only screen and (max-width: 450px) {
    .choose-palette-content {
      gap: 0;
    }
    .palette-image-group {
      .palette-image-container {
        transform: scale(0.9);
      }
    }
  }

  @media only screen and (max-width: 390px) {
    .choose-palette-content {
      gap: 0;
    }
    .palette-image-group {
      width: 300px;
      margin-left: -20px;
      .palette-image-container {
        transform: scale(0.75);
      }
    }
  }
}

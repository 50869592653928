.schedule-a-call-page {
  .main-content {
    background: url('/assets/images/moodboard/schedule-a-call-bg.png');
    background-repeat: no-repeat;
    background-position: 80%;
    background-size: cover;
  }
  .schedule-a-call-wrapper {
    padding: 5% 7%;
    max-width: 680px;
  }

  .header-title {
    color: #000;
    font-family: LTC Caslon;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 1.28px;
  }
  .section-title {
    color: #000;
    font-family: LTC Caslon;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.96px;
  }
  .normal-text {
    color: #000;
    font-family: Brandon Grotesque;
    font-size: 20px;
    font-style: normal;
    font-weight: 390;
    line-height: 30px;
  }

  @media only screen and (max-width: 768px) {
    .main-content {
      background: none;
    }

    .schedule-a-call-wrapper {
      max-width: unset;
    }
    .header-title {
      font-size: 24px;
    }
    .section-title {
      font-size: 20px;
    }
  }
}

.gallery {
  .w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
  }

  .area-6 {
    grid-area: Area-6;
  }

  .area {
    grid-area: Area;
  }

  .area-7 {
    grid-area: Area-7;
  }

  .area-2 {
    grid-area: Area-2;
  }

  .area-3 {
    grid-area: Area-3;
  }

  .area-4 {
    grid-area: Area-4;
  }

  .area-5 {
    grid-area: Area-5;
  }
}

.gallery-desktop {
  box-sizing: border-box;
  display: flex;
  width: 50%;
  height: 100%;
  padding: 10px 10px 19px;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .w-layout-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
  }

  .grid-20 {
    width: 554px;
    height: 554px;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-areas:
      'Area Area Area-6 Area-2'
      'Area Area Area-7 Area-2'
      'Area-3 Area-4 Area-5 Area-5'
      'Area-3 Area-4 Area-5 Area-5';
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }

  .div-block-158 {
    max-height: 100%;
    min-height: 0px;
  }

  .image-84 {
    height: 100%;
  }

  .div-block-159 {
    min-height: 0px;
  }

  .avatar-gif {
    width: 554px;
    height: 554px;
  }
}

@media screen and (max-width: 767px) {
  .gallery-desktop {
    width: 50%;
  }

  .gallery-mobile {
    max-width: 335px;
    margin-bottom: 30px;
    padding: 14px;

    .w-layout-grid {
      display: -ms-grid;
      display: grid;
      grid-auto-columns: 1fr;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
      grid-row-gap: 16px;
      grid-column-gap: 16px;
    }

    .grid-21 {
      height: 218px;
      margin-bottom: 16px;
      grid-template-areas:
        'Area Area-2'
        'Area Area-2';
    }

    .grid-21-copy {
      height: 218px;
      margin-bottom: 0px;
      grid-template-areas:
        '. Area-2'
        '. Area-2';
    }

    .start-end-span-1 {
      grid-column-start: span 1;
      grid-column-end: span 1;
      grid-row-start: span 1;
      grid-row-end: span 1;
    }

    .image-88 {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 479px) {
  .gallery-desktop {
    width: 80%;
  }
}

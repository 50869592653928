.inspiration-page {
  max-width: 1169px;
  margin: auto;
  padding: 0 20px 20px;
  .step-header {
    padding: 16px 10% 0;
    position: sticky;
    top: 0;
    z-index: 1;
    background: rgba(255, 254, 250, 0.98);
  }
  .inspiration-content-wrapper {
    display: flex;
    margin-top: 56px;
    justify-content: space-between;
    gap: 30px;
    .inspiration-content {
      max-width: 35%;
      margin-top: 126px;
      .inspiration-header {
        margin-top: 16px;
      }
    }
    .inspiration-photo-flex {
      display: flex;
      gap: 24px;
      .left,
      .right {
        width: 330px;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
      .left {
        width: 327px;
      }
      .right {
        width: 330px;
      }
      .dropzone-inspiration {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 49px 58px 64px;
      }
      .item {
        position: relative;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .image-wrapper {
          max-height: 350px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            display: block;
            transition: transform 0.3s;
            object-fit: cover;
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        }
        .image-footer {
          padding: 16px 0;
          display: flex;
          align-items: center;
          gap: 10px;
          flex: 0 0 auto;
          .icon-action {
            display: flex;
            align-items: center;
          }
          .caption {
            color: #000;
            font-family: Brandon Grotesque;
            font-size: 16px;
            font-style: normal;
            font-weight: 390;
            line-height: 24px;
          }
        }
      }
      .upload-inspiration {
        text-align: center;
        .title {
          color: #000;
          font-family: LTC Caslon;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 36px;
          letter-spacing: 0.6px;
        }
        .description {
          color: #000;
          text-align: center;
          font-family: Brandon Grotesque;
          font-size: 16px;
          font-style: normal;
          font-weight: 390;
          line-height: 24px;
          margin-top: 12px;
        }
        .upload-btn {
          color: #5e5929;
          font-family: Brandon Grotesque;
          font-size: 16px;
          font-style: normal;
          font-weight: 390;
          line-height: 24px;
          text-decoration-line: underline;
          cursor: pointer;
          margin-top: 17px;
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    max-width: 1128px;
    .inspiration-content-wrapper {
      margin-top: 52px;
      .inspiration-content {
        margin-top: 43px;
      }
      .inspiration-photo-flex {
        .left {
          width: 289px;
        }
        .right {
          width: 292px;
        }
        .dropzone-inspiration {
          padding: 56px 40px 64px;
        }
        .upload-inspiration {
          .description {
            margin-top: 8px;
          }
          .upload-btn {
            margin-top: 14px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .inspiration-content-wrapper {
      .inspiration-photo-flex {
        .left {
          width: 245px;
        }
        .right {
          width: 251px;
        }
        .dropzone-inspiration {
          padding: 56px 20px 64px;
        }
        .upload-inspiration {
          .description {
            margin-top: 8px;
          }
          .upload-btn {
            margin-top: 14px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0 20px;
    padding-bottom: 20px;
    .step-header {
      padding: 0;
      .quiz-step {
        padding-top: 8px;
      }
    }
    .inspiration-content-wrapper {
      flex-direction: column;
      margin-top: 14px;
      .inspiration-content {
        max-width: unset;
        margin-top: 0;
        .inspiration-header {
          margin-top: 0;
        }
      }
      .inspiration-photo-flex {
        width: 100%;
        flex-wrap: wrap;
        .left,
        .right {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 24px;
        }
        .dropzone-inspiration {
          padding: 20px 20px 30px;
        }
      }
    }
  }
}

.ReactModal__Overlay:has(.modal-upload-wrapper) {
  overflow: hidden;
}

.modal-base {
  &.modal-upload-wrapper {
    background-color: transparent;
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: unset;
    .modal-upload-container {
      background-color: #fff;
      position: relative;
      max-height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      min-width: 394px;
      .modal-close {
        background: transparent;
      }
      .upload-image-content {
        overflow: auto;
        .upload-image {
          .image-wrapper {
            display: flex;
            justify-content: center;
            padding: 22px;
            background: #f9f9f9;
          }
          .image-item {
            width: 168px;
            height: 252px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .form-group {
            padding: 22px;
          }
          &:last-child {
            .form-group {
              padding: 22px;
              padding-bottom: 0;
            }
          }
        }
      }
      .modal-footer {
        padding: 22px;
        .actions {
          display: flex;
        }
      }
    }
  }
}

@import 'src/assets/scss/mixin.scss';

.your-details-wrapper {
  width: 100%;
  .your-details-section {
    width: 360px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-title {
      font-family: 'LTC Caslon';
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      margin-bottom: 32px;
    }
    @include responsive-down('md') {
      .form-title {
        font-size: 20px;
        line-height: 30px;
      }
    }
    .customer-name {
      width: 100%;
      .input-wrapper {
        width: unset;
        margin: 0;
      }
    }
    .partner-name {
      width: 100%;
      .input-wrapper {
        width: unset;
        margin: 0;
      }
    }
    .action-box-wrapper {
      justify-content: center;
    }
  }
}
.quiz-question-wrapper .quiz-question-content .body-section:has(> .your-details-wrapper) {
  width: 100%;
}

.canny-valley-layout {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  * {
    box-sizing: border-box;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block: 0;
  }
  .main-content {
    flex: 1;
    overflow: auto;
    background: rgba(255, 254, 250, 0.98);
    .container {
      max-width: 1440px;
      margin: auto;
    }
  }
  &.full-width {
    .main-content {
      .container {
        max-width: 100%;
        margin: auto;
      }
    }
  }

  .width-fit-content {
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
  }
  .error-text {
    color: #d7462d;
    text-align: left;
    font-size: 12px;
  }
  .step-title-header {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .step-title {
    color: #000;
    font-family: LTC Caslon;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.72px;
    margin-block: 0;
  }
  .step-description {
    color: #000;
    font-family: Brandon Grotesque;
    font-size: 20px;
    font-style: normal;
    font-weight: 390;
    line-height: 30px;
    margin-block: 0;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .top-actions {
    .back-action {
      display: flex;
      gap: 10px;
      color: #000;
      font-family: LTC Caslon;
      font-size: 12px;
      font-style: italic;
      font-weight: 405;
      line-height: 36px;
      letter-spacing: 1.2px;
      cursor: pointer;
      width: fit-content;
    }
  }
  .desktop {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .mobile {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
    }
  }
}

.button-stem {
  text-align: center;
  opacity: 1;
  padding: 15px 24px;
  background-color: rgba(94, 89, 41, 1);
  border: 1px solid rgba(94, 89, 41, 1);
  color: #fffefa;
  font-family: BN Bergen St;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  cursor: pointer;
  &:active {
    background: rgba(94, 89, 41, 1);
    border: 1px solid rgba(94, 89, 41, 1);
  }
  &:focus {
    border: 1px solid rgba(94, 89, 41, 0.51);
  }
  &:disabled {
    color: #f2f1ea;
    background: rgba(94, 89, 41, 0.51);
    border: 1px solid rgba(94, 89, 41, 0.51);
  }
  &.loading {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto 8px auto auto;
      border: 4px solid transparent;
      border-top-color: #ffffff;
      border-radius: 50%;
      animation: button-loading-spinner 1s ease infinite;
    }
  }

  &.secondary {
    border: 1px solid #5e5929;
    background: #fffefa;
    color: #5e5929;
  }
}

.form-group {
  position: relative;
  .input-label {
    color: #000;
    font-family: Brandon Grotesque;
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: 24px;
  }
  .form-item {
    position: relative;
    .icon {
      position: absolute;
      right: 10px;
      top: 50%;
    }

    .input {
      font-family: Brandon Grotesque;
      font-size: 14px;
      font-style: normal;
      font-weight: 390;
      line-height: normal;
      border-radius: 4px;
      &::placeholder {
        color: #555152;
        text-align: left;
      }
    }

    .floating-label-wrapper {
      padding-top: 15px;
    }

    &:has(.border-red) {
      .input-label {
        color: #d7462d;
      }
      .input {
        &::placeholder {
          color: #d7462d;
        }
      }
    }
  }
  .error-text {
    position: absolute;
    padding-left: 10px;
  }
}

.wedding-detail-section {
  .wedding-detail {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      .left,.right {
        max-width: 50%;
      }
    }
  }
}

@import './mixin.scss';

$poppy-red: #d7462d;
$fontColor: #320117;
$gray: rgba(0, 0, 0, 0.35);
$primary: #c85138;
$placeholder: #000;

$aqua: #231f20;
$secondary: #f2f1ea;
$poppy-bg: #f2f1ea;

$aquaBoxShadow: rgba(17, 177, 206, 0.425);
$poppyRedBoxShadow: rgba(206, 17, 17, 0.425);

/* Poppy Colors */
$night: #231f20;
$stem: #5e5929;
$linen: #f2f1ea;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'LTC Caslon';
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Brandon Grotesque';
  background-color: $secondary;
  overflow-x: hidden;
  overflow-y: hidden;
}

button {
  font-family: 'BN Bergen St';
  font-size: 1rem;
}

input,
textarea {
  font-family: 'Brandon Grotesque';
  font-size: 16px;
  line-height: 24px;
}

.eyebrow-text {
  font-family: 'LTC Caslon';
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: $night;
}

.font-color {
  color: $fontColor;
}

.color-poppy-red {
  color: $poppy-red;
}

.color-aqua {
  color: $aqua;
}

.font-bold {
  font-weight: 700;
}

.font-playfair {
  font-family: 'Playfair Display';
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline-block;
}

.border-red {
  border-color: red !important;
}

.error {
  color: $poppy-red;
  text-align: left;
  padding: 7px 0 0 25px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 450;
}

.button-empty {
  background: inherit;
  border: 0;
  padding: 0;
  width: fit-content;
}

.button {
  border-radius: 0px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 8px 21px;
  cursor: pointer;
  outline: 0;

  opacity: 0.75;
  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    opacity: 1;
  }

  &.button-basic,
  &.button-alert.swal2-confirm {
    color: #ffffff;
    background: $poppy-red;
    border: 1px solid $poppy-red;
    font-weight: 400;
  }
  &.button-primary {
    border: 1.5px solid $poppy-red;
    box-sizing: border-box;
    background: transparent;
    color: $poppy-red;
    font-weight: 700;
  }

  &.transparent {
    border: 1.5px solid rgba(50, 1, 23, 0.35);
    box-sizing: border-box;
    border-radius: 0px;
    color: rgba(50, 1, 23, 0.35);
    background: transparent;
    font-weight: 700;
  }

  &:disabled {
    border: 1.5px solid rgba(94, 89, 41, 0.51);
    box-sizing: border-box;
    color: rgba(50, 1, 23, 0.35);
    background: #fff;
    font-weight: 700;
  }

  &.button-icon {
    background: inherit;
    border: 0;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    font-size: 0.875rem;
    font-weight: 700;
    height: 3.5rem;
    padding: 0 1rem;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    transition-duration: 50ms;
    transition-timing-function: ease-in;
    white-space: nowrap;
    line-height: 16px;
    font-weight: bold;

    &:disabled {
      opacity: 0.4;
    }
  }

  &.button-day_picker {
    border: 1px solid #9b9b9b;
    border-radius: 26px;
    background: #fbfaf8;
    color: #000000;
    font-weight: normal;
    height: 42px;
  }
}

.radio {
  margin: 10px;
  color: $fontColor;

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        background: #fff;
        @include bordered(1.5px, $gray);
        @include square(1.4em, $radius: 100%);
        display: none;
        position: relative;
        top: -0.2em;
        margin-right: 10px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }

      &:focus {
        outline: 0;
      }
      &:focus-visible {
        &:before {
          @include box-shadow(0, 0, 0, 4px, $aquaBoxShadow);
        }
      }

      display: inline-block;
      border: 1px solid #b4b4b4;
      border-radius: 4px;
      text-align: center;
      box-sizing: border-box;
      padding: 18px 15px;
      text-align: center;
      cursor: pointer;
      background: $poppy-bg;
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 390;
      font-size: 16px;
      line-height: 23px;
      color: #231f20;
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $aqua;
          border-color: $aqua;
          box-shadow: inset 0 0 0 4px #fff;
        }
        &.basic::before {
          background: $primary;
          border-color: $primary;
          box-shadow: none;
        }
        background: #ff9977;
        border: 1px solid #ff9977;
        color: #231f20;
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px #fff;
          background: #fff;
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  color: $fontColor;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    @include square(0);
    &:checked {
      ~ {
        .checkmark {
          background-color: $aqua;
          border-color: #289a9a;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  .checkmark {
    @include square(25px, $radius: 5px);
    @include position(0, 0);
    border: 1px solid $gray;
    &:after {
      content: '';
      display: none;
      width: 7px;
      height: 20px;
      border: solid white;
      border-width: 0 3px 3px 0;
      @include position(-1px, 8px);
      @include transform(rotate(45deg));
    }
  }
}

.checkbox-custom {
  display: inline-block;
  color: $aqua;
  @include bordered(2px, $aqua);
  border-radius: 10px;
  text-align: center;
  padding: 5px 4px;
  text-align: center;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  &.active {
    background: $aqua;
    color: #ffffff;
  }
}

.checkbox-button {
  background: inherit;
  border: 0px;
  outline: 0;

  .label {
    display: inline-block;
    font-family: 'BN Bergen St';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    color: #000000;
    border: 1px solid $aqua;
    border-radius: 60px;
    text-align: center;
    box-sizing: border-box;
    padding: 18px 15px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    cursor: pointer;

    &:focus {
      outline-width: 0px;
    }

    &:focus-within {
      @include box-shadow(0, 0, 0, 4px, $aquaBoxShadow);
    }
  }

  input {
    display: none;

    &:checked + .label {
      background: $aqua;
      color: #ffffff;
    }
  }

  &.cb-btn-basic {
    .label {
      background: $poppy-bg;
      color: $aqua;
    }
    input:checked + .label {
      background: #ff9977;
      border: 1px solid #ff9977;
      color: #231f20;
    }
  }

  &.cb-btn-poppy {
    .label {
      background: #ffffff;
      border: 1.5px solid $poppy-red;
      color: $poppy-red;
    }
    input:checked + .label {
      background: $poppy-red;
      color: #ffffff;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $poppy-bg inset !important;
}

.floating-label-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 19px) scale(1);
    transform-origin: top left;
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    font-family: 'LTC Caslon';
    font-style: normal;
    font-weight: normal;
    color: #231f20;
    font-size: 16px;
    line-height: 1;
    left: 16px;
  }
  &:focus-within label {
    transform: translate(0, -16px) scale(1);
    font-family: 'BN Bergen St';
    left: 2px;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    letter-spacing: 0.2em;
    font-weight: normal;
    color: #231f20;
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  .filled {
    transform: translate(0, -16px) scale(1);
    font-family: 'BN Bergen St';
    left: 2px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0.2em;
    font-weight: normal;
  }
}

.input {
  border: 1px solid #bdbcbc;
  background-color: transparent !important;
  box-sizing: border-box;
  border-radius: 4px;
  outline: 0;
  padding: 10px 20px 10px;
  height: 53px;
  color: $night;
}

.input-icons {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;

  .icon {
    position: absolute;
    padding: 6px;
    width: 28px;
    height: 28px;
    text-align: center;
    margin-left: 15px;
  }
  input {
    height: 53px;
    padding-left: 12px 20px 8px;
    border-radius: 0px;
    outline: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .basic {
    background: transparent;
    letter-spacing: 0.25px;
    border: 1px solid #b5b3ad;
    border-radius: 4px;
    color: #000000;
  }

  .basic::placeholder {
    text-align: left !important;
  }
}

.input-quantity {
  display: inline-flex;
  justify-content: space-between;
  background: transparent;
  border: 1px solid #231f20;
  border-radius: 0px;
  padding: 16.5px 10px;

  .quantity-value {
    background-color: inherit;
    border: 0;
    text-align: center;
    width: 40px;
    outline: 0;
    @include remove-arrows;
  }

  .quantity-action {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #bcbfc2;
    font-size: 20px;
    font-weight: bold;
    background: initial;
    border: 0;
    outline: 0;
  }
}

.gird-col-2 {
  display: grid;
  grid-template-columns: auto auto;
}

.image-option-basic {
  display: grid;
  grid-template-columns: 50% 50%;
}

.image-option-col-3 {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.image-option-card {
  cursor: pointer;
  margin: 10px 10px;
  border-radius: 4px;

  &:focus {
    outline: 0;
  }
  &:focus-visible {
    @include box-shadow(0, 0, 0, 4px, $aquaBoxShadow);
  }
  .under-layer {
    width: 216.4px;
    height: 243.72px;
    background: $poppy-bg;
    border: 1px solid #000000;
    box-sizing: border-box;
    position: relative;
    .up-layer {
      width: 216.4px;
      height: 248.92px;
      background: $poppy-bg;
      border: 1px solid #000000;
      box-sizing: border-box;
      position: absolute;
      top: 10px;
      left: 10px;
      .image-option-image {
        width: 187px;
        height: 145px;
        margin: 10px auto;
        .helper {
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }
      }
      .image-option-label {
        font-family: 'LTC Caslon';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        position: absolute;
        left: 7.12%;
        bottom: 6.45%;
      }
    }
  }
  &.active {
    .under-layer {
      background: #ff9977;
    }
  }

  img {
    width: 100%;
  }
}

.table-option-card {
  .under-layer {
    width: 251.67px;
    height: 203.42px;
    background: $poppy-bg;
    border: 1px solid #000000;
    box-sizing: border-box;
    position: relative;
    .up-layer {
      width: 251.67px;
      height: 203.42px;
      background: $poppy-bg;
      border: 1px solid #000000;
      box-sizing: border-box;
      position: absolute;
      top: 10px;
      left: 10px;
      .option-label {
        font-family: 'LTC Caslon';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 36px;
        color: #000000;
        position: absolute;
        left: 12.12%;
        bottom: 4%;
      }
    }
  }
  &.active {
    .under-layer {
      background: #ff9977;
    }
  }
}

// Modal
.modal-base {
  width: 40rem;
  max-width: 96%;
  box-sizing: border-box;
  border-radius: 11px;
  background-color: rgb(251, 250, 248);
  color: $fontColor;
  background-image: none;
  text-align: center;
  margin: 7rem auto;
  border: 0px solid;
  outline: 0;
  padding: 0;
  position: relative;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  background-color: $stem;

  .modal-close {
    z-index: 1;
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 12px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: rgba(242, 241, 234, 1);

    svg {
      fill: $stem;
      stroke: $stem;
    }
  }
}

textarea {
  border-radius: 15px;
  padding: 15px 20px;
  outline: 0;
  font-family: 'Brandon Grotesque';
  font-weight: 400;
  letter-spacing: 0.892857px;
  resize: none;

  &::placeholder {
    color: #000000;
    opacity: 1;
  }
}
input::placeholder {
  color: #000000;
  opacity: 1;
}
// slider
.range-slider-option {
  padding: 0 30px;
  min-height: 85px;
  .slider-image {
    margin-bottom: 40px;
    background-color: $linen;
    img {
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
  .slider-label {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    color: $fontColor;
    margin-top: 30px;
  }
  .range {
    margin: 0 auto;
    height: 80px;
    &.no-selected .rc-slider-handle {
      display: none;
    }
    .rc-slider-rail {
      background-color: #f4c08f;
      height: 7px;
    }
    .rc-slider-track {
      background-color: #c85138;
      height: 7px;
    }
    .rc-slider-handle {
      border-color: #c85138;
      background-color: #c85138;
      margin-top: -18px;
      margin-left: 10px;
      width: 40px;
      height: 40px;

      &:focus-visible {
        @include box-shadow(0, 0, 0, 4px, $poppyRedBoxShadow);
      }
    }
    .rc-slider-dot,
    &.no-selected .rc-slider-dot-active {
      border-color: #f4c08f;
      background-color: #fdf3ed;
      border-width: 7px;
      margin-left: -8px;
      bottom: -13px;
      width: 30px;
      height: 30px;
    }
    .rc-slider-dot-active {
      border-color: #c85138;
      background-color: #c85138;
    }
    .rc-slider-mark {
      padding-top: 15px;
      font-size: 22px;
    }
    .mark {
      color: #a49e9a;
    }
    .mark-active {
      white-space: nowrap;
      color: #320117;
      font-weight: bold;
      padding-top: 26px;
      width: 100%;
      display: block;
    }
  }
}

// select
.select {
  height: 53px;
  .react-select__control {
    padding: 10px 10px 10px;
    border-radius: 4px;
    border: 1px solid #bdbcbc;
    height: 100%;
    font: 400 16px 'Brandon Grotesque';
    background-color: transparent;
    outline: 0;
    box-shadow: none;
    .react-select__placeholder {
      color: $placeholder;
      opacity: 1;
    }
    .react-select__dropdown-indicator {
      padding: 0;
    }
  }
  .react-select__control:hover,
  .react-select__control:active {
    border-color: #bdbcbc !important;
    box-shadow: none;
  }
  .react-select__menu-list {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: #c4c4c4 white;
    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      border-radius: 25px;
      padding: 2px 0;
    }
    &::-webkit-scrollbar-track {
      border-radius: 25px;
      background-color: #CFCFCF;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 25px;
      background: #FF9977;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #FF9977;
    }
  }
}

.react-select--is-disabled {
  .react-select__control {
    .react-select__indicators {
      display: none;
    }
  }
}

// loader
/* [FULL SCREEN SPINNER] */
.loader_full_screen {
  position: fixed;
  display: flex;
  width: 100vw;
  transition: all 1s;
  z-index: 999;
  height: 105vh;
  background: $secondary;
  text-align: center;
  justify-content: center;
  justify-items: center;
  align-items: center;

  .icon-logo {
    width: 200px;
    height: 200px;
    @include animation(rotating 2s linear infinite);
  }
  .loader {
    border: 8px solid $linen;
    border-top: 8px solid #eb4b32;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: rotating 2s linear infinite;
  }
}

// picker
.day-picker-wrapper {
  .DayPickerInput {
    width: 100%;
    font-family: 'LTC Caslon';
    color: #000000;
  }
  .DayPicker-Day.DayPicker-Day--selected,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $poppy-red !important;
  }
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: $poppy-red !important;
    color: #fff;
  }

  .input-field {
    padding-left: 20px;
  }

  .input-field::placeholder {
    text-align: center;
    padding-right: 55px;
  }
}

.icon-next {
  border-radius: 50%;
  background-color: $aqua;
}

.tooltip {
  position: relative;

  .tooltip-text {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 2;
  }

  &:hover .tooltip-text {
    visibility: visible;
  }
}

.white-space-wrap {
  white-space: normal !important;
}

// Mobile
@media only screen and (max-width: 768px) {
  .modal-base {
    .modal-close {
      right: 16px;
    }
  }

  .range-slider-option {
    .range {
      .rc-slider-mark {
        font-size: 15px;
      }
      .mark-active {
        padding-top: 20px;
      }
    }
  }

  .m-center {
    text-align: center;
  }
}

button:focus-visible {
  border-radius: 10px;
  @include box-shadow(0, 0, 0, 4px, $aquaBoxShadow);
}

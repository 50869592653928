$bgColorSection: #F2F1EA;

.as-seen-in {
  display: flex;
  padding-top: 60px;
  padding-bottom: 10px;
  flex-direction: column;
  align-items: center;
  background-color: $bgColorSection;

  .div-block-164 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .red-horizontal-rule {
      height: 1px;
      flex: 1;
      background-color: #d7462d;
    }

    .heading-82 {
      margin-right: 16px;
      margin-left: 16px;
    }
  }
  .div-block-165 {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 80%;

    .image-93 {
      margin-right: 10px;
      margin-left: 10px;
      -o-object-fit: contain;
      object-fit: contain;
      align-self: center;
    }
  }
}

// table
@media screen and (max-width: 991px) {
  .as-seen-in {
    .div-block-165 {
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;

      .image-93 {
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .as-seen-in {
    background-color: inherit;
    .image-93 {
      padding-bottom: 20px;
    }
  }
}

.calendly-wrapper {
  position: relative;
  height: 700px;
  .loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
}

.counter {
  display: flex;
  border-radius: 9999px;
  border: 1px solid #cfcfcf;
  padding: 13px;
  justify-content: space-between;

  color: #000;
  text-align: center;
  font-family: Brandon Grotesque;
  font-size: 20px;
  font-style: normal;
  font-weight: 390;
  line-height: 24px;
  .action {
    cursor: pointer;
    min-width: 30px;
  }
  .counter-input {
    flex: 1;
    input {
      width: 100%;
      padding: 0;
      background: transparent;
      outline: none;
      border: none;
      text-align: center;
      color: #000;
      text-align: center;
      font-family: Brandon Grotesque;
      font-size: 20px;
      font-style: normal;
      font-weight: 390;
      line-height: 24px;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
    }
  }
}

.navigation {
  position: relative;
  background: #dddddd;
  display: flex;
  padding: 30px 50px 40px;
  align-items: center;
  // background-color: transparent;
  background-color: #fbfaf8;
  z-index: 10;

  * {
    box-sizing: border-box;
  }

  img {
    display: block;
    object-fit: cover;
  }

  &:after {
    clear: both;
  }

  &:before,
  &:after {
    content: ' ';
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
  }

  .navigation-wrap {
    position: relative;
    display: flex;
    margin-top: 9px;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }
  .logo-link {
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    flex: 1;
    transition: opacity 200ms ease;
    font-family: 'Nunito', sans-serif;
  }

  .w-nav-brand {
    float: left;
    text-decoration: none;
    color: #333333;
  }

  .menu {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .navigation-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    order: 0;
    flex: 0 auto;
  }

  .navigation-item {
    padding-top: 9px;
    padding-bottom: 9px;
    opacity: 1;
    font-family: Nunito, sans-serif;
    color: #320117;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: none;

    &:hover {
      opacity: 0.9;
      color: #32343a;
    }
  }

  .w-nav-menu {
    position: relative;
    float: right;
  }

  .w-nav-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    color: #222222;
    padding: 20px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }

  .menu-button {
    display: none;
  }

  .w-nav-button {
    position: relative;
    float: right;
    padding: 18px;
    font-size: 24px;
    display: none;
    cursor: pointer;
    user-select: none;
  }

  .logo-image {
    display: block;
    max-width: 190px;
    order: -1;
    flex: 0 auto;
  }

  .w-nav-overlay {
    position: absolute;
    overflow: hidden;
    display: none;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    z-index: -1;
  }

  @media screen and (max-width: 991px) {
    padding: 25px 30px;

    .menu {
      position: static;
      margin-left: 30px;
      flex: 1;
    }

    &.w-nav[data-collapse='medium'] .w-nav-menu {
      display: none;
    }

    &.w-nav[data-collapse='medium'] .w-nav-button {
      display: block;
    }

    .navigation-items {
      background-color: #fff;
    }

    .navigation-item {
      padding: 15px 30px;
      transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
      text-align: center;
    }

    .menu-button {
      padding: 0px;
      color: #d7462d;
    }

    .navigation-item {
      padding: 15px 30px;
      transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
      text-align: center;
      display: block;
      position: relative;

      &:hover {
        background-color: #f7f8f9;
        opacity: 0.9;
      }
    }

    .w-nav-overlay.open {
      height: 482.64px;
      display: block;
      .navigation-items {
        z-index: 1;
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: #c8c8c8;
        text-align: center;
        overflow: visible;
        min-width: 200px;
        background-color: #fff;
      }
    }
  }
}

.navigation-collapse-enter {
  transform: translateY(-300px) translateX(0px);
}
.navigation-collapse-enter-active {
  transform: translateY(0) translateX(0px);
  transition: transform 300ms ease-in;
}
.navigation-collapse-exit {
  opacity: 1;
}
.navigation-collapse-exit-active {
  transform: translateY(-300px) translateX(0px);
  transition: transform 300ms ease-out;
}

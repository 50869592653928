.met-before-after-submitted {
  .quiz-question-content {
    height: 100vh;
    margin-top: 0px;
    .body-section {
      width: 100%;
      overflow-y: hidden;
      overflow-x: hidden;
      max-width: unset;
      .quiz-content {
        justify-content: flex-start;
        width: 100%;
      }
    }
  }
  .met-before-wrapper {
    display: flex;
    gap: 144px;
    flex: 1;
    position: relative;
    .left-part {
      width: 40%;
    }
    .right-part {
      width: 50%;
    }
    .left-part {
      height: 100vh;
      max-width: 588px;
      .bg-image {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .right-part {
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      > div {
        overflow-y: auto;
        color: #231f20;
        max-width: 448px;
        p,
        h2,
        h3 {
          padding: 0;
          margin: 0;
        }
        .title {
          font-family: 'LTC Caslon';
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          line-height: 48px;
        }
        .description {
          font-family: 'Brandon Grotesque';
          font-style: normal;
          font-weight: 390;
          font-size: 20px;
          line-height: 30px;
          margin-top: 24px;
          margin-bottom: 44px;
        }
        .input {
          &::placeholder {
            font-family: 'Brandon Grotesque';
            font-style: normal;
            font-weight: 390;
            font-size: 16px;
            line-height: 24px;
            color: #8c8c8c;
          }
        }
        .reason-group {
          display: flex;
          gap: 16px;
          flex-wrap: wrap;
          .reason-text {
            font-family: 'Brandon Grotesque';
            font-style: normal;
            font-weight: 390;
            font-size: 16px;
            line-height: 18px;
          }
          .card {
            border: 1px solid #b4b4b4;
            border-radius: 999px;
            padding: 8px 16px;
            cursor: pointer;
            &.active {
              background: #ff9977;
              border: 1px solid #ff9977;
            }
          }
        }
        .action-box-wrapper {
          justify-content: flex-start;
          width: auto;
          padding: 0;
          gap: 20px;
          .button.btn-secondary {
            background-color: transparent;
            border: 1px solid #5E5929;
            color: #5E5929;
          }
          .button.btn-secondary:disabled {
            opacity: 0.8;
          }
        }
      }
    }
    .flower-bg {
      width: 255px;
      height: 517px;
      position: absolute;
      right: -36px;
      bottom: 0;
    }
  }
  @media screen and (max-width: 1280px) {
    .met-before-wrapper {
      gap: 60px;
      .flower-bg {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 820px) {
    .quiz-question-content {
      .body-section {
        width: 100%;
        overflow-y: auto;
      }
    }
    .met-before-wrapper {
      flex-direction: column;
      gap: 16px;
      overflow: auto;
      .left-part {
        width: 100%;
        max-width: 100%;
        height: 390px;
      }
      .right-part {
        width: auto;
        height: auto;
        padding: 0 16px;
        > div {
          overflow: hidden;
          .action-box-wrapper {
            .button {
              padding: 10px 16px;
            }
          }
          .title {
            font-size: 32px;
          }
          .description {
            font-size: 20px;
            margin: 16px 0 24px;
          }
        }
      }
    }
  }
}
